import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, selectors } from "src/containers/TrainingAndCertificate/store";

export interface IUseCertificateDataFetch {
  certificateId?: string | number;
  companyCode?: string | null;
}

export default function useCertificateDataFetch({ certificateId, companyCode }: IUseCertificateDataFetch) {
  const dispatch = useDispatch();

  const certificate = useSelector(selectors.getCurrentCertificate());
  const certificateFiles = useSelector(selectors.getCurrentCertificateFiles());

  useEffect(() => {
    return () => {
      dispatch(actions.getCertificate.success(null));
      dispatch(actions.getCertificateFiles.success([]));
    };
  }, [dispatch]);

  useEffect(() => {
    if (certificateId && companyCode) {
      dispatch(actions.getCertificate.request({ id: certificateId, code: companyCode, payload: null }));
      dispatch(actions.getCertificateFiles.request({ id: certificateId, code: companyCode }));
    }
  }, [dispatch, certificateId, companyCode]);

  return { certificate, certificateFiles };
}
