import React from "react";
import { File } from "src/shared/models";

import { FileItem } from "../FileItem";

export interface FileListProps {
  files: File[];
  onDelete?: (file: File) => void;
  permission?: string;
  companyCode?: string | null;
  isDisabled?: boolean;
}

const FileList = (props: FileListProps) => {
  const { files, onDelete, permission, companyCode, isDisabled = false } = props;
  return (
    <>
      {files.map((file) => (
        <FileItem
          file={file}
          key={file.id}
          onDelete={onDelete}
          permission={permission}
          companyCode={companyCode}
          isDisabled={isDisabled}
        />
      ))}
    </>
  );
};

export default FileList;
