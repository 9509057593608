import { API, METHODS, SCHEMAS } from "src/shared/constants";
import { API_ENUM } from "src/shared/constants/api";
import { SchemasMap } from "src/shared/interfaces";
import { mapToSchema, generateDynamicSchemaKeyString } from "src/shared/utils";
import {
  createCertificateRequestSchema,
  getPaginatedResponseSchema,
  certificateFromListSchema,
  trainingFromListSchema,
  certificateSchema,
  updateCertificateResponseSchema,
  updateTrainingStatusSchema,
  certificateFilterSchema,
  trainingFilterSchema,
  trainingPassSchema,
  trainingPassFilterSchema,
  passTrainingRequestSchema,
  passTrainingResponseSchema,
  trainingSchema,
  createTrainingRequestSchema,
  updateTrainingResponseSchema,
  updateCertificateRequestSchema,
  updateCertificateStatusSchema,
  getFilesResponseSchema,
  createFileResponseSchema,
  createFileRequestSchema,
  deleteFileResponseSchema,
  getCertificatesIssuedByRequestSchema,
  getCertificatesIssuedByResponseSchema,
  certificateTemplatesFilterSchema,
  certificateTemplateSchema,
} from "src/shared/validators";

const schemaShapes: SchemasMap<keyof typeof API.TRAINING & keyof typeof API.CERTIFICATE> = {
  [API_ENUM.GET_TRAININGS]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.TRAINING.GET_TRAININGS("$id"))}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: trainingFilterSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.TRAINING.GET_TRAININGS("$id"))}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: getPaginatedResponseSchema(trainingFromListSchema),
    },
  },
  [API_ENUM.GET_TRAINING]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.TRAINING.GET_TRAINING("$id", "$id"))}${SCHEMAS.REQUEST}${
        METHODS.GET
      }`,
      schema: {},
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.TRAINING.GET_TRAINING("$id", "$id"))}${SCHEMAS.RESPONSE}${
        METHODS.GET
      }`,
      schema: trainingSchema,
    },
  },
  [API_ENUM.CREATE_TRAINING]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.TRAINING.CREATE_TRAINING("$id"))}${SCHEMAS.REQUEST}${METHODS.POST}`,
      schema: createTrainingRequestSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.TRAINING.CREATE_TRAINING("$id"))}${SCHEMAS.RESPONSE}${METHODS.POST}`,
      schema: updateTrainingResponseSchema,
    },
  },
  [API_ENUM.UPDATE_TRAINING]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.TRAINING.UPDATE_TRAINING("$id", "$id"))}${SCHEMAS.REQUEST}${
        METHODS.PUT
      }`,
      schema: createTrainingRequestSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.TRAINING.UPDATE_TRAINING("$id", "$id"))}${SCHEMAS.RESPONSE}${
        METHODS.PUT
      }`,
      schema: updateTrainingResponseSchema,
    },
  },
  [API_ENUM.UPDATE_TRAINING_STATUS]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.TRAINING.UPDATE_TRAINING_STATUS("$id", "$id"))}${SCHEMAS.REQUEST}${
        METHODS.PUT
      }`,
      schema: updateTrainingStatusSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.TRAINING.UPDATE_TRAINING_STATUS("$id", "$id"))}${SCHEMAS.RESPONSE}${
        METHODS.PUT
      }`,
      schema: updateTrainingResponseSchema,
    },
  },
  [API_ENUM.GET_TRAINING_PASS]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.TRAINING.GET_TRAINING_PASS("$id", "$id"))}${SCHEMAS.REQUEST}${
        METHODS.GET
      }`,
      schema: trainingPassFilterSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.TRAINING.GET_TRAINING_PASS("$id", "$id"))}${SCHEMAS.RESPONSE}${
        METHODS.GET
      }`,
      schema: trainingPassSchema,
    },
  },
  [API_ENUM.PASS_TRAINING]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.TRAINING.PASS_TRAINING("$id", "$id"))}${SCHEMAS.REQUEST}${
        METHODS.POST
      }`,
      schema: passTrainingRequestSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.TRAINING.PASS_TRAINING("$id", "$id"))}${SCHEMAS.RESPONSE}${
        METHODS.POST
      }`,
      schema: passTrainingResponseSchema,
    },
  },
  [API_ENUM.CONFIRM_TRAINING]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.TRAINING.CONFIRM_TRAINING("$id", "$id"))}${SCHEMAS.REQUEST}${
        METHODS.POST
      }`,
      schema: passTrainingRequestSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.TRAINING.CONFIRM_TRAINING("$id", "$id"))}${SCHEMAS.RESPONSE}${
        METHODS.POST
      }`,
      schema: passTrainingResponseSchema,
    },
  },
  [API_ENUM.GET_CERTIFICATES]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.CERTIFICATE.GET_CERTIFICATES("$id"))}${SCHEMAS.REQUEST}${
        METHODS.GET
      }`,
      schema: certificateFilterSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.CERTIFICATE.GET_CERTIFICATES("$id"))}${SCHEMAS.RESPONSE}${
        METHODS.GET
      }`,
      schema: getPaginatedResponseSchema(certificateFromListSchema),
    },
  },
  [API_ENUM.GET_CERTIFICATE_TEMPLATES]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.CERTIFICATE.GET_CERTIFICATE_TEMPLATES("$id"))}${SCHEMAS.REQUEST}${
        METHODS.GET
      }`,
      schema: certificateTemplatesFilterSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.CERTIFICATE.GET_CERTIFICATE_TEMPLATES("$id"))}${SCHEMAS.RESPONSE}${
        METHODS.GET
      }`,
      schema: getPaginatedResponseSchema(certificateTemplateSchema),
    },
  },
  [API_ENUM.GET_CERTIFICATES_ISSUED_BY]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.CERTIFICATE.GET_CERTIFICATES_ISSUED_BY("$id"))}${SCHEMAS.REQUEST}${
        METHODS.GET
      }`,
      schema: getCertificatesIssuedByRequestSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.CERTIFICATE.GET_CERTIFICATES_ISSUED_BY("$id"))}${SCHEMAS.RESPONSE}${
        METHODS.GET
      }`,
      schema: getCertificatesIssuedByResponseSchema,
    },
  },
  [API_ENUM.GET_CERTIFICATE]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.CERTIFICATE.GET_CERTIFICATE("$id", "$id"))}${SCHEMAS.REQUEST}${
        METHODS.GET
      }`,
      schema: {},
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.CERTIFICATE.GET_CERTIFICATE("$id", "$id"))}${SCHEMAS.RESPONSE}${
        METHODS.GET
      }`,
      schema: certificateSchema,
    },
  },
  [API_ENUM.CREATE_CERTIFICATE]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.CERTIFICATE.CREATE_CERTIFICATE("$id"))}${SCHEMAS.REQUEST}${
        METHODS.POST
      }`,
      schema: createCertificateRequestSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.CERTIFICATE.CREATE_CERTIFICATE("$id"))}${SCHEMAS.RESPONSE}${
        METHODS.POST
      }`,
      schema: updateCertificateResponseSchema,
    },
  },
  [API_ENUM.UPDATE_CERTIFICATE]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.CERTIFICATE.UPDATE_CERTIFICATE("$id", "$id"))}${SCHEMAS.REQUEST}${
        METHODS.PUT
      }`,
      schema: updateCertificateRequestSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.CERTIFICATE.UPDATE_CERTIFICATE("$id", "$id"))}${SCHEMAS.RESPONSE}${
        METHODS.PUT
      }`,
      schema: updateCertificateResponseSchema,
    },
  },
  [API_ENUM.GET_CERTIFICATE_FILES]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.CERTIFICATE.GET_CERTIFICATE_FILES("$id", "$id"))}${SCHEMAS.REQUEST}${
        METHODS.GET
      }`,
      schema: {},
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.CERTIFICATE.GET_CERTIFICATE_FILES("$id", "$id"))}${SCHEMAS.RESPONSE}${
        METHODS.GET
      }`,
      schema: getFilesResponseSchema,
    },
  },
  [API_ENUM.ADD_CERTIFICATE_FILE]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.CERTIFICATE.ADD_CERTIFICATE_FILE("$id", "$id"))}${SCHEMAS.REQUEST}${
        METHODS.POST
      }`,
      schema: createFileRequestSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.CERTIFICATE.ADD_CERTIFICATE_FILE("$id", "$id"))}${SCHEMAS.RESPONSE}${
        METHODS.POST
      }`,
      schema: createFileResponseSchema,
    },
  },
  [API_ENUM.DELETE_CERTIFICATE_FILE]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.CERTIFICATE.DELETE_CERTIFICATE_FILE("$id", "$id", "$id"))}${
        SCHEMAS.REQUEST
      }${METHODS.DELETE}`,
      schema: {},
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.CERTIFICATE.DELETE_CERTIFICATE_FILE("$id", "$id", "$id"))}${
        SCHEMAS.RESPONSE
      }${METHODS.DELETE}`,
      schema: deleteFileResponseSchema,
    },
  },
  [API_ENUM.UPDATE_CERTIFICATE_STATUS]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.CERTIFICATE.UPDATE_CERTIFICATE_STATUS("$id", "$id"))}${
        SCHEMAS.REQUEST
      }${METHODS.PUT}`,
      schema: updateCertificateStatusSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.CERTIFICATE.UPDATE_CERTIFICATE_STATUS("$id", "$id"))}${
        SCHEMAS.RESPONSE
      }${METHODS.PUT}`,
      schema: updateCertificateResponseSchema,
    },
  },
};

export default mapToSchema(schemaShapes);
