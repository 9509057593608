import { Filter } from "src/shared/interfaces";

export const DEFAULT_LIMIT = 30;

export const defaultFilter: Filter = {
  page: 0,
  limit: DEFAULT_LIMIT,
  company_code: null,
  role_id: null,
  team_id: null,
  industry_id: null,
  search: null,
  status: null,
};
