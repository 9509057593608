import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";
import { defaultFilter } from "src/shared/constants";

import { ActivityStateType } from "../interface";
import * as actions from "./actions";

type Action = ActionType<typeof actions>;

export const initialState: ActivityStateType = {
  activities: [],
  activitiesTotal: 0,
  currentActivity: null,
  lastCreatedActivity: null,
  filter: defaultFilter,
};

const reducer = createReducer<ActivityStateType, Action>(initialState)
  .handleAction(actions.getActivities.success, (state, action) =>
    produce(state, (nextState) => {
      const { rows, count, clear } = action.payload;
      nextState.activities = clear ? rows : [...nextState.activities, ...rows];
      nextState.activitiesTotal = count;
    }),
  )
  .handleAction(actions.createActivity.success, (state, action) =>
    produce(state, (nextState) => {
      const { activity } = action.payload;

      nextState.activities = [
        {
          ...activity,
          companies_count: "0",
          jsa_count: String(activity.jsa.length),
          industries_count: String(activity.industries.length),
          trainings_count: String(activity.trainings.length),
        },
        ...nextState.activities,
      ];

      nextState.lastCreatedActivity = activity;

      nextState.activitiesTotal = nextState.activitiesTotal + 1;
    }),
  )
  .handleAction(actions.updateActivity.success, (state, action) =>
    produce(state, (nextState) => {
      const { activity } = action.payload;
      const index = nextState.activities.findIndex((a) => a.id === activity.id);
      if (index >= 0) {
        const companiesCount = nextState.activities[index].companies_count;
        nextState.activities[index] = {
          ...activity,
          companies_count: companiesCount,
          jsa_count: String(activity.jsa.length),
          industries_count: String(activity.industries.length),
          trainings_count: String(activity.trainings.length),
        };
      }
    }),
  )
  .handleAction(actions.getActivity.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.currentActivity = action.payload;
    }),
  )
  .handleAction(actions.setLastCreatedActivity, (state, action) =>
    produce(state, (nextState) => {
      nextState.lastCreatedActivity = action.payload;
    }),
  )
  .handleAction(actions.setFilter, (state, action) =>
    produce(state, (nextState) => {
      nextState.filter = action.payload || { ...defaultFilter };
    }),
  );

export { reducer as ActivityReducer };
