import React, { FC, useCallback, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AutocompleteSelect, ErrorMessage } from "src/shared/components";
import { AutocompleteSelectProps } from "src/shared/components/Common/Field/AutocompleteSelect/AutocompleteSelect";
import { Option, BaseEntity, AnyType } from "src/shared/interfaces";
import { MenuListProps } from "react-select";
import classnames from "classnames";
import { useDebounce } from "src/shared/hooks";
import "./index.scss";
export interface OptionsSelectorProps {
  name: string;
  getData: (search: string, page?: number) => void;
  //eslint-disable-next-line
  selectData: () => any;
  selectTotalCount?: () => AnyType;
  //eslint-disable-next-line
  prepareOptionFunction?: (item: any) => Option;
  menuWrapper?: (props: MenuListProps) => JSX.Element;
  unique?: boolean;
  title: string;
  addText: string;
  noItemsAvailable?: string;
  uniqueError?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  selectedOptions: Array<BaseEntity | null>;
  optionPrefix?: string;
  onChange?: (options: Array<BaseEntity | null>) => void;
  addButtonDown?: boolean;
}

const OptionsSelector: FC<OptionsSelectorProps> = (props) => {
  const {
    name,
    getData,
    selectData,
    selectTotalCount,
    prepareOptionFunction,
    menuWrapper,
    unique,
    title,
    addText,
    noItemsAvailable = "No items available",
    uniqueError = "Items must be unique",
    placeholder,
    disabled,
    selectedOptions,
    optionPrefix,
    onChange,
    addButtonDown,
  } = props;
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [page, setPage] = useState(0);
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  //eslint-disable-next-line
  const array = useSelector(selectData()) as any[];

  const isDoubleOption = useCallback(
    (option: BaseEntity | null) => {
      return unique && option?.id && selectedOptions.filter((item) => item && item.id === option.id).length > 1;
    },
    [unique, selectedOptions],
  );

  const handleInputSearch = useCallback(
    (search: string) => {
      if (searchQuery !== search) {
        setSearchQuery(search);
        setPage(0);
      }
    },
    [searchQuery],
  );

  const handleSelectChange = useCallback(
    (value, index) => {
      const foundValue = array.find((item) => item.id === value);
      const tempOptions = [...selectedOptions];
      tempOptions[index] = foundValue;
      onChange && onChange(tempOptions);
    },
    [array, selectedOptions, onChange],
  );

  useEffect(() => {
    if (!disabled) {
      dispatch(getData(debouncedSearchQuery, page));
    }
  }, [debouncedSearchQuery, getData, dispatch, disabled, page]);

  const extraSelectProps = useMemo<Partial<AutocompleteSelectProps>>(() => {
    const result: Partial<AutocompleteSelectProps> = {};
    if (menuWrapper) {
      result.menuWrapper = menuWrapper;
    }
    return result;
  }, [menuWrapper]);

  return (
    <div className="options-selector-wrapper">
      <div className="form-subtitle-wrapper">
        <div className="form-subtitle-title">{title}</div>
        {!addButtonDown && (
          <div
            className={classnames("form-subtitle-add", { disabled })}
            onClick={() => {
              if (!disabled) {
                onChange && onChange([...selectedOptions, null]);
              }
            }}>
            {addText}
          </div>
        )}
      </div>
      <div className="options-selector-content">
        {selectedOptions.map((option, index: number) => {
          return (
            <div key={name + index} className="options-selector-item">
              <div className="options-selector-item-label">
                {optionPrefix} {index + 1}
              </div>
              <div className="options-selector-item-input">
                <AutocompleteSelect
                  onInputChange={handleInputSearch}
                  options={[]}
                  isRequest={false}
                  name={`${name}.${index}`}
                  placeholder={placeholder}
                  getData={getData}
                  selectData={selectData}
                  selectTotalCount={selectTotalCount}
                  onChange={(value) => handleSelectChange(value, index)}
                  value={option?.id || null}
                  initialValue={option && prepareOptionFunction ? prepareOptionFunction(option) : undefined}
                  prepareOptionFunction={prepareOptionFunction}
                  noOptionsMessage={noItemsAvailable}
                  isDisabled={disabled}
                  isClearable={true}
                  onChangePage={(page) => setPage(page)}
                  {...extraSelectProps}
                />
                {Boolean(index) && (
                  <div
                    className="options-selector-item-remove"
                    onClick={() => {
                      if (!disabled) {
                        const tempOptions = [...selectedOptions];
                        tempOptions.splice(index, 1);
                        onChange && onChange(tempOptions);
                      }
                    }}
                  />
                )}
              </div>
              {isDoubleOption(option) && <ErrorMessage isTouched={true} error={uniqueError} />}
            </div>
          );
        })}
      </div>
      {addButtonDown && (
        <div className="form-subtitle-wrapper option-selector-footer">
          <div
            className={classnames("form-subtitle-add", { disabled })}
            onClick={() => {
              if (!disabled) {
                onChange && onChange([...selectedOptions, null]);
              }
            }}>
            {addText}
          </div>
        </div>
      )}
    </div>
  );
};

export default OptionsSelector;
