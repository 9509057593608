import { CertificateFormShape } from "src/containers/TrainingAndCertificate/interface";
import { FieldItem } from "src/shared/components/FormGenerator";
import { actions as activityActions, selectors as activitySelectors } from "src/containers/Activity/store";
import {
  actions as certificateActions,
  selectors as certificateSelectors,
} from "src/containers/TrainingAndCertificate/store";
import { actions, selectors } from "src/containers/Project/store";
import { FORM_ERROR_MESSAGES, PERIOD_OPTIONS } from "src/shared/constants";
import { FieldItemType, ORDER_TYPE, RenderTypes, Option } from "src/shared/interfaces";
import { Certificate, CertificateStatus, ProjectStatus, CertificateTemplate } from "src/shared/models";
import { prepareOptionFunction } from "src/shared/utils";
import * as Yup from "yup";

export const prepareCertificateIssuedByFunction = (item: { issued_by: string }): Option<string> => {
  return { label: item.issued_by, value: item.issued_by };
};

export const prepareCertificateNameFunction = (item: CertificateTemplate): Option<string> => {
  return { label: `${item.name} (${item.issued_by})`, value: item.name, object: item };
};

export const validationSchema: Yup.SchemaOf<CertificateFormShape> = Yup.object().shape({
  name: Yup.string().max(100, FORM_ERROR_MESSAGES.LONG).required(FORM_ERROR_MESSAGES.REQUIRED),
  status: Yup.string().required(),
  issued_by: Yup.string(),
  issued_on: Yup.date().typeError(FORM_ERROR_MESSAGES.REQUIRED).required(FORM_ERROR_MESSAGES.REQUIRED),
  valid: Yup.number().min(1, "Min value 1").required(FORM_ERROR_MESSAGES.REQUIRED),
  valid_period: Yup.string().required(FORM_ERROR_MESSAGES.REQUIRED),
  activities: Yup.array().required(),
  projects: Yup.array().required(),
});

interface GetFieldsOptions {
  onChangeName: (fileld: string, certificateName: string) => void;
  onChangeIssuedBy?: () => void;
  hasTemplate?: boolean;
}

export const getFields = ({ onChangeIssuedBy, onChangeName, hasTemplate }: GetFieldsOptions) => {
  const fields: FieldItem[] = [
    {
      type: FieldItemType.AUTOCOMPLETE_INPUT,
      name: "name",
      label: "Certificate name *",
      wrapperClass: "input-line",
      onChangeField: onChangeName,
    },
    {
      type: FieldItemType.TEXT,
      name: "issued_by",
      label: "Issued by",
      wrapperClass: "input-line",
      onChangeField: onChangeIssuedBy,
    },
    {
      type: FieldItemType.RENDER,
      renderType: RenderTypes.FIELD_LIST_LINE,
      wrapperClass: "form-row issued-on-row",
      name: "form-line-1",
      fields: [
        {
          type: FieldItemType.DATEPICKER,
          name: "issued_on",
          label: "Issued On *",
          wrapperClass: "column-date",
          placeholder: "mm/dd/yyyy",
        },
        {
          type: FieldItemType.NUMBER,
          name: "valid",
          label: "Valid *",
          min: 1,
          wrapperClass: "repeat-every-number-wrapper",
        },
        {
          type: FieldItemType.SELECT,
          name: "valid_period",
          label: " ",
          wrapperClass: "repeat-every-period-select-wrapper",
          options: PERIOD_OPTIONS,
          placeholder: " ",
        },
      ],
    },
    {
      type: FieldItemType.AUTOCOMPLETE_SELECT_MULTI,
      name: "activities",
      label: "Activities (optional)",
      wrapperClass: "input-line",
      options: [],
      placeholder: "Select Activity",
      disabled: hasTemplate,
    },
    {
      type: FieldItemType.AUTOCOMPLETE_SELECT_MULTI,
      name: "projects",
      label: "Projects (optional)",
      wrapperClass: "input-line",
      options: [],
      placeholder: "Select Project",
      disabled: hasTemplate,
    },
  ];

  return fields;
};

export const getInitValues = (certificate?: Certificate | null): CertificateFormShape => ({
  name: certificate?.name || "",
  status: certificate?.status || CertificateStatus.Active,
  issued_by: certificate?.issued_by || "",
  issued_on: certificate && certificate.issued_on ? new Date(certificate.issued_on) : null,
  valid: certificate?.valid || 1,
  valid_period: certificate?.valid_period || "",
  activities: certificate?.activities?.map(prepareOptionFunction) || [],
  projects: certificate?.projects?.map(prepareOptionFunction) || [],
});

export const handlers = (companyCode?: string | null) => ({
  activities: {
    getData: (search: string, page?: number) =>
      activityActions.getActivities.request({
        search,
        page: page || 0,
        limit: 10,
        company_code: companyCode,
        status: null,
        sort_order: ORDER_TYPE.ASC,
        sort_by: ["name"],
      }),
    selectData: activitySelectors.getActivities,
    selectTotalCount: activitySelectors.getActivitiesTotal,
    prepareOptionFunction: prepareOptionFunction,
  },
  projects: {
    getData: (search: string, page?: number) =>
      actions.getProjects.request({
        page: page || 0,
        limit: 10,
        search,
        company_code: companyCode,
        status: ProjectStatus.Active,
      }),
    selectData: selectors.getProjects,
    selectTotalCount: selectors.getProjectsTotal,
    prepareOptionFunction: prepareOptionFunction,
  },
  issued_by: {
    getData: (search: { search: string }, page?: number) =>
      certificateActions.getCertificatesIssuedBy.request({
        page: page || 0,
        limit: 10,
        search: search.search,
        company_code: companyCode,
      }),
    selectData: certificateSelectors.getCertificatesIssuedBy,
    selectTotalCount: certificateSelectors.getCertificatesIssuedByTotal,
    prepareOptionFunction: prepareCertificateIssuedByFunction,
  },
  name: {
    getData: (search: { search: string }, page?: number) =>
      certificateActions.getCertificatesTemplates.request({
        page: page || 0,
        limit: 10,
        search: search.search,
        company_code: companyCode,
      }),
    selectData: certificateSelectors.getCertificatesTemplates,
    selectTotalCount: certificateSelectors.getCertificatesIssuedByTotal,
    prepareOptionFunction: prepareCertificateNameFunction,
  },
});
