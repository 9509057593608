import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";
import { defaultFilter } from "src/shared/constants";

import { UserNotificationStateType } from "../interface";
import * as actions from "./actions";

type Action = ActionType<typeof actions>;

export const initialState: UserNotificationStateType = {
  userNotifications: [],
  userNotificationsTotal: 0,
  userNotificationsUnreadCount: 0,
  filter: defaultFilter,
};

const reducer = createReducer<UserNotificationStateType, Action>(initialState)
  .handleAction(actions.getUserNotifications.success, (state, action) =>
    produce(state, (nextState) => {
      const { rows, count, clear } = action.payload;
      nextState.userNotifications = clear ? rows : [...nextState.userNotifications, ...rows];
      nextState.userNotificationsTotal = count;
    }),
  )
  .handleAction(actions.getUserNotificationsUnreadCount.success, (state, action) =>
    produce(state, (nextState) => {
      const { count } = action.payload;
      nextState.userNotificationsUnreadCount = count;
    }),
  )
  .handleAction(actions.readUserNotifications.success, (state, action) =>
    produce(state, (nextState) => {
      const { notifications: readNotificationsIds } = action.payload;
      nextState.userNotificationsUnreadCount = nextState.userNotificationsUnreadCount - readNotificationsIds.length;
      nextState.userNotifications = nextState.userNotifications.map((notif) => {
        if (readNotificationsIds.includes(notif.id) && !notif.is_read) {
          return {
            ...notif,
            is_read: true,
          };
        }
        return notif;
      });
    }),
  )
  .handleAction(actions.readUserNotification.success, (state, action) =>
    produce(state, (nextState) => {
      const { notification: notificationId } = action.payload;
      nextState.userNotificationsUnreadCount = nextState.userNotificationsUnreadCount - 1;
      nextState.userNotifications = nextState.userNotifications.map((notif) => {
        if (notif.id === notificationId && !notif.is_read) {
          return {
            ...notif,
            is_read: true,
          };
        }
        return notif;
      });
    }),
  )
  .handleAction(actions.addUserNotification, (state, action) =>
    produce(state, (nextState) => {
      nextState.userNotifications = [action.payload, ...nextState.userNotifications];
      nextState.userNotificationsTotal = nextState.userNotificationsTotal + 1;

      if (!action.payload.is_read) {
        nextState.userNotificationsUnreadCount = nextState.userNotificationsUnreadCount + 1;
      }
    }),
  )
  .handleAction(actions.clearUserNotifications, (state) =>
    produce(state, (nextState) => {
      nextState.userNotifications = [];
      nextState.userNotificationsTotal = 0;
      nextState.userNotificationsUnreadCount = 0;
    }),
  )
  .handleAction(actions.setFilter, (state, action) =>
    produce(state, (nextState) => {
      nextState.filter = action.payload || { ...defaultFilter };
    }),
  );

export { reducer as UserNotificationReducer };
