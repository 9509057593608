import React, { FC, useState, useCallback, useEffect } from "react";
import classnames from "classnames";
import { ApplySelectOption, CustomMenuList } from "src/shared/components";
import { MultiValueGenericProps } from "react-select";

import { AutocompleteMultiSelect, AutocompleteMultiSelectProps } from "./AutocompleteMultiSelect";

export interface Option {
  label: string;
  value: string;
}

const DEFAULT_OPTIONS: Option[] = [];
export interface AutocompleteCheckboxMultiSelectProps extends Omit<AutocompleteMultiSelectProps, "value" | "onChange"> {
  onChange: (options: Option[]) => void;
  value?: Option[];
}

export const AutocompleteCheckboxMultiSelect: FC<AutocompleteCheckboxMultiSelectProps> = ({
  onChange,
  className,
  options,
  value,
  selectData,
  selectTotalCount,
  prepareOptionFunction,
  ...selectProps
}) => {
  const [values, setValues] = useState<Option[]>([]);
  const [appliedValues, setAppliedValues] = useState<Option[]>([]);

  useEffect(() => {
    setValues(value || []);
    setAppliedValues(value || []);
  }, [value]);

  const clearValues = useCallback(() => {
    setValues([]);
    onChange([]);
    setAppliedValues([]);
  }, [onChange]);

  const multiValueContainer = useCallback(
    (props: MultiValueGenericProps) => {
      if (!appliedValues.length) {
        return <></>;
      }

      const value = props.data.value;
      const allSelected = props.selectProps.value as Option[];
      const index = allSelected.findIndex((selected) => selected.value === value);
      const [firstOption] = appliedValues;

      if (index) {
        return <></>;
      }
      return (
        <div className="custom-multiselect-value">
          {firstOption.label} {appliedValues.length > 1 ? `+ ${appliedValues.length - 1}` : ""}
        </div>
      );
    },
    [appliedValues],
  );

  const menuWrapper = useCallback(
    (props) => {
      const selectedValues = props.getValue();

      return (
        <CustomMenuList
          onMenuScrollToBottom={props.selectProps.onMenuScrollToBottom}
          baseProps={props}
          footerComponent={
            <ApplySelectOption
              disabled={!selectedValues.length}
              onApply={() => {
                onChange(selectedValues);
                setAppliedValues(selectedValues);
              }}
              onReset={clearValues}
            />
          }
        />
      );
    },
    [clearValues, onChange],
  );

  const selectValue = useCallback(
    (value) => {
      setValues(value);
    },
    [setValues],
  );

  const onMenuCloseSelect = useCallback(() => {
    setValues(appliedValues);
  }, [appliedValues]);

  return (
    <AutocompleteMultiSelect
      {...selectProps}
      selectData={selectData}
      selectTotalCount={selectTotalCount}
      prepareOptionFunction={prepareOptionFunction}
      onMenuClose={onMenuCloseSelect}
      options={options || DEFAULT_OPTIONS}
      className={classnames("light-options-style", className)}
      hideValues={true}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      value={values}
      onChange={selectValue}
      multiValueContainer={multiValueContainer}
      menuWrapper={menuWrapper}
    />
  );
};
export default AutocompleteCheckboxMultiSelect;
