import React, { FC, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, selectors } from "src/containers/UserNotification/store";
import { UserNotificationContent } from "src/containers/UserNotification/components";
import { UserNotification } from "src/shared/models";
import { useUserNotificationAction } from "src/containers/UserNotification/hooks";

import "./index.scss";

export interface UserNotificationContainerProps {
  onCancel: () => void;
  companyCode: string;
}

export const UserNotificationContainer: FC<UserNotificationContainerProps> = (props) => {
  const { onCancel, companyCode } = props;

  const dispatch = useDispatch();

  const notifications = useSelector(selectors.getUserNotifications());
  const notificationsTotal = useSelector(selectors.getUserNotificationsTotal());
  const unreadNotificationsCount = useSelector(selectors.getUserNotificationsUnreadCount());
  const filter = useSelector(selectors.getFilter());

  useEffect(() => {
    return () => {
      dispatch(actions.getUserNotifications.success({ rows: [], count: 0 }));
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      actions.getUserNotifications.request({
        ...filter,
        company_code: companyCode,
      }),
    );
  }, [dispatch, filter, companyCode]);

  const handleClickReadAllBtn = useCallback(() => {
    dispatch(
      actions.readUserNotifications.request({
        code: companyCode,
      }),
    );
  }, [dispatch, companyCode]);

  const onLoadNextPage = useCallback(() => {
    if (filter.limit * filter.page < notificationsTotal) {
      dispatch(
        actions.setFilter({
          ...filter,
          page: filter.page + 1,
        }),
      );
    }
  }, [dispatch, filter, notificationsTotal]);

  const { notificationAction } = useUserNotificationAction(companyCode);

  const onNotificationClick = useCallback(
    (notification: UserNotification) => {
      if (notification.action) {
        onCancel();
        notificationAction(notification);
      }

      if (!notification.is_read) {
        dispatch(
          actions.readUserNotification.request({
            code: companyCode,
            id: notification.id,
            payload: null,
          }),
        );
      }
    },
    [dispatch, notificationAction, companyCode, onCancel],
  );

  return (
    <div className="user-notifications-container">
      <UserNotificationContent
        unreadNotificationsCount={unreadNotificationsCount}
        notifications={notifications}
        onLoadNextPage={onLoadNextPage}
        onCancel={onCancel}
        onClickReadAllBtn={handleClickReadAllBtn}
        onNotificationClick={onNotificationClick}
      />
    </div>
  );
};

export default UserNotificationContainer;
