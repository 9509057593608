import { Moment } from "moment";
import { createAction, createAsyncAction } from "typesafe-actions";
import { PaginatedResponse, CodeWithId } from "src/shared/interfaces";
import { AssignmentActionTypes } from "src/containers/Assignment/store/constants";
import {
  AssignmentFormShapeToRequest,
  AssignmentCheckInOutFormShapeToRequest,
  CreateAssignmentCheckInOutSuccess,
  CreateAssignmentSuccess,
  UpdateAssignmentStatusShape,
  AssignmentCheckInOutFilter,
  AssignmentPassedJsaFilter,
  LAST_ASSIGNMENT_VIEW,
  AssignmentJsaFilter,
  AssignmentJsaResponse,
} from "src/containers/Assignment/interface";
import {
  AssignmentListItem,
  MemberWithAssignmentsInfoListItem,
  Assignment,
  AssignmentCheckInOutState,
  AssignmentPassedJsa,
} from "src/shared/models";

import { AssignmentFilter } from "../interface";

export const getAssignments = createAsyncAction(
  AssignmentActionTypes.GET_ASSIGNMENTS,
  AssignmentActionTypes.GET_ASSIGNMENTS_SUCCESS,
  AssignmentActionTypes.GET_ASSIGNMENTS_FAILURE,
)<AssignmentFilter, PaginatedResponse<AssignmentListItem>, Error>();

export const clearAssignments = createAction(AssignmentActionTypes.CLEAR_ASSIGNMENTS)();

export const getMembersAssignments = createAsyncAction(
  AssignmentActionTypes.GET_MEMBERS_ASSIGNMENTS,
  AssignmentActionTypes.GET_MEMBERS_ASSIGNMENTS_SUCCESS,
  AssignmentActionTypes.GET_MEMBERS_ASSIGNMENTS_FAILURE,
)<AssignmentFilter, PaginatedResponse<MemberWithAssignmentsInfoListItem>, Error>();

export const clearMembersAssignments = createAction(AssignmentActionTypes.CLEAR_MEMBERS_ASSIGNMENTS)();

export const createAssignment = createAsyncAction(
  AssignmentActionTypes.CREATE_ASSIGNMENT,
  AssignmentActionTypes.CREATE_ASSIGNMENT_SUCCESS,
  AssignmentActionTypes.CREATE_ASSIGNMENT_FAILURE,
)<AssignmentFormShapeToRequest, CreateAssignmentSuccess, Error>();

export const getAssignmentCheckInOut = createAsyncAction(
  AssignmentActionTypes.GET_ASSIGNMENT_CHECK_IN_OUT,
  AssignmentActionTypes.GET_ASSIGNMENT_CHECK_IN_OUT_SUCCESS,
  AssignmentActionTypes.GET_ASSIGNMENT_CHECK_IN_OUT_FAILURE,
)<Required<CodeWithId<AssignmentCheckInOutFilter>>, Array<AssignmentCheckInOutState>, Error>();

export const createAssignmentCheckIn = createAsyncAction(
  AssignmentActionTypes.CREATE_ASSIGNMENT_CHECK_IN,
  AssignmentActionTypes.CREATE_ASSIGNMENT_CHECK_IN_SUCCESS,
  AssignmentActionTypes.CREATE_ASSIGNMENT_CHECK_IN_FAILURE,
)<Required<CodeWithId<AssignmentCheckInOutFormShapeToRequest>>, CreateAssignmentCheckInOutSuccess, Error>();

export const createAssignmentCheckOut = createAsyncAction(
  AssignmentActionTypes.CREATE_ASSIGNMENT_CHECK_OUT,
  AssignmentActionTypes.CREATE_ASSIGNMENT_CHECK_OUT_SUCCESS,
  AssignmentActionTypes.CREATE_ASSIGNMENT_CHECK_OUT_FAILURE,
)<Required<CodeWithId<AssignmentCheckInOutFormShapeToRequest>>, CreateAssignmentCheckInOutSuccess, Error>();

export const clearAssignmentsCheckInOut = createAction(AssignmentActionTypes.CLEAR_ASSIGNMENT_CHECK_IN_OUT)();

export const setFilter = createAction(AssignmentActionTypes.SET_FILTER)<AssignmentFilter | null>();

export const setPartialFilter = createAction(AssignmentActionTypes.SET_PARTIAL_FILTER)<Partial<AssignmentFilter>>();

export interface GetAssignmentPayload extends Required<CodeWithId> {
  withCheckInOut?: boolean;
  memberId?: number;
}

export const getAssignment = createAsyncAction(
  AssignmentActionTypes.GET_ASSIGNMENT,
  AssignmentActionTypes.GET_ASSIGNMENT_SUCCESS,
  AssignmentActionTypes.GET_ASSIGNMENT_FAILURE,
)<GetAssignmentPayload, Assignment | null, Error>();

export const updateAssignment = createAsyncAction(
  AssignmentActionTypes.UPDATE_ASSIGNMENT,
  AssignmentActionTypes.UPDATE_ASSIGNMENT_SUCCESS,
  AssignmentActionTypes.UPDATE_ASSIGNMENT_FAILURE,
)<Required<CodeWithId<AssignmentFormShapeToRequest>>, CreateAssignmentSuccess, Error>();

export const updateAssignmentStatus = createAsyncAction(
  AssignmentActionTypes.UPDATE_ASSIGNMENT_STATUS,
  AssignmentActionTypes.UPDATE_ASSIGNMENT_STATUS_SUCCESS,
  AssignmentActionTypes.UPDATE_ASSIGNMENT_STATUS_FAILURE,
)<Required<CodeWithId<UpdateAssignmentStatusShape>>, CreateAssignmentSuccess, Error>();

export const getAssignmentPassedJsa = createAsyncAction(
  AssignmentActionTypes.GET_ASSIGNMENT_PASSED_JSA,
  AssignmentActionTypes.GET_ASSIGNMENT_PASSED_JSA_SUCCESS,
  AssignmentActionTypes.GET_ASSIGNMENT_PASSED_JSA_FAILURE,
)<Required<CodeWithId<AssignmentPassedJsaFilter>>, Array<AssignmentPassedJsa>, Error>();

export const getAssignmentJsa = createAsyncAction(
  AssignmentActionTypes.GET_ASSIGNMENT_JSA,
  AssignmentActionTypes.GET_ASSIGNMENT_JSA_SUCCESS,
  AssignmentActionTypes.GET_ASSIGNMENT_JSA_FAILURE,
)<Required<CodeWithId<AssignmentJsaFilter>>, AssignmentJsaResponse, Error>();

export const setLastAssignmentView = createAction(
  AssignmentActionTypes.SET_LAST_ASSIGNMENT_VIEW,
)<LAST_ASSIGNMENT_VIEW>();

export const setClickedCalendarDay = createAction(AssignmentActionTypes.SET_CLICKED_CALENDAR_DAY)<Moment | null>();
export const setClearAssignmentForm = createAction(AssignmentActionTypes.SET_CLEAR_ASSIGNMENT_FORM)<boolean>();
