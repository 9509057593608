import { createSelector } from "reselect";
import { AppState } from "src/shared/interfaces/State.interface";

const selectTags = (state: AppState) => state.tag;

export const getTags = () => createSelector(selectTags, (state) => state.tags);
export const getTag = () => createSelector(selectTags, (state) => state.currentTag);
export const getTagsTotal = () => createSelector(selectTags, (state) => state.tagsTotal);
export const getFilter = () => createSelector(selectTags, (state) => state.filter);
export const getClearTagForm = () => createSelector(selectTags, (state) => state.clearTagForm);
