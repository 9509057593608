import { useCallback, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ActionTypes } from "src/shared/interfaces";
import { MemberWithCertificateInfoListItem, MemberWithTrainingInfoListItem } from "src/shared/models";
import { selectors, actions } from "src/containers/Member/store";

import { TrainingAndCertificateMemberFilter } from "../interface";

export interface IUseMemberTrainingAndCertificateList {
  allowGettingMembers?: boolean;
  getMembersAction: ActionTypes;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getMembersSelector: () => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getMembersTotalSelector: () => any;
  prepareFilter?: (filter: TrainingAndCertificateMemberFilter) => TrainingAndCertificateMemberFilter;
}

export default function useMemberTrainingAndCertificateList<
  MListItem = MemberWithTrainingInfoListItem | MemberWithCertificateInfoListItem,
>({
  allowGettingMembers = true,
  getMembersAction,
  getMembersSelector,
  getMembersTotalSelector,
  prepareFilter,
}: IUseMemberTrainingAndCertificateList) {
  const dispatch = useDispatch();
  const members: MListItem[] = useSelector(getMembersSelector());
  const membersTotal: number = useSelector(getMembersTotalSelector());
  const filter = useSelector(selectors.getTrainingAndCertificateMemberFilter(), shallowEqual);

  useEffect(() => {
    return () => {
      dispatch(actions.setTrainingAndCertificateMemberFilter(null));
      dispatch(getMembersAction.success({ count: 0, rows: [], clear: true }));
    };
  }, [dispatch, getMembersAction]);

  useEffect(() => {
    if (!allowGettingMembers) return;

    const preparedFilter = prepareFilter ? prepareFilter(filter) : filter;

    dispatch(getMembersAction.request(preparedFilter));
  }, [dispatch, filter, getMembersAction, prepareFilter, allowGettingMembers]);

  const onLoadNextPage = useCallback(() => {
    if (filter.page * filter.limit < membersTotal) {
      dispatch(
        actions.setTrainingAndCertificateMemberFilter({
          ...filter,
          page: filter.page + 1,
        }),
      );
    }
  }, [dispatch, filter, membersTotal]);

  const onChangeFilter = useCallback(
    (updatedFilter: Partial<TrainingAndCertificateMemberFilter>) => {
      dispatch(
        actions.setTrainingAndCertificateMemberFilter({
          ...filter,
          ...updatedFilter,
          page: 0,
        }),
      );
    },
    [dispatch, filter],
  );

  return { members, onLoadNextPage, onChangeFilter, filter };
}
