import { useEffect } from "react";
import { generatePath } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { selectors, actions } from "src/shared/store";
import { NameOfRoutes, NameOfChildRoutes } from "src/shared/constants";
import { getLastCompany, getDefaultUrlForCompanyLevel, getDefaultUrlForAppLevel, hasAdminRole } from "src/shared/utils";
import { useLocation } from "react-router-dom";

export default function useCompanySwitchHook() {
  const user = useSelector(selectors.getUserDetails());
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== NameOfRoutes.APP || !user) {
      return;
    }

    if (hasAdminRole(user)) {
      dispatch(actions.navigate(getDefaultUrlForAppLevel(user)));
      return;
    }

    const companyCode = user.user_roles.find(Boolean)?.company?.code;
    if (user.user_roles.length === 1) {
      if (companyCode) {
        const route = getDefaultUrlForCompanyLevel(user, companyCode);
        dispatch(actions.navigate(route ? generatePath(route, { code: companyCode }) : NameOfRoutes.AUTH_LOGIN));
      }
      return;
    }

    const lastCompanyCode = getLastCompany();
    const hasAccessToLastCompanyCode = user.user_roles.find((r) => r.company?.code === lastCompanyCode);
    if (lastCompanyCode && hasAccessToLastCompanyCode) {
      const route = getDefaultUrlForCompanyLevel(user, lastCompanyCode);
      dispatch(actions.navigate(route ? generatePath(route, { code: lastCompanyCode }) : NameOfRoutes.AUTH_LOGIN));
      return;
    }

    if (companyCode) {
      dispatch(
        actions.navigate(
          generatePath(`${NameOfRoutes.COMPANIES}${NameOfChildRoutes.COMPANY.SWITCH}`, { code: companyCode }),
        ),
      );
    }
  }, [user, dispatch, location]);
}
