import React, { useState } from "react";
import { Formik } from "formik";
import { Button, FormGenerator } from "src/shared/components";
import { TeamFormShapeToRequest } from "src/containers/Team/interface";

import { validationSchema, fields } from "./formHelpers";
import "./index.scss";

export interface TeamFormProps {
  onCancel: () => void;
  onSubmit: (value: TeamFormShapeToRequest) => void;
}

const TeamForm = (props: TeamFormProps) => {
  const { onCancel, onSubmit } = props;
  const [formValues] = useState<TeamFormShapeToRequest>({ name: "", members: [] });

  return (
    <div className="team-form">
      <Formik
        validationSchema={validationSchema}
        initialValues={formValues}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          onSubmit(values);
        }}
        enableReinitialize={true}
        validateOnChange={true}
        validateOnBlur={false}>
        {(formikProps) => (
          <FormGenerator {...formikProps} fields={fields} formikProps={formikProps}>
            <div className="submit-wrapper">
              <Button buttonStyle="bordered" type="button" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit">Create</Button>
            </div>
          </FormGenerator>
        )}
      </Formik>
    </div>
  );
};

export default TeamForm;
