import { createAction, createAsyncAction } from "typesafe-actions";
import { PaginatedResponse, CodeWithId, Filter, BaseEntity } from "src/shared/interfaces";
import { Team } from "src/shared/models";

import { TeamActionTypes } from "./constants";
import { CreateUpdateTeamSuccess, DeleteTeamResponse, TeamFormShapeToRequest } from "../interface";

export const getTeams = createAsyncAction(
  TeamActionTypes.GET_TEAMS,
  TeamActionTypes.GET_TEAMS_SUCCESS,
  TeamActionTypes.GET_TEAMS_FAILURE,
)<Partial<Filter>, PaginatedResponse<BaseEntity>, Error>();

export const getTeam = createAsyncAction(
  TeamActionTypes.GET_TEAM,
  TeamActionTypes.GET_TEAM_SUCCESS,
  TeamActionTypes.GET_TEAM_FAILURE,
)<Required<CodeWithId>, Team | null, Error>();

export const createTeam = createAsyncAction(
  TeamActionTypes.CREATE_TEAM,
  TeamActionTypes.CREATE_TEAM_SUCCESS,
  TeamActionTypes.CREATE_TEAM_FAILURE,
)<CodeWithId<TeamFormShapeToRequest>, CreateUpdateTeamSuccess, Error>();

export const updateTeam = createAsyncAction(
  TeamActionTypes.UPDATE_TEAM,
  TeamActionTypes.UPDATE_TEAM_SUCCESS,
  TeamActionTypes.UPDATE_TEAM_FAILURE,
)<Required<CodeWithId<TeamFormShapeToRequest>>, CreateUpdateTeamSuccess, Error>();

export const deleteTeam = createAsyncAction(
  TeamActionTypes.DELETE_TEAM,
  TeamActionTypes.DELETE_TEAM_SUCCESS,
  TeamActionTypes.DELETE_TEAM_FAILURE,
)<Required<CodeWithId>, DeleteTeamResponse, Error>();

export const setFilter = createAction(TeamActionTypes.SET_FILTER)<Filter | null>();
