import { MemberListItem } from "src/shared/models";

export enum AlertAction {
  OPEN_ASSIGNMENTS = "OPEN_ASSIGNMENTS",
  OPEN_TRAININGS = "OPEN_TRAININGS",
  OPEN_CERTIFICATES = "OPEN_CERTIFICATES",
}

export enum AlertStatus {
  Scheduled = "Scheduled",
  Sent = "Sent",
  Archived = "Archived",
}
interface BaseAlert {
  text: string;
  send_date: string;
  is_everyone: boolean;
  created_by: string;
  status: AlertStatus;
}
export interface AlertListItem extends BaseAlert {
  id?: number;
  members_count: string;
}

export interface Alert extends BaseAlert {
  id: number;
  updated_by: string | null;
  members: MemberListItem[];
  action: AlertAction | null;
}
