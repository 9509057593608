import React, { FC, useCallback } from "react";
import { useDebouncedFunction } from "src/shared/hooks";
import { UserNotification } from "src/shared/models";
import classnames from "classnames";

import { UserNotificationListItem } from "../UserNotificationListItem";

import "./index.scss";

export interface UserNotificationListProps {
  notifications: UserNotification[];
  className?: string;
  onFinishScroll?: () => void;
  onNotificationClick?: (notification: UserNotification) => void;
}

export const UserNotificationList: FC<UserNotificationListProps> = (props) => {
  const { notifications, className, onFinishScroll, onNotificationClick } = props;

  const debouncedFinishScroll = useDebouncedFunction(onFinishScroll);

  const onScroll = useCallback(
    (event: React.SyntheticEvent<Element>) => {
      const maxScroll = event.currentTarget.scrollHeight - event.currentTarget.clientHeight;
      if (event.currentTarget.scrollTop >= maxScroll - 1 && debouncedFinishScroll) {
        debouncedFinishScroll();
      }
    },
    [debouncedFinishScroll],
  );

  return (
    <div className={classnames("user-notifications-list", className)} onScroll={onScroll}>
      {notifications.map((notification) => {
        return (
          <UserNotificationListItem
            onClick={onNotificationClick}
            key={notification.id}
            className="user-notifications-list__item"
            notification={notification}
          />
        );
      })}
    </div>
  );
};

export default UserNotificationList;
