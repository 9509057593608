import React from "react";
import { Button } from "src/shared/components";
import { DropDownButton } from "src/shared/components/Common/DropDownButton";
import { Training, TrainingStatus } from "src/shared/models";
import { TypeTraining } from "src/containers/TrainingAndCertificate/containers/TrainingCreateEditContainer/TrainingCreateEditContainer";

export interface RightComplexFormHeaderProps {
  training: Training | null;
  submitText: string | null;
  onSubmit: (params?: { isDraft: boolean }) => void;
  setTypeTraining: (typeAssignment: TypeTraining) => void;
}

const RightComplexFormHeader: React.FC<RightComplexFormHeaderProps> = (props) => {
  const { training, submitText, onSubmit, setTypeTraining } = props;

  return (
    <>
      {(!training || training.status === TrainingStatus.Draft) && (
        <div className="form-header-submit">
          <Button
            buttonStyle="draft"
            className={"training-save-draft"}
            onClick={() => {
              setTypeTraining && setTypeTraining(TypeTraining.SAVE_AS_DRAFT);
              return onSubmit({ isDraft: true });
            }}>
            {"Save as Draft"}
          </Button>
        </div>
      )}
      {training ? (
        submitText &&
        training.status !== TrainingStatus.Archived && (
          <div className="form-header-submit">
            <Button
              type="submit"
              onClick={() => {
                setTypeTraining && setTypeTraining(TypeTraining.CREATE_TRAINING);
                return onSubmit();
              }}>
              {submitText}
            </Button>
          </div>
        )
      ) : (
        <DropDownButton
          mainButtonName={"Publish"}
          optionNames={["Publish Training", "Publish and Duplicate"]}
          optionClickHandlers={[
            () => {
              setTypeTraining && setTypeTraining(TypeTraining.CREATE_TRAINING);
              return onSubmit();
            },
            () => {
              setTypeTraining && setTypeTraining(TypeTraining.CREATE_AND_DUPLICATE);
              return onSubmit();
            },
          ]}
        />
      )}
    </>
  );
};

export default RightComplexFormHeader;
