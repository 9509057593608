import { createAction, createAsyncAction } from "typesafe-actions";
import { PaginatedResponse, CodeWithId } from "src/shared/interfaces";
import { Project } from "src/shared/models";
import { SharedActionTypes } from "src/shared/store/constants";

import { ProjectActionTypes } from "./constants";
import {
  CreateUpdateProjectSuccess,
  ProjectDeleteResponse,
  ProjectFilter,
  ProjectFormShapeToRequest,
} from "../interface";

export const getProjects = createAsyncAction(
  ProjectActionTypes.GET_PROJECTS,
  ProjectActionTypes.GET_PROJECTS_SUCCESS,
  ProjectActionTypes.GET_PROJECTS_FAILURE,
)<Partial<ProjectFilter>, PaginatedResponse<Project>, Error>();

export const getProject = createAsyncAction(
  ProjectActionTypes.GET_PROJECT,
  ProjectActionTypes.GET_PROJECT_SUCCESS,
  ProjectActionTypes.GET_PROJECT_FAILURE,
)<Required<CodeWithId>, Project | null, Error>();

export const createProject = createAsyncAction(
  ProjectActionTypes.CREATE_PROJECT,
  ProjectActionTypes.CREATE_PROJECT_SUCCESS,
  ProjectActionTypes.CREATE_PROJECT_FAILURE,
)<CodeWithId<ProjectFormShapeToRequest>, CreateUpdateProjectSuccess, Error>();

export const updateProject = createAsyncAction(
  ProjectActionTypes.UPDATE_PROJECT,
  ProjectActionTypes.UPDATE_PROJECT_SUCCESS,
  ProjectActionTypes.UPDATE_PROJECT_FAILURE,
)<Required<CodeWithId<ProjectFormShapeToRequest>>, CreateUpdateProjectSuccess, Error>();

export const deleteProject = createAsyncAction(
  ProjectActionTypes.DELETE_PROJECT,
  ProjectActionTypes.DELETE_PROJECT_SUCCESS,
  ProjectActionTypes.DELETE_PROJECT_FAILURE,
)<Required<CodeWithId>, ProjectDeleteResponse, Error>();

export const clearProjects = createAction(ProjectActionTypes.CLEAR_PROJECTS)();

export const clearCurrentProject = createAction(SharedActionTypes.SET_LAST_CREATED_TEAM)();

export const setFilter = createAction(ProjectActionTypes.SET_FILTER)<ProjectFilter | null>();
