import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce, useOutsideClick } from "src/shared/hooks";
import { useLoader } from "src/shared/hooks/LoaderHook";
import { ActionTypes } from "src/shared/interfaces";

import "./index.scss";
import { Input } from '../Input';

interface AutocompleteInputInterface {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  inputType?: string;
  name: string;
  label?: string;
  loadOnFocus?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  prepareOptionFunction?: (element: any) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getData: (params: any) => any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectData: () => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  clearData?: (params: any) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onValueSelect?: (data: any) => void;
  noMatchesLabel?: string;
  disabled?: boolean;
  loadingAction?: ActionTypes;
}

const AutocompleteInput: FC<AutocompleteInputInterface> = (props) => {
  const {
    setFieldValue,
    selectData,
    getData,
    clearData,
    name,
    label,
    loadOnFocus,
    value,
    prepareOptionFunction,
    disabled,
    onValueSelect,
    noMatchesLabel,
    loadingAction,
  } = props;
  const [inputValue, setValue] = useState("");
  const [typing, setTyping] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [valueFound, setValueFound] = useState(true);
  const [isOpenAutomplete, setOpenAutocomplete] = useState(false);
  const wrapperRef = useRef(null);
  const { isOutside } = useOutsideClick(wrapperRef);
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const suggestionData: any[] = useSelector(selectData());
  const debouncedSearchTerm = useDebounce(inputValue, 500);

  const { isLoading } = useLoader({
    name: `${name}-input-list-loading`,
    actionTypes: useMemo(() => (loadingAction ? [loadingAction] : []), [loadingAction]),
  });

  useEffect(() => {
    if (prepareOptionFunction) {
      setSuggestions(suggestionData?.map(prepareOptionFunction));
    } else {
      setSuggestions(suggestionData);
    }
  }, [suggestionData, prepareOptionFunction]);

  useEffect(() => {
    setTyping(true);

    const typingTimeout = setTimeout(() => {
      setTyping(false);
    }, 1500);

    return () => {
      clearTimeout(typingTimeout);
    };
  }, [inputValue]);

  useEffect(() => {
    setOpenAutocomplete(false);
    clearData && dispatch(clearData([]));
  }, [isOutside, dispatch, clearData]);

  useEffect(() => {
    setValue(typeof value === "object" ? value.label : value);
  }, [value]);

  useEffect(() => {
    if (debouncedSearchTerm && !valueFound) {
      dispatch(getData({ search: debouncedSearchTerm }));
    }
  }, [debouncedSearchTerm, valueFound, loadOnFocus, getData, dispatch]);

  const handleValueChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: any) => {
      setFieldValue && setFieldValue(name, event.target.value);
      setValue(event.target.value);
      setValueFound(false);
      if (!event.target.value.length) {
        setValueFound(true);
        setFieldValue && setFieldValue(name, "");
      }
      setOpenAutocomplete(true);
    },
    [setFieldValue, name],
  );

  const handleSelect = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (suggestion: any) => {
      const data = { ...suggestion };
      setValue(data.label);
      setValueFound(true);
      clearData && dispatch(clearData([]));
      setFieldValue && setFieldValue(name, data);
      setOpenAutocomplete(false);
      onValueSelect && onValueSelect(data);
    },
    [dispatch, name, clearData, setFieldValue, onValueSelect],
  );

  const handleFocus = useCallback(() => {
    if (loadOnFocus) {
      dispatch(getData({ search: inputValue }));
    }
    setOpenAutocomplete(true);
  }, [dispatch, loadOnFocus, inputValue, getData]);

  return (
    <div className="search-body" ref={wrapperRef}>
      <Input
        value={inputValue || ""}
        id={name}
        label={label}
        onChange={handleValueChange}
        onFocus={handleFocus}
        autoComplete="off"
        disabled={disabled}
      />
      {isOpenAutomplete && inputValue && (!noMatchesLabel ? Boolean(suggestions?.length) : true) && (
        <div className="autocomplete-variants">
          {Boolean(suggestions?.length) &&
            suggestions.map((item, index) => (
              <div key={`${item.label}-${index}`} className="autocomplete-variant" onClick={() => handleSelect(item)}>
                {item.label}
              </div>
            ))}
          {!typing && !isLoading && noMatchesLabel && (!suggestions || !suggestions.length) && (
            <div className="autocomplete-variant autocomplete-variant_no_matches_found">No matches found</div>
          )}
        </div>
      )}
    </div>
  );
};

export default AutocompleteInput;
