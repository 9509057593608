import { BaseEntity } from "src/shared/interfaces";

import { Location, MemberListItem, Creator, CertificateTrainingPassItem, ShortMember, Signature } from "src/shared/models";

export enum TrainingStatus {
  Active = "Active",
  Archived = "Archived",
  Draft = "Draft",
}

export enum TrainingRepeatEveryPeriod {
  MONTH = "Month",
  WEEK = "Week",
  YEAR = "Year",
}
interface BaseTraining {
  id: number;
  name: string;
  status: TrainingStatus;
  repeat_every_period?: TrainingRepeatEveryPeriod | null;
  repeat_every?: number | null;
  expiration_date?: string | null;
  scheduled_start_date?: string | null;
  scheduled_end_date?: string | null;
  is_auto_create_certificate?: boolean;
  is_expiration_or_scheduled_date?: boolean;
  created_at: string;
  passed_training?: {
    created_at: string;
  } | null;
  activities: BaseEntity[];
}

export interface TrainingListItem extends BaseTraining {
  assigned_members_count: string;
  passed_members_count: string;
}

export interface TrainingPassMember extends ShortMember {
  user_id: number | null;
}

interface TrainingMember extends MemberListItem {
  is_auto: boolean;
  is_deleted?: boolean;
}

export interface Training extends BaseTraining {
  held_by: string | null;
  description: string | null;
  location: Location | null;
  is_recurring_training: boolean;
  certificate_name: string | null;
  certificate_issued_by: string | null;
  certificate_valid: number | null;
  certificate_valid_period: "Year" | "Month" | "Week" | null;
  members: TrainingMember[];
  projects: BaseEntity[];
  updated_at: string | null;
  updated_by: string | null;
  creator: Creator;
  is_public: boolean;
}

export interface TrainingPass {
  member_id: number;
  created_at: Date | string;
  creator_user: Creator;
  confirm_user: Creator | null;
  member: TrainingPassMember;
  certificate: CertificateTrainingPassItem | null;
  confirmed_at: string | null;
  signature: Signature | null;
}
