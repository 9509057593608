import { createAction, createAsyncAction } from "typesafe-actions";
import {
  Member,
  File,
  Note,
  MemberWithTrainingInfoListItem,
  MemberWithCertificateInfoListItem,
  MemberJsaListItem,
  MemberPassedJsa,
  Certificate,
} from "src/shared/models";
import {
  PaginatedResponse,
  Filter,
  CodeWithId,
  CreateFileShape,
  CreateFileSuccess,
  DeleteFileSuccess,
  BaseResponse,
} from "src/shared/interfaces";
import { MemberActionTypes } from "src/containers/Member/store/constants";
import {
  MemberFormShapeToRequest,
  MemberFormUpdateShape,
  CreateMemberSuccess,
  CreateMemberNoteShape,
  CreateMemberNoteSuccess,
  DeleteMemberFileShape,
  DeleteMemberNoteShape,
  DeleteMemberNoteSuccess,
  UpdateMemberStatusShape,
  SetMemberSignatureRequestShape,
  SetMemberSignatureResponseShape,
  TrainingAndCertificateMemberFilter,
  MembersResponse,
} from "src/containers/Member/interface";

export const getMembers = createAsyncAction(
  MemberActionTypes.GET_MEMBERS,
  MemberActionTypes.GET_MEMBERS_SUCCESS,
  MemberActionTypes.GET_MEMBERS_FAILURE,
)<Filter, MembersResponse, Error>();

export const getMembersTrainings = createAsyncAction(
  MemberActionTypes.GET_MEMBER_TRAININGS,
  MemberActionTypes.GET_MEMBER_TRAININGS_SUCCESS,
  MemberActionTypes.GET_MEMBER_TRAININGS_FAILURE,
)<TrainingAndCertificateMemberFilter, PaginatedResponse<MemberWithTrainingInfoListItem>, Error>();

export const getMembersCertificates = createAsyncAction(
  MemberActionTypes.GET_MEMBER_CERTIFICATES,
  MemberActionTypes.GET_MEMBER_CERTIFICATES_SUCCESS,
  MemberActionTypes.GET_MEMBER_CERTIFICATES_FAILURE,
)<TrainingAndCertificateMemberFilter, PaginatedResponse<MemberWithCertificateInfoListItem>, Error>();

export const getMembersJsa = createAsyncAction(
  MemberActionTypes.GET_MEMBER_JSA,
  MemberActionTypes.GET_MEMBER_JSA_SUCCESS,
  MemberActionTypes.GET_MEMBER_JSA_FAILURE,
)<TrainingAndCertificateMemberFilter, PaginatedResponse<MemberJsaListItem>, Error>();

export const setTrainingAndCertificateMemberFilter = createAction(
  MemberActionTypes.SET_TRAINING_AND_CERTIFICATE_MEMBER_FILTER,
)<TrainingAndCertificateMemberFilter | null>();

export const getMember = createAsyncAction(
  MemberActionTypes.GET_MEMBER,
  MemberActionTypes.GET_MEMBER_SUCCESS,
  MemberActionTypes.GET_MEMBER_FAILURE,
)<CodeWithId, Member | null, Error>();

export const getMemberFiles = createAsyncAction(
  MemberActionTypes.GET_MEMBER_FILES,
  MemberActionTypes.GET_MEMBER_FILES_SUCCESS,
  MemberActionTypes.GET_MEMBER_FILES_FAILURE,
)<CodeWithId, File[], Error>();

export const addMemberFile = createAsyncAction(
  MemberActionTypes.ADD_MEMBER_FILE,
  MemberActionTypes.ADD_MEMBER_FILE_SUCCESS,
  MemberActionTypes.ADD_MEMBER_FILE_FAILURE,
)<CodeWithId & CreateFileShape, CreateFileSuccess, Error>();

export const deleteMemberFile = createAsyncAction(
  MemberActionTypes.DELETE_MEMBER_FILE,
  MemberActionTypes.DELETE_MEMBER_FILE_SUCCESS,
  MemberActionTypes.DELETE_MEMBER_FILE_FAILURE,
)<DeleteMemberFileShape, DeleteFileSuccess, Error>();

export const addMemberNote = createAsyncAction(
  MemberActionTypes.ADD_MEMBER_NOTE,
  MemberActionTypes.ADD_MEMBER_NOTE_SUCCESS,
  MemberActionTypes.ADD_MEMBER_NOTE_FAILURE,
)<CodeWithId & CreateMemberNoteShape, CreateMemberNoteSuccess, Error>();

export const deleteMemberNote = createAsyncAction(
  MemberActionTypes.DELETE_MEMBER_NOTE,
  MemberActionTypes.DELETE_MEMBER_NOTE_SUCCESS,
  MemberActionTypes.DELETE_MEMBER_NOTE_FAILURE,
)<DeleteMemberNoteShape, DeleteMemberNoteSuccess, Error>();

export const getMemberNotes = createAsyncAction(
  MemberActionTypes.GET_MEMBER_NOTES,
  MemberActionTypes.GET_MEMBER_NOTES_SUCCESS,
  MemberActionTypes.GET_MEMBER_NOTES_FAILURE,
)<CodeWithId, Note[], Error>();

export const createMember = createAsyncAction(
  MemberActionTypes.CREATE_MEMBER,
  MemberActionTypes.CREATE_MEMBER_SUCCESS,
  MemberActionTypes.CREATE_MEMBER_FAILURE,
)<CodeWithId<MemberFormShapeToRequest>, CreateMemberSuccess, Error>();

export const updateMember = createAsyncAction(
  MemberActionTypes.UPDATE_MEMBER,
  MemberActionTypes.UPDATE_MEMBER_SUCCESS,
  MemberActionTypes.UPDATE_MEMBER_FAILURE,
)<Required<CodeWithId<MemberFormUpdateShape>>, CreateMemberSuccess, Error>();

export const updateMemberStatus = createAsyncAction(
  MemberActionTypes.UPDATE_MEMBER_STATUS,
  MemberActionTypes.UPDATE_MEMBER_STATUS_SUCCESS,
  MemberActionTypes.UPDATE_MEMBER_STATUS_FAILURE,
)<Required<CodeWithId<UpdateMemberStatusShape>>, CreateMemberSuccess, Error>();

export const clearMembers = createAction(MemberActionTypes.CLEAR_MEMBERS)();

export const setFilter = createAction(MemberActionTypes.SET_FILTER)<Filter | null>();

export const setMemberSignature = createAsyncAction(
  MemberActionTypes.SET_MEMBER_SIGNATURE,
  MemberActionTypes.SET_MEMBER_SIGNATURE_SUCCESS,
  MemberActionTypes.SET_MEMBER_SIGNATURE_FAILURE,
)<Required<CodeWithId<SetMemberSignatureRequestShape>>, SetMemberSignatureResponseShape, Error>();

export const deleteMemberSignature = createAsyncAction(
  MemberActionTypes.DELETE_MEMBER_SIGNATURE,
  MemberActionTypes.DELETE_MEMBER_SIGNATURE_SUCCESS,
  MemberActionTypes.DELETE_MEMBER_SIGNATURE_FAILURE,
)<Required<CodeWithId>, BaseResponse, Error>();

export const addMemberCerificate = createAction(MemberActionTypes.ADD_CERTIFICATE)<Certificate>();

export const updateMemberCerificate = createAction(MemberActionTypes.UPDATE_CERTIFICATE)<Certificate>();

export const getMemberPassedJsa = createAsyncAction(
  MemberActionTypes.GET_MEMBER_PASSED_JSA,
  MemberActionTypes.GET_MEMBER_PASSED_JSA_SUCCESS,
  MemberActionTypes.GET_MEMBER_PASSED_JSA_FAILURE,
)<CodeWithId, MemberPassedJsa, Error>();
