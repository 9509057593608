import React from "react";
import classnames from "classnames";
import { Icon } from "src/shared/components";

import "./index.scss";

export interface FormLinkProps {
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
  text: string;
}

export default function FormLink(props: FormLinkProps) {
  const { onClick, text, className } = props;
  return (
    <div className={classnames("form-link", className)} onClick={onClick}>
      {text}
      <Icon type="front-orange" />
    </div>
  );
}
