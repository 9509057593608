import React, { FC, useCallback } from "react";
import { useDispatch } from "react-redux";
import { ContainerWithCompanyCode } from "src/shared/interfaces";
import { ContactUsForm } from "src/containers/ContactUs/components";
import { Icon } from "src/shared/components";
import { getBrowserVersion } from "src/shared/utils";
import { actions } from "src/containers/ContactUs/store";

import "./index.scss";

export interface ContactUsContainerProps extends Required<ContainerWithCompanyCode> {
  onCancel?: () => void;
}

const ContactUsContainer: FC<ContactUsContainerProps> = ({ onCancel, companyCode }) => {
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (payload) => {
      dispatch(
        actions.createSupportMessage.request({
          code: companyCode,
          payload: {
            ...payload,
            version: getBrowserVersion(),
          },
        }),
      );
    },
    [dispatch, companyCode],
  );

  return (
    <div className="contact-us-container">
      {onCancel && (
        <div className="contact-us-container-cancel-btn" onClick={onCancel}>
          <Icon type="close" />
        </div>
      )}
      <ContactUsForm submitHandler={onSubmit} />
    </div>
  );
};

export default ContactUsContainer;
