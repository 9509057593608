import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { generatePath } from "react-router";
import { MenuItem } from "src/shared/interfaces";
import { User, Company, Member, UserRole } from "src/shared/models";
import { Icon, Profile } from "src/shared/components";
import { hasAdminRole } from "src/shared/utils";
import { useUserNotification } from "src/containers/UserNotification/hooks";
import { NameOfChildRoutes, NameOfRoutes } from "src/shared/constants";
import classnames from "classnames";

import { Item } from "./Item";
import { ItemContent } from "./Item/Item";

import "./index.scss";

export interface SidebarProps {
  items: MenuItem[];
  user: User | null;
  activePath?: string;
  onLogout: () => void;
  companyCode: string | null;
  company: Company | null;
  onBackClick?: () => void;
  onSwitch?: () => void;
  authorizedMember?: Member | null;
  onCollapse?: (collapsed: boolean) => void;
}

const autoCloseSideBar = window.innerWidth <= 1400;

const getUserRoleByCompany = (user: User, companyCode: string | null) => {
  return (
    user.user_roles.find((ur) => ur.role.is_app_admin) ||
    user.user_roles.find((ur) => companyCode && ur.company && ur.company.code === companyCode)
  );
};

const getAvailableMenuItems = (items: MenuItem[], userRole: UserRole) => {
  return items.filter((i) => userRole.role.permissions.find((p) => p.name === i.permission));
};

const getComponentsMenuItems = (
  availableItems: MenuItem[],
  companyCode: string | null,
  activePath: string | null | undefined,
  showTooltip: boolean,
) => {
  return availableItems.map((item) => {
    const itemPath = companyCode ? generatePath(item.path, { code: companyCode }) : item.path;
    const isActiveItem = activePath?.includes(itemPath) && (companyCode ? itemPath?.includes(companyCode) : true);
    return (
      <Item
        iconType={item.iconType}
        active={isActiveItem}
        path={itemPath}
        name={item.name}
        key={item.name}
        showTooltip={showTooltip}
      />
    );
  });
};

export interface AvatarProps {
  member?: Member | null;
  user: User | null;
}

const Avatar: FC<AvatarProps> = ({ member, user }) => {
  const firstName = member ? member.first_name : user?.first_name;
  const lastName = member ? member.last_name : user?.last_name;
  return (
    <div className="avatar">
      {member?.avatar ? (
        <img src={member.avatar.url} />
      ) : (
        <span>
          {firstName?.[0].toUpperCase()}
          {lastName?.[0].toUpperCase()}
        </span>
      )}
    </div>
  );
};

const Sidebar: FC<SidebarProps> = (props) => {
  const {
    items,
    activePath,
    user,
    onLogout,
    company,
    onBackClick,
    onSwitch,
    companyCode,
    authorizedMember,
    onCollapse,
  } = props;
  const [showProfile, setShowProfile] = useState(false);

  const [hideInnerElements, setHideInnerElements] = useState(autoCloseSideBar);
  const [openSidebar, setOpenSidebar] = useState(!autoCloseSideBar);

  useEffect(() => {
    onCollapse?.(openSidebar);
  }, [onCollapse, openSidebar]);

  const toggle = useCallback(() => {
    let timeout;
    if (openSidebar) {
      clearTimeout(timeout);
      setHideInnerElements(true);
      timeout = setTimeout(() => {
        setOpenSidebar(false);
      }, 250);
    } else {
      clearTimeout(timeout);
      setOpenSidebar(true);
      timeout = setTimeout(() => {
        setHideInnerElements(false);
      }, 250);
    }
  }, [openSidebar]);

  const isAdminRole = useMemo(() => {
    return hasAdminRole(user);
  }, [user]);

  const isSwitcher = useMemo(() => {
    const userRoles = user?.user_roles || [];
    return !isAdminRole && userRoles.length !== 1;
  }, [user, isAdminRole]);

  const renderItems = useMemo(() => {
    if (!user) {
      return [];
    }

    const userRole = getUserRoleByCompany(user, companyCode);
    if (!userRole) {
      return [];
    }

    const availableItems = getAvailableMenuItems(items, userRole);
    return getComponentsMenuItems(availableItems, companyCode, activePath, !openSidebar);
  }, [items, activePath, companyCode, user, openSidebar]);

  const memberPrifileMenuItem = useMemo(() => {
    if (!companyCode || !authorizedMember) return;

    const memberPagePath = generatePath(`${NameOfRoutes.COMPANIES}${NameOfChildRoutes.COMPANY.MEMBER_PROFILE}`, {
      code: companyCode,
      id: String(authorizedMember.id),
    });

    return (
      <Item
        iconType="user"
        active={activePath?.includes(memberPagePath) && (companyCode ? memberPagePath.includes(companyCode) : true)}
        path={memberPagePath}
        name="Member Profile"
        showTooltip={!openSidebar}
      />
    );
  }, [companyCode, authorizedMember, activePath, openSidebar]);

  const { isOpenUserNotificationOverlay, showUserNotificationOverlay, unreadNotificationsCount } =
    useUserNotification(companyCode);

  const notificationsMenuItem = useMemo(() => {
    return (
      <ItemContent
        iconType="notifications"
        name="Notifications"
        badged={Boolean(unreadNotificationsCount)}
        active={isOpenUserNotificationOverlay}
        onClick={showUserNotificationOverlay}
        showTooltip={!openSidebar}
      />
    );
  }, [isOpenUserNotificationOverlay, showUserNotificationOverlay, unreadNotificationsCount, openSidebar]);

  const onToggleProfile = useCallback(() => {
    setShowProfile((prev) => !prev);
  }, []);

  return (
    <div
      className={classnames("sidebar", {
        sidebar_closed: !openSidebar,
        sidebar_opened: openSidebar,
        "sidebar_hide-inner-elements": hideInnerElements,
      })}>
      <div className="sidebar-header">
        {isAdminRole &&
          (company ? (
            <div className="sidebar-back">
              <Icon onClick={onBackClick} type="back-white" />
              Companies
            </div>
          ) : (
            <div className="sidebar-title">
              <div className="sidebar-logo" />
              SafeTo
            </div>
          ))}
        {!isAdminRole && company && (
          <div className="sidebar-company-title">
            <div className="sidebar-company-title-name" title={company.name}>
              {company.name}
            </div>
            {isSwitcher && (
              <div className="sidebar-company-title-switch" onClick={onSwitch}>
                Switch
              </div>
            )}
          </div>
        )}
        <Icon onClick={toggle} className="toggle-menu-btn" type="menu-toggle" />
      </div>
      {isAdminRole && company && (
        <div className="sidebar-admin-company" title={company.name}>
          <span className="sidebar-admin-company-name">{company.name}</span>
        </div>
      )}
      <div className="sidebar-content">{renderItems}</div>
      {user && (
        <div className="sidebar-bottom-content">
          {!!company && notificationsMenuItem}
          {memberPrifileMenuItem}
          <div className="sidebar-footer">
            {showProfile ? (
              <Profile user={user} companyCode={companyCode} onLogOut={onLogout} onOutsideClick={onToggleProfile} />
            ) : null}
            <div className="user-info" onClick={onToggleProfile}>
              <Avatar user={user} member={authorizedMember} />
              <span className="user-info-name">
                {user.first_name} {user.last_name}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
