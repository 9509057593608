import { ActionWithPayload, PayloadWithCode } from "src/shared/interfaces";
import { startLoading, stopLoading, hideModal } from "src/shared/store/actions";
import { call, put, takeLatest } from "redux-saga/effects";

import api from "../api";
import { ContactUsFormShape, ContactUsSuccessResponse } from "../interface";
import { createSupportMessage } from "./actions";

function* createSupportMessageSaga({ payload }: ActionWithPayload<Required<PayloadWithCode<ContactUsFormShape>>>) {
  try {
    yield put(startLoading());
    const response: ContactUsSuccessResponse = yield call(api.createSupportMessage, payload);
    yield put(stopLoading());
    yield put(createSupportMessage.success(response));
    yield put(hideModal());
  } catch (error) {
    yield put(createSupportMessage.failure(error as Error));
    yield put(stopLoading());
  }
}

function* activitySagas() {
  yield takeLatest(createSupportMessage.request, createSupportMessageSaga);
}

export default activitySagas;
