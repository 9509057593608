import { TeamFormShapeToRequest } from "src/containers/Team/interface";
import { FieldItem } from "src/shared/components/FormGenerator";
import { FORM_ERROR_MESSAGES } from "src/shared/constants";
import { FieldItemType } from "src/shared/interfaces";
import * as Yup from "yup";

export const validationSchema: Yup.SchemaOf<TeamFormShapeToRequest> = Yup.object().shape({
  name: Yup.string().required(FORM_ERROR_MESSAGES.REQUIRED).max(70, `Team Name ${FORM_ERROR_MESSAGES.LONG}`),
  members: Yup.array().optional(),
});

export const fields: FieldItem[] = [
  {
    type: FieldItemType.TEXT,
    name: "name",
    placeholder: " ",
    label: "",
    wrapperClass: "input-line",
  },
];

export const initValues: TeamFormShapeToRequest = {
  name: "",
  members: [],
};
