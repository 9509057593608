import { API, METHODS, SCHEMAS } from "src/shared/constants";
import { API_ENUM } from "src/shared/constants/api";
import { SchemasMap } from "src/shared/interfaces";
import { mapToSchema, generateDynamicSchemaKeyString } from "src/shared/utils";
import {
  baseFilterSchema,
  getPaginatedResponseSchema,
  createUpdateTeamRequestSchema,
  createUpdateTeamResponseSchema,
  teamSchema,
  teamDeleteResponseSchema,
} from "src/shared/validators";

const schemaShapes: SchemasMap<keyof typeof API.TEAM> = {
  [API_ENUM.GET_TEAMS]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.TEAM.GET_TEAMS("$id"))}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: baseFilterSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.TEAM.GET_TEAMS("$id"))}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: getPaginatedResponseSchema(teamSchema),
    },
  },
  [API_ENUM.GET_TEAM]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.TEAM.GET_TEAM("$id", "$id"))}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: {},
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.TEAM.GET_TEAM("$id", "$id"))}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: teamSchema,
    },
  },
  [API_ENUM.CREATE_TEAM]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.TEAM.CREATE_TEAM("$id"))}${SCHEMAS.REQUEST}${METHODS.POST}`,
      schema: createUpdateTeamRequestSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.TEAM.CREATE_TEAM("$id"))}${SCHEMAS.RESPONSE}${METHODS.POST}`,
      schema: createUpdateTeamResponseSchema,
    },
  },
  [API_ENUM.UPDATE_TEAM]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.TEAM.UPDATE_TEAM("$id", "$id"))}${SCHEMAS.REQUEST}${METHODS.PUT}`,
      schema: createUpdateTeamRequestSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.TEAM.UPDATE_TEAM("$id", "$id"))}${SCHEMAS.RESPONSE}${METHODS.PUT}`,
      schema: createUpdateTeamResponseSchema,
    },
  },
  [API_ENUM.DELETE_TEAM]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.TEAM.DELETE_TEAM("$id", "$id"))}${SCHEMAS.REQUEST}${METHODS.DELETE}`,
      schema: {},
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.TEAM.DELETE_TEAM("$id", "$id"))}${SCHEMAS.RESPONSE}${METHODS.DELETE}`,
      schema: teamDeleteResponseSchema,
    },
  },
};

export default mapToSchema(schemaShapes);
