import { BaseModel } from "src/shared/interfaces";

import { Creator, File } from "./";

export enum TaskStatus {
  Archived = "Archived",
  Active = "Active",
  Draft = "Draft",
}

interface BaseTask extends BaseModel {
  index: string;
  name: string;
  status: TaskStatus;
  hazard_control_proactive_measures: string | null;
  potential_hazards: string | null;
  steps: string | null;
}

export interface Task extends BaseTask {
  potential_risk?: string;
  hazard_risk?: string;
  parent_task_id?: number;
  company_id: number | null;
  position?: number;
  steps_media?: File[];
  hazard_control_proactive_measures_media?: File[];
  potential_hazards_media?: File[];
}

export interface TaskListItem extends BaseTask {
  creator: Creator;
}

export interface TaskJsa {
  id: number;
  name: string;
  position: number;
}
