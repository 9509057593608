import React, { useEffect, useMemo, useState } from "react";
import type { FC, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { authentificated } from "src/containers/Auth/store/selectors";
import { NameOfRoutes } from "src/shared/constants";
import { useParams } from "react-router";
import { checkPermission, checkPermissions } from "src/shared/utils";
import { actions as sharedActions } from "src/shared/store";
import { selectors, actions } from "src/containers/Company/store";
import { useLoader } from "src/shared/hooks/LoaderHook";

interface AuthGuardProps {
  children: ReactNode;
  permission?: string | string[];
  onlyAppAdmin?: boolean;
  checkCompanyCode?: boolean;
  companyCode?: string;
}

const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children, checkCompanyCode, permission, onlyAppAdmin, companyCode } = props;
  const dispatch = useDispatch();
  const company = useSelector(selectors.getCurrentCompany());
  const isAuthenticated = useSelector(authentificated());
  const params = useParams();
  const [companyFirstLoading, setCompanyFirstLoading] = useState(false);

  const code = useMemo(() => {
    return params.code || companyCode;
  }, [params.code, companyCode]);

  useEffect(() => {
    if (code) {
      setCompanyFirstLoading(false);
    }
  }, [code]);

  const companyLoading = useLoader({
    name: "CompanyInfo",
    actionTypes: useMemo(() => [actions.getCompany], []),
  });

  useEffect(() => {
    if (!companyLoading.isLoading && code && (!company || company.code !== code) && !companyFirstLoading) {
      setCompanyFirstLoading((prev) => !prev);
      dispatch(actions.getCompany.request(code));
    }
  }, [dispatch, company, code, companyLoading.isLoading, companyFirstLoading]);

  if (!isAuthenticated) {
    return <Navigate to={NameOfRoutes.AUTH_LOGIN} />;
  }

  const options = { companyCode: checkCompanyCode ? code : undefined, onlyAppAdmin };

  const hasPermission =
    permission && Array.isArray(permission)
      ? checkPermissions(permission, options)
      : checkPermission(permission, options);

  if (!hasPermission) {
    dispatch(
      sharedActions.showNotification({
        message: "Permission denied",
        appearance: "error",
      }),
    );
    return <Navigate to={NameOfRoutes.AUTH_LOGIN} />;
  }

  return <>{children}</>;
};

export default AuthGuard;
