import React, { FC, useCallback, useMemo } from "react";
import moment from "moment";
import { actions as sharedActions, selectors as sharedSelectors } from "src/shared/store";
import { AutocompleteCheckboxMultiSelect, DateRangePicker, InputSearch, Select } from "src/shared/components";
import { certificateMemberStatusesOptions, trainingMemberStatusesOptions } from "src/shared/constants";
import { Team, TeamStatus } from "src/shared/models";
import { Option } from "src/shared/interfaces";
import { TrainingAndCertificateMemberFilter } from "src/containers/Member/interface";
import { defaultMemberFilter } from "src/containers/Member/store/reducer";

import "./index.scss";

export enum MemberTableType {
  Trainings = "Trainings",
  Certificates = "Certificates",
}

export const tableTypeOptions: Option[] = [
  {
    label: MemberTableType.Trainings,
    value: MemberTableType.Trainings,
  },
  {
    label: MemberTableType.Certificates,
    value: MemberTableType.Certificates,
  },
];

export const prepareTeamOptions = (item: Team) => {
  return { label: item.name, value: item.id || null };
};

export interface MemberHeaderProps {
  onChange: (filter: Partial<TrainingAndCertificateMemberFilter>) => void;
  filter: TrainingAndCertificateMemberFilter;
  companyCode?: string;
  memberTableType: MemberTableType;
  onTableTypeChange?: (value: MemberTableType) => void;
}

const MemberHeader: FC<MemberHeaderProps> = (props) => {
  const { onChange, filter, companyCode, memberTableType, onTableTypeChange } = props;

  const getTeams = useCallback(
    (search: string, page?: number) => {
      return sharedActions.getTeams.request({
        search,
        page: page || 0,
        limit: 10,
        status: TeamStatus.Active,
        company_code: companyCode,
      });
    },
    [companyCode],
  );

  const onFieldChange = useCallback(
    (field: string, newValue: unknown) => {
      onChange({ [field]: newValue });
    },
    [onChange],
  );

  const onDatePickerFilterChange = useCallback(
    (field1: string, value1: unknown, field2: string, value2: unknown) => {
      onChange({ [field1]: value1, [field2]: value2 });
    },
    [onChange],
  );

  const onStatusChange = useCallback((value) => onFieldChange("status", value), [onFieldChange]);

  const onTypeChange = useCallback(
    (value) => {
      onStatusChange(undefined);
      onTableTypeChange?.(value);
    },
    [onStatusChange, onTableTypeChange],
  );

  const onTeamsChange = useCallback(
    (value) => {
      return onFieldChange("teams", value);
    },
    [onFieldChange],
  );

  const statusOptions = useMemo(() => {
    return memberTableType === MemberTableType.Trainings
      ? trainingMemberStatusesOptions
      : certificateMemberStatusesOptions;
  }, [memberTableType]);

  const statusValue = useMemo(() => {
    return statusOptions.find((sOpt) => sOpt.value === filter.status) || null;
  }, [filter.status, statusOptions]);

  const tableTypeValue = useMemo(() => {
    return tableTypeOptions.find((tOpt) => tOpt.value === memberTableType) || null;
  }, [memberTableType]);

  return (
    <div className="training-header">
      <AutocompleteCheckboxMultiSelect
        staticOptions={[{ value: "-1", label: "No Teams" }]}
        options={[]}
        value={filter.teams || []}
        name="teams"
        placeholder="All Teams"
        getData={getTeams}
        selectData={sharedSelectors.getTeams}
        selectTotalCount={sharedSelectors.getTeamsTotal}
        onChange={onTeamsChange}
        prepareOptionFunction={prepareTeamOptions}
      />
      <DateRangePicker
        className="members-list-daterange-picker"
        cleanable={false}
        showReset={true}
        resetValues={[moment(defaultMemberFilter.start_date).toDate(), moment(defaultMemberFilter.end_date).toDate()]}
        onChange={([start, end]) => {
          if (start && end) {
            onDatePickerFilterChange(
              "start_date",
              start.startOf("day").toISOString(),
              "end_date",
              end.endOf("day").toISOString(),
            );
          }
        }}
        value={[filter.start_date ? moment(filter.start_date) : null, filter.end_date ? moment(filter.end_date) : null]}
        showCancel={true}
      />
      <Select
        value={tableTypeValue}
        className="members-list-type-select"
        onChange={onTypeChange}
        options={tableTypeOptions}
        isClearable={false}
      />
      <Select
        value={statusValue}
        className="members-list-status-select"
        onChange={onStatusChange}
        options={statusOptions}
        placeholder="All Statuses"
        isClearable={true}
      />
      <InputSearch
        onChangeSearch={(search) => onFieldChange("search", search)}
        placeholder="Search"
        showClearText={true}
        showClose={true}
        value={filter.search || ""}
      />
    </div>
  );
};

export default MemberHeader;
