import { createSelector } from "reselect";
import { AppState } from "src/shared/interfaces/State.interface";

const selectTrainingAndCertificate = (state: AppState) => state.trainingAndCertificate;

export const getTrainings = () => createSelector(selectTrainingAndCertificate, (state) => state.trainings);
export const getTrainingsTotal = () => createSelector(selectTrainingAndCertificate, (state) => state.trainingsTotal);
export const getCurrentTraining = () => createSelector(selectTrainingAndCertificate, (state) => state.currentTraining);

export const getCertificates = () => createSelector(selectTrainingAndCertificate, (state) => state.certificates);
export const getCertificatesTotal = () =>
  createSelector(selectTrainingAndCertificate, (state) => state.certificatesTotal);
export const getCurrentCertificate = () =>
  createSelector(selectTrainingAndCertificate, (state) => state.currentCertificate);
export const getCurrentCertificateFiles = () =>
  createSelector(selectTrainingAndCertificate, (state) => state.currentCertificateFiles);

export const getTrainingAndCertificateFilter = () =>
  createSelector(selectTrainingAndCertificate, (state) => state.trainingAndCertificateFilter);

export const getTrainingPass = () => createSelector(selectTrainingAndCertificate, (state) => state.trainingPass);

export const getCertificatesIssuedBy = () =>
  createSelector(selectTrainingAndCertificate, (state) => state.certificatesIssuedBy);
export const getCertificatesIssuedByTotal = () =>
  createSelector(selectTrainingAndCertificate, (state) => state.certificatesIssuedByTotal);

export const getCertificatesTemplates = () =>
  createSelector(selectTrainingAndCertificate, (state) => state.certificatesTemplates);

export const getCertificatesTemplatesTotal = () =>
  createSelector(selectTrainingAndCertificate, (state) => state.certificateTemplatesTotal);

export const getCertificatesTemplatesAdditional = () =>
  createSelector(selectTrainingAndCertificate, (state) => state.certificatesTemplatesAdditional);

export const getCertificatesTemplatesTotalAdditional = () =>
  createSelector(selectTrainingAndCertificate, (state) => state.certificateTemplatesTotalAdditional);

export const getClearTrainingForm = () =>
  createSelector(selectTrainingAndCertificate, (state) => state.clearTrainingForm);
