import React from "react";
import { MemberWithTrainingInfoListItem } from "src/shared/models";
import { StatusLabel, TableProperty } from "src/shared/components";
import { getUSFormattedDate } from "src/shared/utils";

export const memberTableProperties: TableProperty<MemberWithTrainingInfoListItem>[] = [
  {
    title: "Team Member",
    key: "first_name",
    get: (item) => `${item.first_name} ${item.last_name}`,
    sort: ["member_name"],
  },
  {
    title: "Name",
    key: "training_name",
    get: (item) => item.training_name,
    sort: ["name"],
  },
  {
    title: "Assigned by",
    key: "assigned_by",
    get: (item) => item.assigned_by,
  },
  {
    title: "Expire Date",
    key: "training_expiration_date",
    get: (item) => getUSFormattedDate(item.training_expiration_date),
    sort: ["expiration_date"],
  },
  {
    title: "Status",
    key: "status",
    get: (item) => <StatusLabel status={item.status} />,
    sort: ["status"],
  },
];
