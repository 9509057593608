import React, { FC, useCallback, useMemo } from "react";
import moment from "moment";
import {
  AddButton,
  InputSearch,
  AccessControl,
  AutocompleteCheckboxMultiSelect,
  Select,
  DateRangePicker,
} from "src/shared/components";
import { PERMISSION, trainingMemberStatusesOptions, trainingStatusesOptions } from "src/shared/constants";
import { ActivityListItem } from "src/shared/models";
import { actions as activityActions, selectors as activitySelectors } from "src/containers/Activity/store";
import { TrainingAndCertificateFilter } from "src/containers/TrainingAndCertificate/interface";
import { ORDER_TYPE } from "src/shared/interfaces";
import { Option } from "src/shared/components/Common/Field/AutocompleteMultiSelect/AutocompleteCheckboxMultiSelect";

import "./index.scss";

const DEFAULT_ACTIVITIES_OPTIONS: Option[] = [];
const DEFAULT_STATIC_ACTIVITIES_OPTIONS: Option[] = [{ label: "No Activity", value: "-1" }];
export const prepareActivityOptions = (item: ActivityListItem) => {
  return { label: item.name, value: String(item.id) };
};

export interface TrainingAndCertificateHeaderProps {
  memberId?: number;
  onChange: (filter: Partial<TrainingAndCertificateFilter>) => void;
  filter: TrainingAndCertificateFilter;
  onCreate?: () => void;
  companyCode?: string;
  createPermission: PERMISSION;
  allMembersLabel?: string;
  isTeamMember?: boolean;
  showStatusFilter?: boolean;
  showDateFilter?: boolean;
}

const TrainingAndCertificateHeader: FC<TrainingAndCertificateHeaderProps> = (props) => {
  const {
    onChange,
    filter,
    onCreate,
    companyCode,
    memberId,
    createPermission,
    allMembersLabel = "All",
    isTeamMember,
    showStatusFilter,
    showDateFilter,
  } = props;

  const membersSelectOptions = useMemo(() => {
    if (!memberId) return [];

    return [
      { label: allMembersLabel, value: null },
      { label: "Assigned To Me", value: memberId },
    ];
  }, [memberId, allMembersLabel]);

  const onFieldChange = useCallback(
    (field: string, newValue: unknown) => {
      onChange({ [field]: newValue });
    },
    [onChange],
  );

  const onMemberChange = useCallback(
    (value: unknown) => {
      onFieldChange("member_id", value);
    },
    [onFieldChange],
  );

  const onActivitiesChange = useCallback(
    (value: Option[]) => {
      onFieldChange("activities", value);
    },
    [onFieldChange],
  );

  const onChangeTrainingStatus = useCallback(
    (value: unknown) => {
      onFieldChange("statuses", value ? [value] : undefined);
    },
    [onFieldChange],
  );

  const onChangeMemberTrainingStatus = useCallback(
    (value: unknown) => {
      onFieldChange("member_status", value);
    },
    [onFieldChange],
  );

  const onDatePickerFilterChange = useCallback(
    (field: string, newValue: unknown, field2: string, value2: unknown) => {
      onChange({ [field]: newValue, [field2]: value2 });
    },
    [onChange],
  );

  const getActivities = useCallback(
    (search: string, page?: number) => {
      return activityActions.getActivities.request({
        search,
        page: page || 0,
        limit: 10,
        company_code: companyCode,
        sort_by: ["name"],
        sort_order: ORDER_TYPE.ASC,
      });
    },
    [companyCode],
  );

  const renderStatusFilter = useMemo(() => {
    if (!showStatusFilter) {
      return null;
    }
    return isTeamMember ? (
      <Select
        value={filter.member_status ? { label: filter.member_status, value: filter.member_status } : null}
        onChange={onChangeMemberTrainingStatus}
        options={trainingMemberStatusesOptions}
        placeholder="All Statuses"
        isClearable={true}
      />
    ) : (
      <Select
        value={filter.statuses && filter.statuses[0] ? { label: filter.statuses[0], value: filter.statuses[0] } : null}
        onChange={onChangeTrainingStatus}
        options={trainingStatusesOptions}
        placeholder="All Statuses"
        isClearable={true}
      />
    );
  }, [filter, onChangeTrainingStatus, onChangeMemberTrainingStatus, isTeamMember, showStatusFilter]);

  const renderDateRangePicker = useMemo(() => {
    if (!showDateFilter) {
      return null;
    }
    return (
      <DateRangePicker
        resetValues={[null, null]}
        cleanable={true}
        onChange={([start, end]) => {
          onDatePickerFilterChange(
            "start_date",
            start ? start.startOf("day").toISOString() : null,
            "end_date",
            end ? end.endOf("day").toISOString() : null,
          );
        }}
        value={[filter.start_date ? moment(filter.start_date) : null, filter.end_date ? moment(filter.end_date) : null]}
      />
    );
  }, [filter, onDatePickerFilterChange, showDateFilter]);

  return (
    <div className="training-header">
      {renderDateRangePicker}
      {renderStatusFilter}
      {memberId ? (
        <AccessControl permission={PERMISSION.VIEW_MEMBERS} option={{ companyCode, onlyAppAdmin: !companyCode }}>
          <Select
            value={membersSelectOptions.find((o) => o.value === filter.member_id)}
            onChange={onMemberChange}
            options={membersSelectOptions}
            placeholder={allMembersLabel}
          />
        </AccessControl>
      ) : null}
      <AccessControl
        permission={PERMISSION.VIEW_COMPANY_ACTIVITY_LIBRARY}
        option={{ companyCode, onlyAppAdmin: !companyCode }}>
        <AutocompleteCheckboxMultiSelect
          value={filter.activities || []}
          options={DEFAULT_ACTIVITIES_OPTIONS}
          staticOptions={DEFAULT_STATIC_ACTIVITIES_OPTIONS}
          name="activities"
          placeholder="All Activities"
          getData={getActivities}
          selectData={activitySelectors.getActivities}
          selectTotalCount={activitySelectors.getActivitiesTotal}
          onChange={onActivitiesChange}
          prepareOptionFunction={prepareActivityOptions}
          className="training-activities-filter"
        />
      </AccessControl>
      <InputSearch
        onChangeSearch={(search) => onFieldChange("search", search)}
        placeholder="Search"
        showClearText={true}
        showClose={true}
        value={filter.search || ""}
      />
      {onCreate && (
        <AccessControl permission={createPermission} option={{ companyCode, onlyAppAdmin: !companyCode }}>
          <AddButton onClick={onCreate} />
        </AccessControl>
      )}
    </div>
  );
};

export default TrainingAndCertificateHeader;
