import { createSelector } from "reselect";
import { AppState } from "src/shared/interfaces/State.interface";

const selectDocument = (state: AppState) => state.document;

export const getFilter = () => createSelector(selectDocument, (state) => state.filter);
export const getDocuments = () => createSelector(selectDocument, (state) => state.documents);
export const getDocumentsTotal = () => createSelector(selectDocument, (state) => state.documentsTotal);

export const getCurrentDocument = () => createSelector(selectDocument, (state) => state.currentDocument);
