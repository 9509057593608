import React, { FC, useMemo } from "react";
import { Certificate, CertificateStatus, File } from "src/shared/models";
import { FormHeader, LabelArray, FormDivider } from "src/shared/components";
import { getUSFormattedDateShort, getFullUserName } from "src/shared/utils";
import { FilesBlockView } from "src/shared/components/FilesBlock/FilesBlock";

import "./index.scss";

export interface CertificateViewProps {
  certificate: Certificate;
  onCancel?: () => void;
  onEdit?: () => void;
  isTeamMember?: boolean;
  files: File[];
  onClickMember: () => void;
}

const CertificateView: FC<CertificateViewProps> = (props) => {
  const { certificate, onCancel, onEdit, isTeamMember, files, onClickMember } = props;

  const isArchived = useMemo(() => {
    return certificate?.status === CertificateStatus.Archived;
  }, [certificate]);

  return (
    <div className="certificate-view-wrapper">
      <FormHeader
        onSubmit={onEdit}
        title={isArchived ? "Archived Certificate" : "View Certificate"}
        onCancel={onCancel}
        submitText={onEdit && !isArchived ? "Edit" : undefined}
      />
      <div className="certificate-view-content">
        <div className="certificate-view-row">
          <div>
            <div className="certificate-subtitle">Name</div>
            <div className="certificate-name">{certificate.name}</div>
          </div>
        </div>
        {isTeamMember ? (
          <FormDivider />
        ) : (
          <div className="certificate-member-wrapper" onClick={onClickMember}>
            <div className="certificate-member-avatar">
              {certificate.member.avatar && <img src={certificate.member.avatar.url} />}
            </div>
            <div className="certificate-member-info">
              <div className="certificate-member-name">{getFullUserName(certificate.member)}</div>
              {certificate.member.job && <div className="certificate-member-job">{certificate.member.job.name}</div>}
            </div>
          </div>
        )}
        <div className="certificate-view-row">
          <div className="certificate-view-column certificate-issued-by">
            <div className="certificate-subtitle">Issued By</div>
            <div className="certificate-value">{certificate.issued_by}</div>
          </div>
          <div className="certificate-view-column">
            <div className="certificate-subtitle">Issued On</div>
            <div className="certificate-value">{getUSFormattedDateShort(certificate.issued_on)}</div>
          </div>
          <div className="certificate-view-column">
            <div className="certificate-subtitle">Valid</div>
            <div className="certificate-value">
              {certificate.valid} {certificate.valid_period}
              {certificate.valid >= 2 ? "s" : ""}
            </div>
          </div>
        </div>
        <FormDivider />
        <LabelArray items={certificate.activities} title="Activities" />
        <LabelArray items={certificate.projects} title="Projects" />
        <FilesBlockView withoutContainer className="certificate-form-files-block" files={files} emptyFileText={null} />
      </div>
    </div>
  );
};

export default CertificateView;
