import React, { useState, FC, useMemo, useEffect, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { Formik, FormikProps } from "formik";
import { CreateFileShape, FormProps } from "src/shared/interfaces";
import { AccessControl, FilesBlock, FormDivider, FormGenerator, FormHeader } from "src/shared/components";
import { Certificate, CertificateStatus, File, CertificateTemplate } from "src/shared/models";
import { CertificateFormShape } from "src/containers/TrainingAndCertificate/interface";
import { useCertificateTemplateSelector } from "src/containers/TrainingAndCertificate/hooks";
import { ACCEPT_FILE_TYPES, MAX_INPUT_FILE_SIZE, PERMISSION } from "src/shared/constants";
import { actions as sharedActions } from "src/shared/store";
import { prepareOptionFunction } from "src/shared/utils";

import { validationSchema, getFields, getInitValues, handlers } from "./formHelpers";

import "./index.scss";

export interface CertificateFormProps {
  certificate?: Certificate | null;
  onCancel: () => void;
  onChangeForm: () => void;
  onArchive: () => void;
  companyCode: string;
  onCreateFile: (payload: CreateFileShape) => void;
  onDeleteFile: (file: File) => void;
  files: File[];
}

const CertificateForm: FC<FormProps<CertificateFormShape> & CertificateFormProps> = (props) => {
  const {
    certificate,
    onCancel,
    onChangeForm,
    companyCode,
    submitHandler,
    onArchive,
    onCreateFile,
    onDeleteFile,
    files,
  } = props;
  const dispatch = useDispatch();
  const formikRef = useRef<FormikProps<CertificateFormShape>>(null);
  const [formValues, setFormValues] = useState(getInitValues(certificate));

  useEffect(() => {
    setFormValues(getInitValues(certificate));
  }, [certificate]);

  const memoizedHandlers = useMemo(() => {
    return handlers(companyCode);
  }, [companyCode]);

  const onSubmit = useCallback(
    (values: CertificateFormShape, { setSubmitting }) => {
      submitHandler?.(values);
      setSubmitting(true);
    },
    [submitHandler],
  );

  const selectCertificateTemplateCb = useCallback((template: CertificateTemplate) => {
    if (formikRef.current) {
      formikRef.current.setFieldValue("name", template.name);
      formikRef.current.setFieldValue("issued_by", template.issued_by);
      formikRef.current.setFieldValue("activities", template.activities.map(prepareOptionFunction));
      formikRef.current.setFieldValue("projects", template.projects.map(prepareOptionFunction));
      setTimeout(() => formikRef.current && formikRef.current.validateField("name"), 0);
    }
  }, []);

  const { selectedCertificateTemplate, onChangeCertificateTemplateName, onChangeCertificateTemplateIssuedBy } =
    useCertificateTemplateSelector({
      companyCode,
      selectCertificateTemplateCb,
      getFilterData: () => {
        if (formikRef.current) {
          const { name, issued_by } = formikRef.current.values;

          return { name, issued_by };
        }
      },
    });

  const fields = useMemo(() => {
    return getFields({
      onChangeIssuedBy: onChangeCertificateTemplateIssuedBy,
      onChangeName: onChangeCertificateTemplateName,
      hasTemplate: Boolean(selectedCertificateTemplate),
    });
  }, [onChangeCertificateTemplateIssuedBy, onChangeCertificateTemplateName, selectedCertificateTemplate]);

  const isArchived = useMemo(() => {
    return certificate?.status === CertificateStatus.Archived;
  }, [certificate]);

  const displayFileRejectMessage = useCallback(
    (message: string) => {
      dispatch(
        sharedActions.showNotification({
          message,
          appearance: "error",
        }),
      );
    },
    [dispatch],
  );

  return (
    <Formik
      innerRef={formikRef}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnMount={false}
      validateOnChange={true}
      validate={onChangeForm}
      enableReinitialize={true}
      initialValues={formValues}>
      {(formikProps) => (
        <>
          <FormGenerator
            firstChildren={
              <FormHeader
                permission={PERMISSION.CREATE_EDIT_ARCHIVE_COMPANY_CERTIFICATES}
                companyCode={companyCode || null}
                title={certificate ? "Edit Certificate" : "New Certificate"}
                onCancel={onCancel}
                submitText={certificate ? "Save" : "Create"}
              />
            }
            handlers={memoizedHandlers}
            fields={fields}
            formikProps={formikProps}
          />
          <FilesBlock
            withoutContainer={true}
            className="certificate-form-files-block"
            isDisabled={!certificate}
            onCreateFile={onCreateFile}
            onDeleteFile={onDeleteFile}
            files={files}
            permission={PERMISSION.CREATE_EDIT_ARCHIVE_COMPANY_CERTIFICATES}
            companyCode={companyCode}
            onReject={displayFileRejectMessage}
            maxFileSize={MAX_INPUT_FILE_SIZE}
            emptyFileText={certificate ? "No files yet" : "Please create a certificate to add files"}
            deprecatedClick={!certificate}
            acceptFileTypes={ACCEPT_FILE_TYPES}
          />
          {!isArchived && certificate && (
            <AccessControl permission={PERMISSION.CREATE_EDIT_ARCHIVE_COMPANY_CERTIFICATES} option={{ companyCode }}>
              <div className="certificate-additional-form-archive">
                <FormDivider />
                <div className="certificate-additional-form-archive-button" onClick={onArchive}>
                  Archive
                </div>
              </div>
            </AccessControl>
          )}
        </>
      )}
    </Formik>
  );
};

export default CertificateForm;
