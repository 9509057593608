import { locationCommonProperties } from "./location.schema";
import { certificateFromTrainingPass } from "./certificate.schema";
import { signatureProperties } from "./signature.schema";

import { shortUserSchema, getRequestSchema, arrayOfBaseEntities, shortMemberSchema } from "src/shared/validators";

const commonTrainingProperties = {
  id: { type: "number" },
  name: { type: "string" },
  expiration_date: { type: ["string", "null"] },
  sort_date: { type: ["string", "null"] },
  scheduled_start_date: { type: ["string", "null"] },
  scheduled_end_date: { type: ["string", "null"] },
  repeat_every: { type: ["number", "null"] },
  repeat_every_period: { type: ["string", "null"] },
  is_auto_create_certificate: { type: ["boolean", "null"] },
};

const trainingGeneralProperties = {
  held_by: { type: ["string", "null"] },
  description: { type: ["string", "null"] },
  status: { type: "string" },
  is_expiration_or_scheduled_date: { type: "boolean" },
  is_recurring_training: { type: "boolean" },
  certificate_name: { type: ["string", "null"] },
  certificate_issued_by: { type: ["string", "null"] },
  certificate_valid: { type: ["number", "null"] },
  certificate_valid_period: { type: ["string", "null"] },
};

export const trainingSchema = {
  type: "object",
  additionalProperties: false,
  required: ["id", "name", "created_at"],
  properties: {
    created_at: { type: "string" },
    updated_at: { type: ["string", "null"] },
    updated_by: { type: ["string", "null"] },
    creator: shortUserSchema,
    location: {
      type: ["object", "null"],
      additionalProperties: false,
      properties: {
        ...locationCommonProperties,
      },
    },
    projects: arrayOfBaseEntities,
    activities: arrayOfBaseEntities,
    members: {
      type: "array",
      items: shortMemberSchema,
    },
    is_public: { type: "boolean" },
    is_assigned_authorized_member: { type: "boolean" },
    ...commonTrainingProperties,
    ...trainingGeneralProperties,
  },
};

export const trainingFromListSchema = {
  type: "object",
  additionalProperties: false,
  required: ["id", "name", "created_at"],
  properties: {
    created_at: { type: "string" },
    assigned_members_count: { type: "string" },
    passed_members_count: { type: "string" },
    passed_training: {
      type: ["object", "null"],
      properties: {
        created_at: { type: "string" },
      },
    },
    activities: arrayOfBaseEntities,
    projects: arrayOfBaseEntities,
    ...commonTrainingProperties,
  },
};

export const createTrainingRequestSchema = {
  type: "object",
  required: ["name", "status"],
  additionalProperties: false,
  properties: {
    ...commonTrainingProperties,
    ...trainingGeneralProperties,
    location_place_id: { type: ["string", "null"] },
    company_code: { type: "string" },
    is_public: { type: ["boolean", "null"] },
    members: {
      type: "array",
      items: {
        type: "number",
      },
    },
    activities: {
      type: "array",
      items: {
        type: "number",
      },
    },
    projects: {
      type: "array",
      items: {
        type: "number",
      },
    },
  },
};

export const updateTrainingStatusSchema = {
  type: "object",
  additionalProperties: false,
  properties: {
    status: { type: "string" },
  },
};

export const updateTrainingResponseSchema = {
  type: "object",
  additionalProperties: false,
  required: ["message", "training"],
  properties: {
    message: { type: "string" },
    training: trainingSchema,
  },
};

export const trainingFilterSchema = getRequestSchema({
  page: { type: "number" },
  limit: { type: "number" },
  search: { type: ["string", "null"] },
  member_id: { type: ["number", "null"] },
  activities: { type: "array" },
  code: { type: "string" },
});

export const trainingPassFilterSchema = getRequestSchema({
  member_id: { type: ["number", "null"] },
});

const trainingPassProperties = {
  member_id: { type: "number" },
  created_at: { type: "string" },
  confirmed_at: { type: ["string", "null"] },
  creator_user: shortUserSchema,
  confirm_user: {
    type: ["object", "null"],
    properties: {
      id: { type: "number" },
      first_name: { type: "string" },
      last_name: { type: "string" },
    },
  },
  member: shortMemberSchema,
  id: { type: "number" },
  certificate: {
    type: ["object", "null"],
    properties: {
      ...certificateFromTrainingPass.properties,
    },
  },
  signature: {
    type: ["object", "null"],
    additionalProperties: false,
    properties: {
      ...signatureProperties,
    },
  },
};

export const trainingPassSchema = {
  type: "array",
  items: {
    type: "object",
    additionalProperties: false,
    properties: trainingPassProperties,
  },
};

export const passTrainingRequestSchema = {
  type: "object",
  additionalProperties: false,
  properties: {
    member_id: { type: "number" },
  },
};

export const passTrainingResponseSchema = {
  type: "object",
  additionalProperties: false,
  properties: {
    message: { type: "string" },
    passed_training: {
      type: "object",
      properties: trainingPassProperties,
    },
  },
};
