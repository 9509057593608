import { createAction, createAsyncAction } from "typesafe-actions";
import { Tag, TagListItem } from "src/shared/models";
import { PaginatedResponse, Filter, BaseResponse } from "src/shared/interfaces";
import { TagActionTypes } from "./constants";
import { TagFormShapeToRequest, CreateTagSuccess } from "../interface";

export const getTags = createAsyncAction(
  TagActionTypes.GET_TAGS,
  TagActionTypes.GET_TAGS_SUCCESS,
  TagActionTypes.GET_TAGS_FAILURE,
)<Partial<Filter>, PaginatedResponse<Tag>, Error>();

export const getTag = createAsyncAction(
  TagActionTypes.GET_TAG,
  TagActionTypes.GET_TAG_SUCCESS,
  TagActionTypes.GET_TAG_FAILURE,
)<{ tag_id: number | string }, Tag | null, Error>();

// export const clearTags = createAction(TagActionTypes.CLEAR_TAGS)();

export const createTag = createAsyncAction(
  TagActionTypes.CREATE_TAG,
  TagActionTypes.CREATE_TAG_SUCCESS,
  TagActionTypes.CREATE_TAG_FAILURE,
)<TagFormShapeToRequest, CreateTagSuccess, Error>();
