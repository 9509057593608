import { Middleware, Dispatch } from "redux";
import { createReduxHistoryContext } from "redux-first-history";
import createSagaMiddleware from "redux-saga";
import { configureStore } from "@reduxjs/toolkit";
import freeze from "redux-freeze";
import { NameOfRoutes } from "src/shared/constants";
import appSagas from "./saga";
import rootReducer from "./reducer";
import { showNotification, navigate } from "../shared/store/actions";
import history from "../shared/history";

const sagaMiddleware = createSagaMiddleware();

const errorHandlerMiddleware: Middleware = () => (next: Dispatch) => (action) => {
  if (action.type.includes("FAILURE")) {
    next(
      showNotification({
        message: action.payload.error || action.payload.message,
        appearance: "error",
      }),
    );

    if (action.payload && [401, 403].includes(action.payload.code)) {
      next(navigate(NameOfRoutes.AUTH_LOGIN));
    }
  }

  if (action.type.includes("SUCCESS") && action.payload && action.payload.message) {
    next(
      showNotification({
        message: action.payload.message,
        appearance: "success",
      }),
    );
  }
  return next(action);
};

// TOOL-KIT WAY
export default function configureAppStore() {
  const { routerMiddleware, routerReducer } = createReduxHistoryContext({ history });

  const middleware = (getDefaultMiddleware: any) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['@src/shared/SHOW_OVERLAY','@src/shared/SHOW_MODAL'],
        // Ignore these field paths in all actions
       // ignoredActionPaths: ['shared.overlay.body.$$typeof'],
        // Ignore these paths in the state
        ignoredPaths: ['shared.overlay.body','shared.modal.body','shared.modal.onConfirm'],
      },
    }).concat(errorHandlerMiddleware, routerMiddleware, sagaMiddleware, freeze);

//@src/shared/SHOW_OVERLAY'

  const store = configureStore({
    reducer: rootReducer(routerReducer),
    devTools: process.env.NODE_ENV !== "production", // Enable Redux DevTools in development
    middleware,
  });

  sagaMiddleware.run(appSagas);

  if ((module as any).hot) {
    (module as any).hot.accept(() => store.replaceReducer(rootReducer(routerReducer)));
  }

  // if (process.env.NODE_ENV !== "production" && module.hot) {
  //   module.hot.accept("./reducer", () => store.replaceReducer(rootReducer(routerReducer)));
  // }

  return { store };
}



// // OLD Way
// import { createStore, combineReducers, applyMiddleware, Middleware, compose, Dispatch } from "redux";
// import { createReduxHistoryContext } from "redux-first-history";
// import createSagaMiddleware from "redux-saga";
// import { composeWithDevTools } from "@redux-devtools/extension";
// import freeze from "redux-freeze";
// import { NameOfRoutes } from "src/shared/constants";

// import appSagas from "./saga";
// import rootReducer from "./reducer";
// import { showNotification, navigate } from "../shared/store/actions";
// import history from "../shared/history";

// const sagaMiddleware = createSagaMiddleware();

// let middleware: Array<Middleware>;
// // eslint-disable-next-line @typescript-eslint/ban-types
// let composer: Function;

// if (process.env.NODE_ENV === "development") {
//   middleware = [sagaMiddleware, freeze];
//   composer = composeWithDevTools({ trace: true, traceLimit: 25 });
// } else {
//   middleware = [sagaMiddleware];
//   composer = compose;
// }

// const errorHandlerMiddleware: Middleware = () => (next: Dispatch) => (action) => {
//   if (action.type.includes("FAILURE")) {
//     console.log("FAILURE");
//     next(
//       showNotification({
//         message: action.payload.error || action.payload.message,
//         appearance: "error",
//       }),
//     );

//     if (action.payload && [401, 403].includes(action.payload.code)) {
//       next(navigate(NameOfRoutes.AUTH_LOGIN));
//     }
//   }

//   if (action.type.includes("SUCCESS") && action.payload && action.payload.message) {
//     console.log("SUCCESS");
//     next(
//       showNotification({
//         message: action.payload.message,
//         appearance: "success",
//       }),
//     );
//   }
//   return next(action);
// };

// export default function configureAppStore() {
//   const { routerMiddleware, routerReducer } = createReduxHistoryContext({history});

//   const store = createStore(
//     rootReducer(routerReducer),
//     undefined,
//     composer(applyMiddleware(...middleware, routerMiddleware, errorHandlerMiddleware)),
//   );

//   sagaMiddleware.run(appSagas);

//   if ((module as any).hot) {
//     (module as any).hot.accept(() => store.replaceReducer(rootReducer(routerReducer)));
//   }

//   return { store };
// }