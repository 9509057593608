import React, { FC } from "react";
import { getUSFormatedTime, getUSFormattedDate } from "src/shared/utils";
import { Icon } from "src/shared/components";
import { TIMEZONE } from "src/shared/constants";

import "./index.scss";

export interface DateTimeHeaderProps {
  isDraft?: boolean;
  startDate?: Date | string | null;
  endDate?: Date | string | null;
  onlyDate?: boolean;
}

const DateTimeHeader: FC<DateTimeHeaderProps> = ({ startDate, endDate, isDraft, onlyDate }) => {
  return (
    <div className="date-time-header">
      {isDraft && <span className="status">(Draft)</span>}
      <span className="date">
        <Icon type="calendar" className="date-icon" />
        {getUSFormattedDate(startDate, TIMEZONE.EST)}
      </span>
      {startDate && !onlyDate && (
        <span className="start-end-time">
          <Icon type="clock" className="start-end-time-icon" />
          {getUSFormatedTime(startDate, TIMEZONE.EST)} {endDate && <> - {getUSFormatedTime(endDate, TIMEZONE.EST)}</>}
        </span>
      )}
    </div>
  );
};

export default DateTimeHeader;
