import React, { useMemo, useCallback } from "react";
import moment from "moment";
import { ErrorMessage, DatePicker, Switcher, SwitchState } from "src/shared/components/Common/";
import { FieldItemType } from "src/shared/interfaces";

import { RenderField, GenerateRenderField } from "../../interfaces";

export interface TimePickerSwitcherFieldItem extends RenderField {
  type: FieldItemType.TIMEPICKER_SWITCHER;
  label?: string;
  format?: string;
  disabled?: boolean;
  onChange?: (date: Date | null) => void;
  showMeridian?: boolean;
}

export const GenerateTimepickerSwitcher: GenerateRenderField<TimePickerSwitcherFieldItem> = (props) => {
  const {
    name,
    wrapperClass,
    label,
    disabled,
    onChange,
    formikProps: { setFieldValue, values, touched, errors },
  } = props;

  const handleChange = useCallback(
    (value: moment.Moment | null) => {
      const newValue = value ? value.toDate() : null;
      setFieldValue(name, newValue);
      onChange && onChange(newValue);
    },
    [setFieldValue, name, onChange],
  );

  const mDateValue = useMemo(() => (values[name] ? moment(values[name]) : null), [values, name]);

  const dateValueMeridian = useMemo(() => {
    const meridian = mDateValue?.format("a");
    if (meridian === "pm") {
      return SwitchState.OFF;
    } else if (meridian === "am") {
      return SwitchState.ON;
    }
    return null;
  }, [mDateValue]);

  const setMeridian = useCallback(
    (switchValue) => {
      const newMDateValue = mDateValue?.clone();
      if (!newMDateValue) {
        return;
      }

      if (switchValue === SwitchState.OFF) {
        newMDateValue.add(12, "h");
      } else if (switchValue === SwitchState.ON) {
        newMDateValue.add(-12, "h");
      }

      handleChange(newMDateValue);
    },
    [mDateValue, handleChange],
  );

  return (
    <div className="input-wrapper time-picker-switcher-wrapper" key={name} id={name}>
      <label className="label">{label}</label>
      <div className="time-picker-switcher">
        <div className="time-picker-switcher-hours-selector">
          <DatePicker
            className={wrapperClass}
            onChange={handleChange}
            disabled={disabled}
            format="hh:mm"
            value={mDateValue}
            view="time"
            cleanable={false}
            showMeridian={true}
          />
        </div>
        <div className="time-picker-switcher-meridian-selector">
          <Switcher switchOnText="AM" switchOffText="PM" value={dateValueMeridian} onChange={setMeridian} />
        </div>
      </div>
      <ErrorMessage isTouched={!!touched[name]} error={errors[name]?.toString()} />
    </div>
  );
};
