import { AnyAction, combineReducers } from "redux";
import { History } from "history";
import { AuthReducer } from "src/containers/Auth/store/reducer";
import { UserReducer } from "src/containers/User/store/reducer";
import { CompanyReducer } from "src/containers/Company/store/reducer";
import { AppState } from "src/shared/interfaces";
import { SharedReducer } from "src/shared/store/reducer";
import { MemberReducer } from "src/containers/Member/store/reducer";
import { ActivityReducer } from "src/containers/Activity/store/reducer";
import { TagReducer } from "src/containers/Tag/store/reducer";
import { TaskReducer } from "src/containers/Task/store/reducer";
import { JsaReducer } from "src/containers/Jsa/store/reducer";
import { AssignmentReducer } from "src/containers/Assignment/store/reducer";
import { DocumentReducer } from "src/containers/Documents/store/reducer";
import { TrainingAndCertificateReducer } from "src/containers/TrainingAndCertificate/store/reducer";
import { AlertReducer } from "src/containers/Alert/store/reducer";
import { UserNotificationReducer } from "src/containers/UserNotification/store/reducer";
import { ProjectReducer } from "src/containers/Project/store/reducer";
import { TeamReducer } from "src/containers/Team/store/reducer";
import { createReduxHistoryContext, RouterState } from 'redux-first-history';


export default (routerReducer: any) => {


  const appReducer = combineReducers({
    router: routerReducer,
    shared: SharedReducer,
    auth: AuthReducer,
    user: UserReducer,
    company: CompanyReducer,
    member: MemberReducer,
    activity: ActivityReducer,
    tag: TagReducer,
    task: TaskReducer,
    jsa: JsaReducer,
    assignment: AssignmentReducer,
    document: DocumentReducer,
    trainingAndCertificate: TrainingAndCertificateReducer,
    alert: AlertReducer,
    userNotification: UserNotificationReducer,
    project: ProjectReducer,
    team: TeamReducer,
  });

  return (state: AppState | undefined, action: AnyAction) => {
    const appr = appReducer(state,action);
    return appr;
  };
};
