import React, { useState, FC } from "react";
import { Formik } from "formik";
import { LoginShape } from "src/containers/Auth/interface/AuthForm.interface";
import { FormProps } from "src/shared/interfaces";
import { RedirectLink, FormGenerator, Button } from "src/shared/components";
import { NameOfRoutes } from "src/shared/constants";

import { validationSchema, fields, initValues } from "./formHelpers";

const getRedirectLink = (email: string) => {
  const clippedEmail = email ? email.trim() : "";
  return `${NameOfRoutes.AUTH_FORGOT_PASSWORD}${clippedEmail ? `?email=${clippedEmail}` : ""}`;
};

const LoginForm: FC<FormProps<LoginShape>> = (props) => {
  const [formValues] = useState(initValues);

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        props.submitHandler && props.submitHandler(values);
        setSubmitting(false);
      }}
      initialValues={formValues}>
      {(formikProps) => (
        <>
          <FormGenerator fields={fields} formikProps={formikProps}>
            <RedirectLink to={getRedirectLink(formikProps.values.email)}>Forgot Password?</RedirectLink>
            <Button type="submit" disabled={props.loading || !validationSchema.isValidSync(formikProps.values)}>
              Log In
            </Button>
          </FormGenerator>
        </>
      )}
    </Formik>
  );
};

export default LoginForm;
