import { createAsyncAction } from "typesafe-actions";
import { PayloadWithCode } from "src/shared/interfaces";
import { ContactUsActionTypes } from "src/containers/ContactUs/store/constants";

import { ContactUsFormShape, ContactUsSuccessResponse } from "../interface";

export const createSupportMessage = createAsyncAction(
  ContactUsActionTypes.CREATE_SUPPORT_MESSAGE,
  ContactUsActionTypes.CREATE_SUPPORT_MESSAGE_SUCCESS,
  ContactUsActionTypes.CREATE_SUPPORT_MESSAGE_FAILURE,
)<Required<PayloadWithCode<ContactUsFormShape>>, ContactUsSuccessResponse, Error>();
