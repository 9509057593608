import React, { useCallback, useMemo } from "react";
import classnames from "classnames";
import { useModal, useHideModal } from "src/shared/hooks";
import { Button } from "src/shared/components";

import "./index.scss";

const ModalWrapper = () => {
  const { modal } = useModal();
  const { handleOnClose } = useHideModal();

  const localCancel = useCallback(() => {
    modal.onCancel && modal.onCancel();
    handleOnClose();
  }, [modal, handleOnClose]);

  const localConfirm = useCallback(() => {
    modal.onConfirm && modal.onConfirm();
    handleOnClose();
  }, [modal, handleOnClose]);

  const handleCloseOutside = useCallback(() => {
    modal.closeOnOutside && handleOnClose();
  }, [handleOnClose, modal]);

  const style = useMemo(() => ({ width: modal.modalWidth || "330px" }), [modal]);

  return modal.show ? (
    <>
      <div className="modal-overlay" onClick={handleCloseOutside} />
      <div className={classnames("modal-wrapper", modal.wrapperClass)} style={style}>
        {modal.title && <div className="modal-title">{modal.title}</div>}
        {modal.body && <div className="modal-body">{modal.body}</div>}
        {(modal.onConfirm || modal.onCancel) && (
          <div className="modal-buttons">
            {!modal.hideCancelButton && (
              <Button buttonStyle="bordered" type="button" onClick={localCancel}>
                {modal.cancelText || "Cancel"}
              </Button>
            )}
            <Button
              className={classnames({ "modal-buttons__block-btn": modal.hideCancelButton })}
              type="button"
              onClick={localConfirm}>
              {modal.confirmText || "Confirm"}
            </Button>
          </div>
        )}
      </div>
    </>
  ) : null;
};

export default ModalWrapper;
