import React, { FC } from "react";
import classnames from "classnames";
import { Icon, RedirectLink, Tooltip } from "src/shared/components";
import { IconType } from "src/shared/components/Icon/Icon";

import "./index.scss";

export interface ItemContentProps {
  name: string;
  active?: boolean;
  badged?: boolean;
  onClick?: () => void;
  iconType?: IconType;
  showTooltip?: boolean;
}

export const ItemContent: FC<ItemContentProps> = (props) => {
  const { active, badged, name, onClick, iconType, showTooltip } = props;
  return (
    <div
      onClick={onClick}
      className={classnames("sidebar-menu-item", {
        active,
        badged,
      })}>
      {iconType &&
        (showTooltip ? (
          <Tooltip content={name} forSidebar={true}>
            <Icon className="menu-icon" type={iconType} />
          </Tooltip>
        ) : (
          <Icon className="menu-icon" type={iconType} />
        ))}
      <span className="sidebar-menu-item-content">{name}</span>
    </div>
  );
};

export interface ItemProps extends ItemContentProps {
  path: string;
  iconType?: IconType;
  showTooltip?: boolean;
}

export const Item: FC<ItemProps> = (props) => {
  const { path, ...contentProps } = props;
  return (
    <RedirectLink to={path}>
      <ItemContent {...contentProps} />
    </RedirectLink>
  );
};

export default Item;
