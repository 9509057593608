import {
  UserStatus,
  JsaStatus,
  TaskStatus,
  MemberStatus,
  ActivityStatus,
  TrainingStatus,
  TeamStatus,
} from "src/shared/models";

export enum ORDER_TYPE {
  ASC = "ASC",
  DESC = "DESC",
  NONE = "",
}

export interface BaseFilter {
  page: number;
  limit: number;
  company_code?: string | null;
  search?: string | null;
}

export interface Filter extends BaseFilter {
  status?: UserStatus | TaskStatus | JsaStatus | MemberStatus | ActivityStatus | TrainingStatus | TeamStatus | null;
  team_id?: number | null;
  teams?: number[];
  industry_id?: number | null;
  role_id?: number | null;
  member_id?: number | null;
  is_supervisor?: boolean;
  is_library?: boolean | null;
  is_assigned_to_me?: boolean | null;
  exclude?: number | null;
  activities?: number[] | { label: string; value: string }[];
  tags?: number[] | { label: string; value: string }[];
  extend?: boolean;
  types?: string[];
  sort_by?: string[];
  sort_order?: ORDER_TYPE;
  certificate_expire_date?: Date | null;
  member_ids?: number[];
  additional?: boolean;
}

export interface GetRolesFilter {
  is_app_admin: boolean;
}

export interface CodeWithId<T = null> {
  code: string;
  id?: number | string;
  payload?: T;
}

export interface PayloadWithId<T = null> {
  id: number | string;
  payload: T;
}

export interface PayloadWithCode<T = null> {
  code: string;
  payload?: T;
}

export interface IdWithOptionalCode<T = null> {
  id: number | string;
  code?: string | null;
  payload: T;
}
