import React, { useMemo, useState, useEffect } from "react";
import "./index.scss";
import { TabBarNav } from "src/shared/components/TabBar/TabBarNav";
import classNames from "classnames";

export interface TabBar {
  className?: string;
  children: {
    props: {
      label: string;
      isAccess?: boolean;
    };
  }[];
  onChangeActiveTabHandler?: (label: string, number: number) => void;
  isLoading?: boolean;
  activeTab?: number;
}

const TabBar: React.FC<TabBar> = (props) => {
  const { children, className, onChangeActiveTabHandler, isLoading } = props;
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    setActiveTab(props.activeTab ? props.activeTab : 0);
  }, [props.activeTab]);

  const activeChildren = useMemo(
    () => children?.filter(({ props }) => (props.isAccess === undefined ? true : props.isAccess)),
    [children],
  );

  const labelsList = useMemo(() => activeChildren?.map(({ props }) => props.label), [activeChildren]);

  const onChangeActiveTab = (label: string) => {
    setActiveTab(labelsList.indexOf(label));
    if (onChangeActiveTabHandler) {
      onChangeActiveTabHandler(label, labelsList.indexOf(label));
    }
  };

  const renderTabs = () =>
    labelsList.map((label: string, key: number) => (
      <TabBarNav
        label={label}
        key={label}
        className={key === activeTab ? "active-tab" : ""}
        onChangeActiveTab={onChangeActiveTab}
      />
    ));

  const renderContent = () => {
    return activeChildren[activeTab];
  };

  const classes = classNames("tab-bar", className);

  return (
    <div className={classes}>
      <div className="tab-bar-nav">{renderTabs()}</div>
      <div className="tab-container">{!isLoading ? renderContent() : null}</div>
    </div>
  );
};

export default TabBar;
