import React, { FC, useCallback, useEffect, useLayoutEffect, useRef, useState, useMemo } from "react";
import { Icon } from "src/shared/components";
import classnames from "classnames";
import "./index.scss";

export interface CollapseProps {
  title: string;
  isOpen?: boolean;
}

const Collapse: FC<CollapseProps> = (props) => {
  const collapseContentEl = useRef<HTMLDivElement | null>(null);
  const [childHeight, setChildHeight] = useState(0);
  const [isOpen, setIsOpen] = useState(props.isOpen || false);

  useEffect(() => {
    setIsOpen(props.isOpen || false);
  }, [props.isOpen]);

  const toggle = useCallback(() => {
    setIsOpen((isOpenState) => !isOpenState);
  }, []);

  useLayoutEffect(() => {
    const childHeight = collapseContentEl.current?.offsetHeight || 0;

    setChildHeight(childHeight);
  }, [props.children]);

  const style = useMemo(() => ({ maxHeight: isOpen ? childHeight : 0 }), [isOpen, childHeight]);

  return (
    <div className="collapse-wrapper">
      <div className="collapse-header" onClick={toggle}>
        <div className="collapse-header-title">{props.title}</div>
        <Icon
          className={classnames("collapse-toggle", {
            "collapse-toggle_rotate": isOpen,
          })}
          type="chevron-top-blue"
        />
      </div>
      <div className="collapse-content-wrapper" style={style}>
        <div ref={collapseContentEl} className="collapse-content">
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default Collapse;
