import {
  MemberListItem,
  Member,
  File,
  Note,
  MemberWithTrainingInfoListItem,
  MemberWithCertificateInfoListItem,
  MemberJsaListItem,
  MemberPassedJsa,
} from "src/shared/models";
import { NameOfRoutes } from "src/shared/constants";
import {
  PaginatedResponse,
  ActionWithPayload,
  Filter,
  CodeWithId,
  CreateFileShape,
  CreateFileSuccess,
  DeleteFileSuccess,
  BaseResponse,
} from "src/shared/interfaces";
import {
  MemberFormShapeToRequest,
  MemberFormUpdateShape,
  CreateMemberSuccess,
  CreateMemberNoteShape,
  CreateMemberNoteSuccess,
  DeleteMemberNoteShape,
  DeleteMemberNoteSuccess,
  DeleteMemberFileShape,
  UpdateMemberStatusShape,
  SetMemberSignatureRequestShape,
  SetMemberSignatureResponseShape,
  TrainingAndCertificateMemberFilter,
} from "src/containers/Member/interface";
import { startLoading, stopLoading, navigate, hideModal } from "src/shared/store/actions";
import { call, put, takeLatest } from "redux-saga/effects";

import api from "../api";
import {
  getMembers,
  createMember,
  updateMember,
  getMember,
  getMemberFiles,
  getMemberNotes,
  addMemberFile,
  addMemberNote,
  deleteMemberNote,
  deleteMemberFile,
  updateMemberStatus,
  setMemberSignature,
  getMembersTrainings,
  getMembersCertificates,
  getMembersJsa,
  getMemberPassedJsa,
  deleteMemberSignature,
} from "./actions";

function* getMembersSaga({ payload }: ActionWithPayload<Filter>) {
  try {
    yield put(startLoading());
    const { additional, ...restPayload } = payload;
    const response: PaginatedResponse<MemberListItem> = yield call(api.getMembers, restPayload);
    yield put(stopLoading());
    yield put(getMembers.success({ ...response, clear: !payload.page, additional }));
  } catch (error) {
    yield put(getMembers.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getMembersTrainingsSaga({ payload }: ActionWithPayload<TrainingAndCertificateMemberFilter>) {
  try {
    yield put(startLoading());
    const response: PaginatedResponse<MemberWithTrainingInfoListItem> = yield call(api.getMembersTrainings, payload);
    yield put(stopLoading());
    yield put(getMembersTrainings.success({ ...response, clear: !payload.page }));
  } catch (error) {
    yield put(getMembersTrainings.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getMembersCertificatesSaga({ payload }: ActionWithPayload<TrainingAndCertificateMemberFilter>) {
  try {
    yield put(startLoading());
    const response: PaginatedResponse<MemberWithCertificateInfoListItem> = yield call(
      api.getMembersCertificates,
      payload,
    );
    yield put(stopLoading());
    yield put(getMembersCertificates.success({ ...response, clear: !payload.page }));
  } catch (error) {
    yield put(getMembersCertificates.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getMembersJsaSaga({ payload }: ActionWithPayload<TrainingAndCertificateMemberFilter>) {
  try {
    yield put(startLoading());
    const response: PaginatedResponse<MemberJsaListItem> = yield call(api.getMembersJsa, payload);
    yield put(stopLoading());
    yield put(getMembersJsa.success({ ...response, clear: !payload.page }));
  } catch (error) {
    yield put(getMembersJsa.failure(error as Error));
    yield put(stopLoading());
  }
}

function* createMemberSaga({ payload }: ActionWithPayload<CodeWithId<MemberFormShapeToRequest>>) {
  try {
    yield put(startLoading());
    const response: CreateMemberSuccess = yield call(api.createMember, payload);
    yield put(stopLoading());
    yield put(createMember.success(response));
    yield put(navigate(`${NameOfRoutes.COMPANIES}/${payload.code}${NameOfRoutes.MEMBERS}`));
  } catch (error) {
    yield put(createMember.failure(error as Error));
    yield put(stopLoading());
  }
}

function* updateMemberSaga({ payload }: ActionWithPayload<Required<CodeWithId<MemberFormUpdateShape>>>) {
  const { needRedirect } = payload.payload;
  try {
    yield put(startLoading());
    const response: CreateMemberSuccess = yield call(api.updateMember, payload);
    yield put(stopLoading());
    yield put(updateMember.success(response));
    if (needRedirect) {
      yield put(navigate(`${NameOfRoutes.COMPANIES}/${payload.code}${NameOfRoutes.MEMBERS}`));
    }
  } catch (error) {
    yield put(updateMember.failure(error as Error));
    yield put(stopLoading());
  }
}

function* updateMemberStatusSaga({ payload }: ActionWithPayload<Required<CodeWithId<UpdateMemberStatusShape>>>) {
  try {
    yield put(startLoading());
    const response: CreateMemberSuccess = yield call(api.updateMemberStatus, payload);
    yield put(stopLoading());
    yield put(updateMember.success(response));
    yield put(navigate(`${NameOfRoutes.COMPANIES}/${payload.code}${NameOfRoutes.MEMBERS}`));
  } catch (error) {
    yield put(updateMember.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getMemberSaga({ payload }: ActionWithPayload<Required<CodeWithId>>) {
  try {
    yield put(startLoading());
    const response: Member = yield call(api.getMember, payload);
    yield put(stopLoading());
    yield put(getMember.success(response));
  } catch (error) {
    yield put(getMember.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getMemberFilesSaga({ payload }: ActionWithPayload<Required<CodeWithId>>) {
  try {
    yield put(startLoading());
    const response: File[] = yield call(api.getMemberFiles, payload);
    yield put(stopLoading());
    yield put(getMemberFiles.success(response));
  } catch (error) {
    yield put(getMemberFiles.failure(error as Error));
    yield put(stopLoading());
  }
}

function* addMemberFileSaga({ payload }: ActionWithPayload<Required<CodeWithId & CreateFileShape>>) {
  try {
    yield put(startLoading());
    const response: CreateFileSuccess = yield call(api.addMemberFile, payload);
    yield put(stopLoading());
    yield put(addMemberFile.success(response));
  } catch (error) {
    yield put(addMemberFile.failure(error as Error));
    yield put(stopLoading());
  }
}

function* addMemberNoteSaga({ payload }: ActionWithPayload<Required<CodeWithId & CreateMemberNoteShape>>) {
  try {
    yield put(startLoading());
    const response: CreateMemberNoteSuccess = yield call(api.addMemberNote, payload);
    yield put(stopLoading());
    yield put(addMemberNote.success(response));
  } catch (error) {
    yield put(addMemberNote.failure(error as Error));
    yield put(stopLoading());
  }
}

function* deleteMemberNoteSaga({ payload }: ActionWithPayload<DeleteMemberNoteShape>) {
  try {
    yield put(startLoading());
    const response: DeleteMemberNoteSuccess = yield call(api.deleteMemberNote, payload);
    yield put(stopLoading());
    yield put(deleteMemberNote.success(response));
  } catch (error) {
    yield put(deleteMemberNote.failure(error as Error));
    yield put(stopLoading());
  }
}

function* deleteMemberFileSaga({ payload }: ActionWithPayload<DeleteMemberFileShape>) {
  try {
    yield put(startLoading());
    const response: DeleteFileSuccess = yield call(api.deleteMemberFile, payload);
    yield put(stopLoading());
    yield put(deleteMemberFile.success(response));
  } catch (error) {
    yield put(deleteMemberFile.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getMemberNotesSaga({ payload }: ActionWithPayload<Required<CodeWithId>>) {
  try {
    yield put(startLoading());
    const response: Note[] = yield call(api.getMemberNotes, payload);
    yield put(stopLoading());
    yield put(getMemberNotes.success(response));
  } catch (error) {
    yield put(getMemberNotes.failure(error as Error));
    yield put(stopLoading());
  }
}

function* setMemberSignatureSaga({ payload }: ActionWithPayload<Required<CodeWithId<SetMemberSignatureRequestShape>>>) {
  try {
    yield put(startLoading());
    const response: SetMemberSignatureResponseShape = yield call(api.setMemberSignature, payload);
    yield put(stopLoading());
    yield put(hideModal());
    yield put(setMemberSignature.success(response));
  } catch (error) {
    yield put(setMemberSignature.failure(error as Error));
    yield put(stopLoading());
  }
}

function* deleteMemberSignatureSaga({ payload }: ActionWithPayload<Required<CodeWithId>>) {
  try {
    yield put(startLoading());
    const response: BaseResponse = yield call(api.deleteMemberSignature, payload);
    yield put(stopLoading());
    yield put(hideModal());
    yield put(deleteMemberSignature.success(response));
  } catch (error) {
    yield put(deleteMemberSignature.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getMemberPassedJsaSaga({ payload }: ActionWithPayload<Required<CodeWithId>>) {
  try {
    yield put(startLoading());
    const response: MemberPassedJsa = yield call(api.getMemberPassedJsa, payload);
    yield put(stopLoading());
    yield put(getMemberPassedJsa.success(response));
  } catch (error) {
    yield put(getMemberPassedJsa.failure(error as Error));
    yield put(stopLoading());
  }
}

function* memberSagas() {
  yield takeLatest(getMembers.request, getMembersSaga);
  yield takeLatest(getMembersTrainings.request, getMembersTrainingsSaga);
  yield takeLatest(getMembersCertificates.request, getMembersCertificatesSaga);
  yield takeLatest(getMembersJsa.request, getMembersJsaSaga);
  yield takeLatest(createMember.request, createMemberSaga);
  yield takeLatest(updateMember.request, updateMemberSaga);
  yield takeLatest(updateMemberStatus.request, updateMemberStatusSaga);
  yield takeLatest(getMember.request, getMemberSaga);
  yield takeLatest(getMemberNotes.request, getMemberNotesSaga);
  yield takeLatest(getMemberFiles.request, getMemberFilesSaga);
  yield takeLatest(addMemberNote.request, addMemberNoteSaga);
  yield takeLatest(addMemberFile.request, addMemberFileSaga);
  yield takeLatest(deleteMemberFile.request, deleteMemberFileSaga);
  yield takeLatest(deleteMemberNote.request, deleteMemberNoteSaga);
  yield takeLatest(setMemberSignature.request, setMemberSignatureSaga);
  yield takeLatest(deleteMemberSignature.request, deleteMemberSignatureSaga);
  yield takeLatest(getMemberPassedJsa.request, getMemberPassedJsaSaga);
}

export default memberSagas;
