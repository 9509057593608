import { createAction, createAsyncAction } from "typesafe-actions";
import { DocumentActionTypes } from "src/containers/Documents/store/constants";
import { CodeWithId, PaginatedResponse } from "src/shared/interfaces";
import { Document } from "src/shared/models";

import {
  DocumentDeleteResponse,
  DocumentFilter,
  DocumentFormShapeToCreateRequest,
  DocumentFormShapeToUpdateRequest,
  DocumentUpdateResponse,
} from "../interface";

export const setFilter = createAction(DocumentActionTypes.SET_FILTER)<DocumentFilter | null>();

export const setPartialFilter = createAction(DocumentActionTypes.SET_PARTIAL_FILTER)<Partial<DocumentFilter>>();

export const getDocuments = createAsyncAction(
  DocumentActionTypes.GET_DOCUMENTS,
  DocumentActionTypes.GET_DOCUMENTS_SUCCESS,
  DocumentActionTypes.GET_DOCUMENTS_FAILURE,
)<DocumentFilter, PaginatedResponse<Document>, Error>();

export const getDocument = createAsyncAction(
  DocumentActionTypes.GET_DOCUMENT,
  DocumentActionTypes.GET_DOCUMENT_SUCCESS,
  DocumentActionTypes.GET_DOCUMENT_FAILURE,
)<Required<CodeWithId>, Document | null, Error>();

export const createDocument = createAsyncAction(
  DocumentActionTypes.CREATE_DOCUMENT,
  DocumentActionTypes.CREATE_DOCUMENT_SUCCESS,
  DocumentActionTypes.CREATE_DOCUMENT_FAILURE,
)<DocumentFormShapeToCreateRequest, DocumentUpdateResponse, Error>();

export const updateDocument = createAsyncAction(
  DocumentActionTypes.UPDATE_DOCUMENT,
  DocumentActionTypes.UPDATE_DOCUMENT_SUCCESS,
  DocumentActionTypes.UPDATE_DOCUMENT_FAILURE,
)<DocumentFormShapeToUpdateRequest, DocumentUpdateResponse, Error>();

export const deleteDocument = createAsyncAction(
  DocumentActionTypes.DELETE_DOCUMENT,
  DocumentActionTypes.DELETE_DOCUMENT_SUCCESS,
  DocumentActionTypes.DELETE_DOCUMENT_FAILURE,
)<Required<CodeWithId>, DocumentDeleteResponse, Error>();
