import React from "react";
import classnames from "classnames";
import { ORDER_TYPE, SortTable } from "src/shared/interfaces";

import { SortableHeader } from "../SortableHeader";
import { TableProperty } from "../interface";

import "./index.scss";

export interface HeadProps<T> extends SortTable {
  properties: TableProperty<T>[];
  total?: number;
}

function Head<T>(props: HeadProps<T>) {
  const { properties, sortBy, onChangeSorting, sortOrder } = props;

  return (
    <div className="table-header">
      {properties.map((prop) =>
        !prop.appearClickBlock ? (
          <div key={prop.title} className={classnames("table-header-cell", prop.headerClassName)}>
            {prop.sort ? (
              <SortableHeader
                column={prop}
                sortOrder={
                  sortBy && sortBy.find((sf) => prop.sort?.includes(sf)) && sortOrder ? sortOrder : ORDER_TYPE.NONE
                }
                onChangeSorting={onChangeSorting}
              />
            ) : (
              <div>{prop.title}</div>
            )}
          </div>
        ) : null,
      )}
    </div>
  );
}

export default Head;
