import { request } from "src/shared/utils";
import { API, METHODS } from "src/shared/constants";
import { PayloadWithId, Filter } from "src/shared/interfaces";
import { ActivityFormShapeToRequest, UpdateStatusShape, ActivityImportShape } from "src/containers/Activity/interface";

export default {
  getActivities: (payload: Filter) => request(METHODS.GET, API.ACTIVITIES.GET_ACTIVITIES)({ params: payload }),
  createActivity: (payload: ActivityFormShapeToRequest) =>
    request(METHODS.POST, API.ACTIVITIES.CREATE_ACTIVITY)(payload),
  updateActivity: (payload: PayloadWithId<ActivityFormShapeToRequest>) =>
    request(METHODS.PUT, API.ACTIVITIES.UPDATE_ACTIVITY(payload.id))(payload.payload),
  updateActivityStatus: (payload: PayloadWithId<UpdateStatusShape>) =>
    request(METHODS.PUT, API.ACTIVITIES.UPDATE_ACTIVITY_STATUS(payload.id))(payload.payload),
  getActivity: (id: number, company_code?: string) =>
    request(METHODS.GET, API.ACTIVITIES.GET_ACTIVITY(id))({ params: { company_code } }),
  importActivity: (payload: ActivityImportShape) => request(METHODS.POST, API.ACTIVITIES.IMPORT_ACTIVITY)(payload),
};
