import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLoading } from "src/shared/store/selectors";
import { useNavigate } from "react-router-dom";
import { NameOfRoutes } from "src/shared/constants";
import { useLocation } from "react-router";

import { RestoreForm, AuthModal } from "../../components";
import { RestoreShape } from "../../interface";
import { actions } from "../../store";

const getEmailFromQuery = (string: string, field: string) => {
  return string.replace(`?${field}=`, "");
};

const RestoreContainer: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const loading = useSelector(getLoading());

  const email = useMemo(() => {
    return getEmailFromQuery(location.search, "email");
  }, [location.search]);

  const handleSubmit = useCallback(
    (values: RestoreShape) => {
      dispatch(actions.forgotPassword.request(values));
    },
    [dispatch],
  );

  return (
    <AuthModal title="Restore Password" backClick={() => navigate(NameOfRoutes.AUTH_LOGIN)}>
      <RestoreForm email={email} loading={loading} submitHandler={handleSubmit} />
    </AuthModal>
  );
};

export default RestoreContainer;
