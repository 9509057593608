import React, { FC } from "react";
import classnames from "classnames";
import { BaseEntity } from "src/shared/interfaces";

import "./index.scss";

export interface LabelArrayProps {
  items: (BaseEntity | null)[];
  onClick?: (jsa: BaseEntity | null) => void;
  title?: string;
  className?: string;
}

const LabelArray: FC<LabelArrayProps> = (props) => {
  const { items, title, className, onClick } = props;

  return (
    <div className={classnames("label-array-wrapper", className)}>
      {title && <div className="label-array-title">{title}</div>}
      {items.map((item) => {
        return (
          <span onClick={onClick ? onClick.bind(null, item) : undefined} key={item?.id} className="label-array-item">
            {item?.name}
          </span>
        );
      })}
    </div>
  );
};

export default LabelArray;
