import { API, METHODS, SCHEMAS } from "src/shared/constants";
import { API_ENUM } from "src/shared/constants/api";
import { SchemasMap } from "src/shared/interfaces";
import { mapToSchema, generateDynamicSchemaKeyString } from "src/shared/utils";
import {
  filterSchema,
  getUserNotificationsResponseSchema,
  getUserNotificationsUnreadCountResponseSchema,
  readUserNotificationResponseSchema,
  readUserNotificationsResponseSchema,
} from "src/shared/validators";

const schemaShapes: SchemasMap<keyof typeof API.USER_NOTIFICATION> = {
  [API_ENUM.GET_USER_NOTIFICATIONS]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.USER_NOTIFICATION.GET_USER_NOTIFICATIONS("$id"))}${SCHEMAS.REQUEST}${
        METHODS.GET
      }`,
      schema: filterSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.USER_NOTIFICATION.GET_USER_NOTIFICATIONS("$id"))}${SCHEMAS.RESPONSE}${
        METHODS.GET
      }`,
      schema: getUserNotificationsResponseSchema,
    },
  },
  [API_ENUM.GET_USER_NOTIFICATIONS_UNREAD_COUNT]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.USER_NOTIFICATION.GET_USER_NOTIFICATIONS_UNREAD_COUNT("$id"))}${
        SCHEMAS.REQUEST
      }${METHODS.GET}`,
      schema: {},
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.USER_NOTIFICATION.GET_USER_NOTIFICATIONS_UNREAD_COUNT("$id"))}${
        SCHEMAS.RESPONSE
      }${METHODS.PUT}`,
      schema: getUserNotificationsUnreadCountResponseSchema,
    },
  },
  [API_ENUM.READ_USER_NOTIFICATIONS]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.USER_NOTIFICATION.READ_USER_NOTIFICATIONS("$id"))}${SCHEMAS.REQUEST}${
        METHODS.PUT
      }`,
      schema: {},
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.USER_NOTIFICATION.READ_USER_NOTIFICATIONS("$id"))}${
        SCHEMAS.RESPONSE
      }${METHODS.PUT}`,
      schema: readUserNotificationsResponseSchema,
    },
  },
  [API_ENUM.READ_USER_NOTIFICATION]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.USER_NOTIFICATION.READ_USER_NOTIFICATION("$id", "$id"))}${
        SCHEMAS.REQUEST
      }${METHODS.PUT}`,
      schema: {},
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.USER_NOTIFICATION.READ_USER_NOTIFICATION("$id", "$id"))}${
        SCHEMAS.RESPONSE
      }${METHODS.PUT}`,
      schema: readUserNotificationResponseSchema,
    },
  },
};

export default mapToSchema(schemaShapes);
