import { PaginatedResponse, ActionWithPayload, CodeWithId } from "src/shared/interfaces";
import { call, put, takeLatest } from "redux-saga/effects";
import { actions } from "src/shared/store";
import { Project } from "src/shared/models";

import api from "../api";
import { createProject, deleteProject, getProject, getProjects, updateProject } from "./actions";
import {
  CreateUpdateProjectSuccess,
  ProjectDeleteResponse,
  ProjectFilter,
  ProjectFormShapeToRequest,
} from "../interface";

function* getProjectsSaga({ payload }: ActionWithPayload<Partial<ProjectFilter>>) {
  try {
    const response: PaginatedResponse<Project> = yield call(api.getProjects, payload);
    yield put(getProjects.success({ ...response, clear: !payload.page }));
  } catch (error) {
    yield put(getProjects.failure(error as Error));
  }
}

function* getProjectSaga({ payload }: ActionWithPayload<Required<CodeWithId>>) {
  try {
    yield put(actions.startLoading());
    const response: Project = yield call(api.getProject, payload);
    yield put(actions.stopLoading());
    yield put(getProject.success(response));
  } catch (error) {
    yield put(getProject.failure(error as Error));
    yield put(actions.stopLoading());
  }
}

function* createProjectSaga({ payload }: ActionWithPayload<CodeWithId<ProjectFormShapeToRequest>>) {
  try {
    yield put(actions.startLoading());
    const response: CreateUpdateProjectSuccess = yield call(api.createProject, payload);
    yield put(actions.stopLoading());
    yield put(createProject.success(response));
    yield put(actions.hideOverlay());
  } catch (error) {
    yield put(createProject.failure(error as Error));
    yield put(actions.stopLoading());
  }
}

function* updateProjectSaga({ payload }: ActionWithPayload<Required<CodeWithId<ProjectFormShapeToRequest>>>) {
  try {
    yield put(actions.startLoading());
    const response: CreateUpdateProjectSuccess = yield call(api.updateProject, payload);
    yield put(actions.stopLoading());
    yield put(updateProject.success(response));
    yield put(actions.hideOverlay());
  } catch (error) {
    yield put(updateProject.failure(error as Error));
    yield put(actions.stopLoading());
  }
}

function* deleteProjectSaga({ payload }: ActionWithPayload<Required<CodeWithId>>) {
  try {
    yield put(actions.startLoading());
    const response: ProjectDeleteResponse = yield call(api.deleteProject, payload);
    yield put(actions.stopLoading());
    yield put(deleteProject.success(response));
    yield put(actions.hideOverlay());
  } catch (error) {
    yield put(deleteProject.failure(error as Error));
    yield put(actions.stopLoading());
  }
}

function* projectSagas() {
  yield takeLatest(getProjects.request, getProjectsSaga);
  yield takeLatest(getProject.request, getProjectSaga);
  yield takeLatest(createProject.request, createProjectSaga);
  yield takeLatest(updateProject.request, updateProjectSaga);
  yield takeLatest(deleteProject.request, deleteProjectSaga);
}

export default projectSagas;
