import { request } from "src/shared/utils";
import { API, METHODS } from "src/shared/constants";
import { Filter } from "src/shared/interfaces";
import { TagFormShapeToRequest } from "src/containers/Tag/interface";

export default {
  getTags: (tagsFilter: Filter) => {
    return request(METHODS.GET, API.TAG.GET_TAGS)({ params: tagsFilter });
  },
  getTag: (payload: Required<{ tag_id: string | number }>) => {
    return request(METHODS.GET, API.TAG.GET_TAG(payload.tag_id))();
  },
  createTag: (payload: TagFormShapeToRequest) => request(METHODS.POST, API.TAG.CREATE_TAG)(payload),
};
