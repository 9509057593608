import { LoginShape } from "src/containers/Auth/interface";
import { FieldItem } from "src/shared/components/FormGenerator";
import { FORM_ERROR_MESSAGES } from "src/shared/constants";
import { FieldItemType } from "src/shared/interfaces";
import * as Yup from "yup";

export const validationSchema: Yup.SchemaOf<LoginShape> = Yup.object().shape({
  email: Yup.string().email(FORM_ERROR_MESSAGES.EMAIL).required(FORM_ERROR_MESSAGES.REQUIRED),
  password: Yup.string().required(FORM_ERROR_MESSAGES.REQUIRED),
});

export const fields: FieldItem[] = [
  {
    type: FieldItemType.TEXT,
    name: "email",
    label: "Email",
    wrapperClass: "input-line",
  },
  {
    type: FieldItemType.PASSWORD,
    name: "password",
    label: "Password",
    wrapperClass: "input-line",
    isShowPasswordIcon: true,
  },
];

export const initValues: LoginShape = {
  email: "",
  password: "",
};
