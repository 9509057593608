import { API, METHODS, SCHEMAS } from "src/shared/constants";
import { API_ENUM } from "src/shared/constants/api";
import { SchemasMap } from "src/shared/interfaces";
import { generateDynamicSchemaKeyString, mapToSchema } from "src/shared/utils";
import {
  getTasksResponseSchema,
  filterSchema,
  createTaskRequestSchema,
  createTaskResponseSchema,
  getTaskResponseSchema,
  updateTaskStatusRequestSchema,
  getFilesResponseSchema,
  createFileResponseSchema,
  createFileRequestSchema,
  deleteFileResponseSchema,
  importTaskRequestSchema,
  importTaskResponseSchema,
} from "src/shared/validators";

const schemaShapes: SchemasMap<keyof typeof API.TASK> = {
  [API_ENUM.GET_TASKS]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.TASK.GET_TASKS)}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: filterSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.TASK.GET_TASKS)}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: getTasksResponseSchema,
    },
  },
  [API_ENUM.GET_TASK]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.TASK.GET_TASK("$id"))}${SCHEMAS.REQUEST}${METHODS.POST}`,
      schema: {},
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.TASK.GET_TASK("$id"))}${SCHEMAS.RESPONSE}${METHODS.POST}`,
      schema: getTaskResponseSchema,
    },
  },
  [API_ENUM.CREATE_TASK]: {
    request: {
      name: `${API.TASK.CREATE_TASK}${SCHEMAS.REQUEST}${METHODS.POST}`,
      schema: createTaskRequestSchema,
    },
    response: {
      name: `${API.TASK.CREATE_TASK}${SCHEMAS.RESPONSE}${METHODS.POST}`,
      schema: createTaskResponseSchema,
    },
  },
  [API_ENUM.UPDATE_TASK]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.TASK.UPDATE_TASK("$id"))}${SCHEMAS.REQUEST}${METHODS.PUT}`,
      schema: createTaskRequestSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.TASK.UPDATE_TASK("$id"))}${SCHEMAS.RESPONSE}${METHODS.PUT}`,
      schema: createTaskResponseSchema,
    },
  },
  [API_ENUM.UPDATE_TASK_STATUS]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.TASK.UPDATE_TASK_STATUS("$id"))}${SCHEMAS.REQUEST}${METHODS.PUT}`,
      schema: updateTaskStatusRequestSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.TASK.UPDATE_TASK_STATUS("$id"))}${SCHEMAS.RESPONSE}${METHODS.PUT}`,
      schema: createTaskResponseSchema,
    },
  },
  [API_ENUM.GET_TASK_FILES]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.TASK.GET_TASK_FILES("$id"))}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: {},
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.TASK.GET_TASK_FILES("$id"))}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: getFilesResponseSchema,
    },
  },
  [API_ENUM.ADD_TASK_FILE]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.TASK.ADD_TASK_FILE("$id"))}${SCHEMAS.REQUEST}${METHODS.POST}`,
      schema: createFileRequestSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.TASK.ADD_TASK_FILE("$id"))}${SCHEMAS.RESPONSE}${METHODS.POST}`,
      schema: createFileResponseSchema,
    },
  },
  [API_ENUM.DELETE_TASK_FILE]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.TASK.DELETE_TASK_FILE("$id", "$id"))}${SCHEMAS.REQUEST}${
        METHODS.DELETE
      }`,
      schema: {},
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.TASK.DELETE_TASK_FILE("$id", "$id"))}${SCHEMAS.RESPONSE}${
        METHODS.DELETE
      }`,
      schema: deleteFileResponseSchema,
    },
  },
  [API_ENUM.IMPORT_TASK]: {
    request: {
      name: `${API.TASK.IMPORT_TASK}${SCHEMAS.REQUEST}${METHODS.POST}`,
      schema: importTaskRequestSchema,
    },
    response: {
      name: `${API.TASK.IMPORT_TASK}${SCHEMAS.RESPONSE}${METHODS.POST}`,
      schema: importTaskResponseSchema,
    },
  },
};

export default mapToSchema(schemaShapes);
