import { createAction, createAsyncAction } from "typesafe-actions";
import { JsaListItem, Jsa, File } from "src/shared/models";
import {
  PaginatedResponse,
  Filter,
  PayloadWithId,
  DeleteFileSuccess,
  CreateFileSuccess,
  CreateFileShape,
  IdWithOptionalCode,
  BaseResponse,
} from "src/shared/interfaces";
import { JsaActionTypes } from "src/containers/Jsa/store/constants";
import {
  CreateJsaSuccess,
  DeleteJsaFileShape,
  JsaFormShapeToRequest,
  UpdateJsaStatusShape,
  JsaImportShape,
} from "src/containers/Jsa/interface";

export const getJsa = createAsyncAction(
  JsaActionTypes.GET_JSA,
  JsaActionTypes.GET_JSA_SUCCESS,
  JsaActionTypes.GET_JSA_FAILURE,
)<Filter, PaginatedResponse<JsaListItem>, Error>();

export const getJsaItem = createAsyncAction(
  JsaActionTypes.GET_JSA_ITEM,
  JsaActionTypes.GET_JSA_ITEM_SUCCESS,
  JsaActionTypes.GET_JSA_ITEM_FAILURE,
)<{ id: number | string }, Jsa | null, Error>();

export const clearJsa = createAction(JsaActionTypes.CLEAR_JSA)();

export const createJsa = createAsyncAction(
  JsaActionTypes.CREATE_JSA,
  JsaActionTypes.CREATE_JSA_SUCCESS,
  JsaActionTypes.CREATE_JSA_FAILURE,
)<JsaFormShapeToRequest, CreateJsaSuccess, Error>();

export const updateJsa = createAsyncAction(
  JsaActionTypes.UPDATE_JSA,
  JsaActionTypes.UPDATE_JSA_SUCCESS,
  JsaActionTypes.UPDATE_JSA_FAILURE,
)<Required<PayloadWithId<JsaFormShapeToRequest>>, CreateJsaSuccess, Error>();

export const updateJsaStatus = createAsyncAction(
  JsaActionTypes.UPDATE_JSA_STATUS,
  JsaActionTypes.UPDATE_JSA_STATUS_SUCCESS,
  JsaActionTypes.UPDATE_JSA_STATUS_FAILURE,
)<Required<IdWithOptionalCode<UpdateJsaStatusShape>>, CreateJsaSuccess, Error>();

export const getJsaFiles = createAsyncAction(
  JsaActionTypes.GET_JSA_FILES,
  JsaActionTypes.GET_JSA_FILES_SUCCESS,
  JsaActionTypes.GET_JSA_FILES_FAILURE,
)<{ id: string | number }, File[], Error>();

export const addJsaFile = createAsyncAction(
  JsaActionTypes.ADD_JSA_FILE,
  JsaActionTypes.ADD_JSA_FILE_SUCCESS,
  JsaActionTypes.ADD_JSA_FILE_FAILURE,
)<PayloadWithId<CreateFileShape>, CreateFileSuccess, Error>();

export const deleteJsaFile = createAsyncAction(
  JsaActionTypes.DELETE_JSA_FILE,
  JsaActionTypes.DELETE_JSA_FILE_SUCCESS,
  JsaActionTypes.DELETE_JSA_FILE_FAILURE,
)<DeleteJsaFileShape, DeleteFileSuccess, Error>();

export const importJsa = createAsyncAction(
  JsaActionTypes.IMPORT_JSA,
  JsaActionTypes.IMPORT_JSA_SUCCESS,
  JsaActionTypes.IMPORT_JSA_FAILURE,
)<JsaImportShape, BaseResponse, Error>();

export const setFilter = createAction(JsaActionTypes.SET_FILTER)<Filter | null>();
export const setClearJsaForm = createAction(JsaActionTypes.SET_CLEAR_JSA_FORM)<boolean>();
