import { request } from "src/shared/utils";
import { API, METHODS } from "src/shared/constants";
import {
  DocumentFilter,
  DocumentFormShapeToCreateRequest,
  DocumentFormShapeToUpdateRequest,
} from "src/containers/Documents/interface";
import { CodeWithId } from "src/shared/interfaces";

export default {
  getDocuments: (payload: DocumentFilter) => {
    const { company_code, ...documentFilter } = payload;
    return request(METHODS.GET, API.DOCUMENT.GET_DOCUMENTS(company_code ?? ""))({ params: documentFilter });
  },
  getDocument: ({ code, id }: Required<CodeWithId>) => {
    return request(METHODS.GET, API.DOCUMENT.GET_DOCUMENT(code, id))();
  },
  createDocument: ({ code, payload }: DocumentFormShapeToCreateRequest) => {
    return request(METHODS.POST, API.DOCUMENT.CREATE_DOCUMENT(code))(payload);
  },
  updateDocument: ({ code, id, payload }: Required<DocumentFormShapeToUpdateRequest>) => {
    return request(METHODS.PUT, API.DOCUMENT.UPDATE_DOCUMENT(code, id))(payload);
  },
  deleteDocument: ({ code, id }: Required<CodeWithId>) => {
    return request(METHODS.DELETE, API.DOCUMENT.DELETE_DOCUMENT(code, id))();
  },
};
