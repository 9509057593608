import { request } from "src/shared/utils";
import { API, METHODS } from "src/shared/constants";
import { CodeWithId, Filter } from "src/shared/interfaces";

import { TeamFormShapeToRequest } from "../interface";

export default {
  getTeams: (payload: Partial<Filter>) => {
    const { company_code, ...teamFilter } = payload;
    return request(METHODS.GET, API.TEAM.GET_TEAMS(company_code ?? ""))({ params: teamFilter });
  },
  getTeam: ({ code, id }: Required<CodeWithId>) => request(METHODS.GET, API.TEAM.GET_TEAM(code, id))(),
  createTeam: (payload: CodeWithId<TeamFormShapeToRequest>) => {
    return request(METHODS.POST, API.TEAM.CREATE_TEAM(payload.code))(payload.payload);
  },
  updateTeam: ({ code, id, payload }: Required<CodeWithId<TeamFormShapeToRequest>>) =>
    request(METHODS.PUT, API.TEAM.UPDATE_TEAM(code, id))(payload),
  deleteTeam: ({ code, id }: Required<CodeWithId>) => request(METHODS.DELETE, API.TEAM.DELETE_TEAM(code, id))(),
};
