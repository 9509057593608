import { createSelector } from "reselect";

import { AppState } from "../interfaces";

const selectShared = (state: AppState) =>  state.shared;

export const getLoading = () => createSelector(selectShared, (state) => state.loading);

export const getNotification = () => createSelector(selectShared, (state) => state.notification);

export const getUserDetails = () => createSelector(selectShared, (state) => state.user);

export const getRoles = () => createSelector(selectShared, (state) => state.roles);

export const getModal = () => createSelector(selectShared, (state) => state.modal);

export const getOverlay = () => createSelector(selectShared, (state) => state.overlay);

export const getNavigateURL = () => createSelector(selectShared, (state) => state.navigateURL);

export const getJobs = () => createSelector(selectShared, (state) => state.jobs);

export const getAddress = () => createSelector(selectShared, (state) => state.addresses);

export const getLocation = () => createSelector(selectShared, (state) => state.location);

export const getIndustries = () => createSelector(selectShared, (state) => state.industries);

export const getIndustriesTotal = () => createSelector(selectShared, (state) => state.industriesTotal);

export const getJsa = () => createSelector(selectShared, (state) => state.jsa);

export const getTeams = () => createSelector(selectShared, (state) => state.teams);

export const getTeamsTotal = () => createSelector(selectShared, (state) => state.teamsTotal);

export const getLastCreatedTeam = () => createSelector(selectShared, (state) => state.lastCreatedTeam);

export const getClients = () => createSelector(selectShared, (state) => state.clients);

export const getLoadingSections = () => createSelector(selectShared, (state) => Object.keys(state.loadingSections));

export const getAuthorizedMemberByCompany = (companyCode?: string | null) =>
  createSelector(selectShared, (state) => {
    const user = state.user;
    if (!user) return null;

    const members = user?.members || [];
    return members.find((m) => m.company && m.company.code === companyCode);
  });

export const getLoadingSection = (sectionName: string) =>
  createSelector(selectShared, (state) => state.loadingSections[sectionName]);

export const selectRouter = () =>
  createSelector(
    (state: AppState) => state,
    (state) => state,
  );
