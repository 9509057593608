import React from "react";
import { Note } from "src/shared/models";
import { getUSFormatedDateTime } from "src/shared/utils";
import { AccessControl, Icon } from "src/shared/components";

import "./index.scss";

export interface NoteItemProps {
  isDisabled: boolean;
  note: Note;
  onDelete: (note: Note) => void;
  permission?: string;
  companyCode?: string | null;
}

const NoteItem = (props: NoteItemProps) => {
  const { note, onDelete, permission, companyCode, isDisabled } = props;

  return (
    <div className="note-item">
      <div className="note-item-header">
        <div className="left-note-item-header">
          <div className="note-user">{`${note.creator?.first_name} ${note.creator?.last_name}`}</div>
          <div>|</div>
          <div className="note-date">{getUSFormatedDateTime(note.created_at)}</div>
        </div>
        <AccessControl permission={permission || ""} option={{ companyCode, onlyAppAdmin: !companyCode }}>
          {!isDisabled ? (
            <div className="right-note-item-header">
              <Icon type="close" onClick={onDelete.bind(null, note)} />
            </div>
          ) : null}
        </AccessControl>
      </div>
      <div className="note-item-content">{note.description}</div>
    </div>
  );
};

export default NoteItem;
