import React, { FC } from "react";
import { Icon } from "src/shared/components";

import "./indes.scss";

export interface BackButtonProps {
  onClick?: () => void;
  text?: string;
}

const BackButton: FC<BackButtonProps> = (props) => {
  const { onClick, text = "Back" } = props;

  return (
    <div className="back-button" onClick={onClick}>
      <Icon type="back-gray" />
      <div>{text}</div>
    </div>
  );
};

export default BackButton;
