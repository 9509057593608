import { createAction, createAsyncAction } from "typesafe-actions";
import {
  CodeWithId,
  CreateFileShape,
  CreateFileSuccess,
  DeleteFileSuccess,
  PaginatedResponse,
  BaseFilter,
} from "src/shared/interfaces";
import {
  Certificate,
  CertificateListItem,
  File,
  Training,
  TrainingListItem,
  TrainingPass,
  CertificateIssuedByItem,
} from "src/shared/models";

import { TrainingAndCertificateActionTypes } from "./constants";
import {
  CreateTrainingSuccess,
  TrainingAndCertificateFilter,
  CertificateTemplatesFilter,
  TrainingFormShapeToRequest,
  UpdateTrainingStatusShape,
  TrainingPassFilter,
  TrainingPassPayload,
  TrainingPassResponse,
  CreateUpdateCertificateSuccess,
  CertificateFormShapeToRequest,
  UpdateCertificateStatusShape,
  DeleteCertificateFileShape,
  GetCertificatesTemplatesResponse,
} from "../interface";

export const getTrainings = createAsyncAction(
  TrainingAndCertificateActionTypes.GET_TRAININGS,
  TrainingAndCertificateActionTypes.GET_TRAININGS_SUCCESS,
  TrainingAndCertificateActionTypes.GET_TRAININGS_FAILURE,
)<TrainingAndCertificateFilter, PaginatedResponse<TrainingListItem>, Error>();

export const setTrainingAndCertificateFilter = createAction(
  TrainingAndCertificateActionTypes.SET_TRAINING_AND_CERTIFICATE_FILTER,
)<TrainingAndCertificateFilter | null>();

export const clearTrainings = createAction(TrainingAndCertificateActionTypes.CLEAR_TRAININGS)();

export const getTraining = createAsyncAction(
  TrainingAndCertificateActionTypes.GET_TRAINING,
  TrainingAndCertificateActionTypes.GET_TRAINING_SUCCESS,
  TrainingAndCertificateActionTypes.GET_TRAINING_FAILURE,
)<Required<CodeWithId>, Training | null, Error>();

export const createTraining = createAsyncAction(
  TrainingAndCertificateActionTypes.CREATE_TRAINING,
  TrainingAndCertificateActionTypes.CREATE_TRAINING_SUCCESS,
  TrainingAndCertificateActionTypes.CREATE_TRAINING_FAILURE,
)<TrainingFormShapeToRequest, CreateTrainingSuccess, Error>();

export const updateTraining = createAsyncAction(
  TrainingAndCertificateActionTypes.UPDATE_TRAINING,
  TrainingAndCertificateActionTypes.UPDATE_TRAINING_SUCCESS,
  TrainingAndCertificateActionTypes.UPDATE_TRAINING_FAILURE,
)<Required<CodeWithId<TrainingFormShapeToRequest>>, CreateTrainingSuccess, Error>();

export const updateTrainingStatus = createAsyncAction(
  TrainingAndCertificateActionTypes.UPDATE_TRAINING_STATUS,
  TrainingAndCertificateActionTypes.UPDATE_TRAINING_STATUS_SUCCESS,
  TrainingAndCertificateActionTypes.UPDATE_TRAINING_STATUS_FAILURE,
)<Required<CodeWithId<UpdateTrainingStatusShape>>, CreateTrainingSuccess, Error>();

export const getCertificates = createAsyncAction(
  TrainingAndCertificateActionTypes.GET_CERTIFICATES,
  TrainingAndCertificateActionTypes.GET_CERTIFICATES_SUCCESS,
  TrainingAndCertificateActionTypes.GET_CERTIFICATES_FAILURE,
)<TrainingAndCertificateFilter, PaginatedResponse<CertificateListItem>, Error>();

export const getCertificatesTemplates = createAsyncAction(
  TrainingAndCertificateActionTypes.GET_CERTIFICATES_TEMPLATES,
  TrainingAndCertificateActionTypes.GET_CERTIFICATES_TEMPLATES_SUCCESS,
  TrainingAndCertificateActionTypes.GET_CERTIFICATES_TEMPLATES_FAILURE,
)<CertificateTemplatesFilter, GetCertificatesTemplatesResponse, Error>();

export const getCertificatesIssuedBy = createAsyncAction(
  TrainingAndCertificateActionTypes.GET_CERTIFICATES_ISSUED_BY,
  TrainingAndCertificateActionTypes.GET_CERTIFICATES_ISSUED_BY_SUCCESS,
  TrainingAndCertificateActionTypes.GET_CERTIFICATES_ISSUED_BY_FAILURE,
)<BaseFilter, PaginatedResponse<CertificateIssuedByItem>, Error>();

export const getCertificate = createAsyncAction(
  TrainingAndCertificateActionTypes.GET_CERTIFICATE,
  TrainingAndCertificateActionTypes.GET_CERTIFICATE_SUCCESS,
  TrainingAndCertificateActionTypes.GET_CERTIFICATE_FAILURE,
)<Required<CodeWithId>, Certificate | null, Error>();

export const createCertificate = createAsyncAction(
  TrainingAndCertificateActionTypes.CREATE_CERTIFICATE,
  TrainingAndCertificateActionTypes.CREATE_CERTIFICATE_SUCCESS,
  TrainingAndCertificateActionTypes.CREATE_CERTIFICATE_FAILURE,
)<CodeWithId<CertificateFormShapeToRequest>, CreateUpdateCertificateSuccess, Error>();

export const updateCertificate = createAsyncAction(
  TrainingAndCertificateActionTypes.UPDATE_CERTIFICATE,
  TrainingAndCertificateActionTypes.UPDATE_CERTIFICATE_SUCCESS,
  TrainingAndCertificateActionTypes.UPDATE_CERTIFICATE_FAILURE,
)<Required<CodeWithId<CertificateFormShapeToRequest>>, CreateUpdateCertificateSuccess, Error>();

export const updateCertificateStatus = createAsyncAction(
  TrainingAndCertificateActionTypes.UPDATE_CERTIFICATE_STATUS,
  TrainingAndCertificateActionTypes.UPDATE_CERTIFICATE_STATUS_SUCCESS,
  TrainingAndCertificateActionTypes.UPDATE_CERTIFICATE_STATUS_FAILURE,
)<Required<CodeWithId<UpdateCertificateStatusShape>>, CreateUpdateCertificateSuccess, Error>();

export const getCertificateFiles = createAsyncAction(
  TrainingAndCertificateActionTypes.GET_CERTIFICATE_FILES,
  TrainingAndCertificateActionTypes.GET_CERTIFICATE_FILES_SUCCESS,
  TrainingAndCertificateActionTypes.GET_CERTIFICATE_FILES_FAILURE,
)<CodeWithId, File[], Error>();

export const addCertificateFile = createAsyncAction(
  TrainingAndCertificateActionTypes.ADD_CERTIFICATE_FILE,
  TrainingAndCertificateActionTypes.ADD_CERTIFICATE_FILE_SUCCESS,
  TrainingAndCertificateActionTypes.ADD_CERTIFICATE_FILE_FAILURE,
)<CodeWithId<CreateFileShape>, CreateFileSuccess, Error>();

export const deleteCertificateFile = createAsyncAction(
  TrainingAndCertificateActionTypes.DELETE_CERTIFICATE_FILE,
  TrainingAndCertificateActionTypes.DELETE_CERTIFICATE_FILE_SUCCESS,
  TrainingAndCertificateActionTypes.DELETE_CERTIFICATE_FILE_FAILURE,
)<DeleteCertificateFileShape, DeleteFileSuccess, Error>();

export const clearCertificates = createAction(TrainingAndCertificateActionTypes.CLEAR_CERTIFICATES)();

export const getTrainingPass = createAsyncAction(
  TrainingAndCertificateActionTypes.GET_TRAINING_PASS,
  TrainingAndCertificateActionTypes.GET_TRAINING_PASS_SUCCESS,
  TrainingAndCertificateActionTypes.GET_TRAINING_PASS_FAILURE,
)<Required<CodeWithId<TrainingPassFilter>>, TrainingPass[], Error>();

export const passTraining = createAsyncAction(
  TrainingAndCertificateActionTypes.PASS_TRAINING,
  TrainingAndCertificateActionTypes.PASS_TRAINING_SUCCESS,
  TrainingAndCertificateActionTypes.PASS_TRAINING_FAILURE,
)<Required<CodeWithId<TrainingPassPayload>>, TrainingPassResponse, Error>();

export const confirmTraining = createAsyncAction(
  TrainingAndCertificateActionTypes.CONFIRM_TRAINING,
  TrainingAndCertificateActionTypes.CONFIRM_TRAINING_SUCCESS,
  TrainingAndCertificateActionTypes.CONFIRM_TRAINING_FAILURE,
)<Required<CodeWithId<TrainingPassPayload>>, TrainingPassResponse, Error>();

export const setClearTrainingForm = createAction(TrainingAndCertificateActionTypes.SET_CLEAR_TRAINING_FORM)<boolean>();
