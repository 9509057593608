import { shortMemberSchema } from "src/shared/validators";

export const teamSchema = {
  type: "object",
  additionalProperties: false,
  required: ["id", "name", "status", "created_at", "created_by"],
  properties: {
    id: { type: "number" },
    name: { type: "string" },
    status: { type: "string" },
    created_at: { type: "string" },
    created_by: { type: "string" },
    members_count: { type: ["number", "null"] },
    creator: { type: ["object", "null"] },
    members: {
      type: ["array", "null"],
      additionalProperties: false,
      items: shortMemberSchema,
    },
  },
};

export const createUpdateTeamRequestSchema = {
  type: "object",
  additionalProperties: false,
  required: ["name", "members"],
  properties: {
    name: { type: "string" },
    members: {
      type: "array",
      items: {
        type: "number",
      },
    },
  },
};

export const createUpdateTeamResponseSchema = {
  type: "object",
  additionalProperties: false,
  required: ["message", "team"],
  properties: {
    message: { type: "string" },
    team: teamSchema,
  },
};

export const teamDeleteResponseSchema = {
  type: "object",
  additionalProperties: true,
  required: ["message", "team_id"],
  properties: {
    message: { type: "string" },
    team_id: { type: "number" },
  },
};
