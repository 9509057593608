import { MemberListItem, Creator, User } from "src/shared/models";
import { AnyType, Option, ORDER_TYPE } from "src/shared/interfaces";
import moment, { unitOfTime } from "moment";
import { isEmptyArray } from "formik";

import { LAST_COMPANY_KEY, NameOfRoutes, TIMEZONE } from "../constants";
import { BaseEntity } from "../interfaces";

export const getUSFormattedDate = (date: Date | string | undefined | null, timeZone?: string) => {
  return date ? new Date(date).toLocaleString("en-US", { timeZone }).split(",")[0] : "-";
};

export const getUSFormattedDateLong = (date: Date | string | undefined | null, timeZone?: string) => {
  return date
    ? new Date(date).toLocaleString("en-US", { year: "numeric", month: "long", day: "numeric", timeZone })
    : "-";
};

export const getUSFormatedTime = (date: Date | string, timeZone?: string) => {
  const tzDate = new Date(date).toLocaleString("en-US", { timeZone });

  return date ? moment(tzDate, "MM/DD/YYYY, hh:mm:ss A").locale("en-US").format("LT") : "-";
};

export const getUSFormatedDateTime = (date: Date | string, timeZone?: string) => {
  return date ? `${getUSFormattedDate(date, timeZone)} ${getUSFormatedTime(date, timeZone)}` : "-";
};

export const getUSFormatedDateTimeForJsa = (date: Date | string, timeZone?: string) => {
  return date ? `${getUSFormattedDate(date, timeZone)}, ${getUSFormatedTime(date, timeZone)}` : "-";
};

export const getUSFormattedDateMonth = (date: Date | string) => {
  return moment(date).format("MMM D, YYYY");
};

export const getUSFormattedDateShort = (date: Date | string | undefined | null) => {
  const momentDate = moment(date);
  return momentDate.isValid() ? momentDate.format("MM/DD/YY") : "-";
};

export const setLastCompany = (code: string) => {
  localStorage.setItem(LAST_COMPANY_KEY, code);
};

export const getLastCompany = () => {
  return localStorage.getItem(LAST_COMPANY_KEY);
};

export const getCompanyUrl = (code: string) => {
  return `${NameOfRoutes.COMPANIES}/${code}${NameOfRoutes.USERS}`;
};

export const getCompanyCodeFromLocationPath = (path: string) => {
  if (!path.includes(NameOfRoutes.COMPANIES)) return null;
  const parts = path.split("/");

  return parts[2] || null;
};

export const getBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = function (error) {
      reject(error);
    };
  });

export const validateFile = (file: File | null, options: { maxSize?: number }): string => {
  if (file && options.maxSize && file.size > options.maxSize) {
    return `Max size of the file should be ${options.maxSize / 1000000} MB`;
  }

  return "";
};

export const openInNewTab = (url: string) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export function isEmptyObject<ObjectType>(obj?: ObjectType | null): boolean {
  if (!obj) {
    return true;
  }

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (obj[key] && !Array.isArray(obj[key])) {
        return false;
      }

      if (obj[key] && Array.isArray(obj[key]) && !isEmptyArray(obj[key])) {
        return false;
      }
    }
  }

  return true;
}

export function clearEmptyParagraphs(html: string): string {
  // eslint-disable-next-line
  const regex = /<(p|h\d)>[\t\r\n\s(&nbsp;)]*<\/(p|h\d)>/gim;
  return html.replace(regex, "");
}

export function replaceLinks(html: string): string {
  // eslint-disable-next-line
  const regex = /(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|](?=[^>]*<)(?![^>]*<\/a)/gim;

  const replaceFunc = (link: string) => {
    return `<a target="_blank" href="${link}">${link}</a>`;
  };

  return html.replace(regex, replaceFunc);
}

export function setTargetBlankAttrForChildLinks(containerElement: HTMLElement) {
  const linkElements = containerElement.getElementsByTagName("a");
  for (const key in linkElements) {
    if (Object.prototype.hasOwnProperty.call(linkElements, key)) {
      const linkElement = linkElements[key];
      linkElement.target = "_blank";
    }
  }
}

export const prepareBaseOptions = (item: BaseEntity) => {
  return { label: item.name, value: String(item.id) };
};

export const prepareBaseIntegerOptions = (item: BaseEntity) => {
  return { label: item.name, value: item.id };
};

export const getDuration = (start: Date, end: Date): string => {
  const startMoment = moment(start);
  startMoment.startOf("minute");

  const endMoment = moment(end);
  endMoment.startOf("minute");

  const diff = moment.duration(endMoment.diff(startMoment));
  if (diff.asMinutes() < 0) {
    return `00:00 h`;
  }

  const days = diff.get("days");
  const hours = String(diff.get("hours"));
  const minutes = String(diff.get("minutes"));

  let timeString = `${hours.length === 1 ? `0${hours}` : hours}:${minutes.length === 1 ? `0${minutes}` : minutes} h`;

  if (days > 0) {
    timeString = `${days}d ${timeString}`;
  }

  return timeString;
};

export const getDateByDuration = (firstTime: Date, duration: Date, type: "start" | "end") => {
  const durationMoment = moment(duration);
  const durationHours = durationMoment.get("hours");
  const durationMinutes = durationMoment.get("minutes");
  const firstTimeMoment = moment(firstTime);
  const method = type === "start" ? "subtract" : "add";
  firstTimeMoment[method](durationHours, "hours");
  firstTimeMoment[method](durationMinutes, "minutes");
  return firstTimeMoment;
};

export const getDateWithTimezone = (date: string) => {
  const offset = moment(date).isDST() ? "04" : "05";
  return moment(`${date}:00-${offset}`).toDate();
};

export const convertDateToEstTz = (date: Date | string) => {
  return new Date(new Date(date).toLocaleString("en-US", { timeZone: TIMEZONE.EST }));
};

export const convertToEst = (date: Date) => {
  const newDate = new Date();
  const clientOffsetMinutes = newDate.getTimezoneOffset();
  const estOffsetMinutes = 5 * 60;
  newDate.setTime(date.getTime() + (estOffsetMinutes - clientOffsetMinutes) * 60 * 1000);
  return newDate;
};

export const getStartOfDay = (date: Date = new Date()) => {
  const start = new Date(date);
  start.setHours(0, 0, 0, 0);
  return start;
};

export const getEndOfDay = (date: Date = new Date()) => {
  const end = new Date(date);
  end.setHours(23, 59, 59, 0);
  return end;
};

export const convertDatesToEst = <T extends { start_date?: Date | string | null; end_date?: Date | string | null }>(
  filter: T,
): T => {
  const convertedFilter = { ...filter };

  if (filter.start_date) {
    convertedFilter.start_date = convertToEst(getStartOfDay(new Date(filter.start_date)));
  }

  if (filter.end_date) {
    convertedFilter.end_date = convertToEst(getEndOfDay(new Date(filter.end_date)));
  }
  return convertedFilter;
};

export const getFirstDayOfMonth = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), 1).toISOString();
};

export const getLastDayOfMonth = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString();
};

export const getMapLocationLinkUrl = (placeId?: string) => {
  return placeId ? `https://www.google.com/maps/place/?q=place_id:${placeId}` : "#";
};

export const getFullUserName = (
  user: User | Creator | MemberListItem,
  options?: {
    shortFirstName?: boolean;
  },
) => {
  let fName = user.first_name;

  if (options?.shortFirstName) {
    fName = fName.charAt(0) + ".";
  }

  return `${fName} ${user.last_name}`;
};

export const debounce = <F extends (...args: AnyType) => AnyType>(func: F, waitFor = 500) => {
  let timeout: ReturnType<typeof setTimeout> | null = null;
  const debounced = (...args: AnyType) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => func(...args), waitFor);
  };

  return debounced as (...args: Parameters<F>) => ReturnType<F>;
};

export function getUniqueArray<T>(a: T[], property: keyof T) {
  return a.filter((item, pos, self) => self.findIndex((v) => v[property] === item[property]) === pos);
}

export const isDateInThePast = (date: Date | string | null | undefined) => {
  const currentDate = moment();
  const checkDate = moment(date);
  return checkDate.isBefore(currentDate);
};

export function optionToValue<T>(option: Option<T>) {
  return option.value;
}

export const prepareOptionFunction = (item: BaseEntity): Option<string> => {
  return { label: item.name, value: String(item.id) };
};

export const getDateAfterPeriod = (startDate: Date | string, count: number, period: unitOfTime.Base) => {
  return moment(startDate).add(count, period).toDate();
};

export const prepareSortFilter = <T,>(payload: Partial<T & { sort_by?: string[]; sort_order?: ORDER_TYPE }>) => {
  return {
    ...payload,
    sort_by: payload.sort_by && payload.sort_order ? payload.sort_by : undefined,
    sort_order: payload.sort_by && payload.sort_order ? payload.sort_order : undefined,
  };
};

export const getBrowserVersion = () => {
  const ua = navigator.userAgent;
  let tem;
  let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return "IE " + (tem[1] || "");
  }
  if (M[1] === "Chrome") {
    tem = ua.match(/\b(OPR|Edge|Edg)\/(\d+)/);
    if (tem != null) return tem.slice(1).join(" ").replace("OPR", "Opera");
  }
  M = M[2] ? [M[1], M[2]] : ["Netscape", "4.0", "-?"];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  return M.join(" ");
};
