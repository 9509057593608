import React, { useCallback } from "react";
import classnames from "classnames";
import { ErrorMessage, AutocompleteMultiSelect } from "src/shared/components/Common/";

import { AutocompleteSelectField, GenerateRenderField, Handlers } from "../../interfaces";

export const GenerateSelectSearchMulti: GenerateRenderField<AutocompleteSelectField & Handlers> = (props) => {
  const {
    formikProps: { errors, touched, setFieldValue, values, setFieldTouched },
    handlers,
    wrapperClass,
    disabled,
    label,
    name,
    options,
    placeholder,
    menuWrapper,
    isLockedLogic,
    availableOptions,
    tooltipText,
    onOptionClick,
    onLockedOptionClick,
    staticOptions,
    highlightValues,
    invertHighlight,
    isCreatable,
  } = props;

  const { getData, selectData, prepareOptionFunction, prepareBadgeFunction, selectTotalCount } =
    (handlers || {})[name] || {};

  const handleChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (option: any) => {
      setFieldValue(name, option);
      setFieldTouched(name, true, false);
    },
    [name, setFieldTouched, setFieldValue],
  );

  const handleRemoveValue = useCallback(
    (id: string) => {
      setFieldValue?.(
        name,
        (values[name] || []).filter(({ value }: { label: string; value: string }) => value !== id),
      );
      setFieldTouched(name, true, false);
    },
    [setFieldTouched, name, values, setFieldValue],
  );

  return (
    <div
      className={classnames("render-select", wrapperClass, {
        error: errors[name] && touched[name],
      })}
      key={name}
      id={name}>
      {label && <label className={classnames({ disabled })}>{label}</label>}
      <div className={classnames("select", { disabled })}>
        <AutocompleteMultiSelect
          options={options.map((o) => ({ label: o.label, value: String(o.value || "") })) || []}
          staticOptions={staticOptions}
          onOptionClick={onOptionClick}
          onLockedOptionClick={onLockedOptionClick}
          name={name}
          placeholder={placeholder}
          isDisabled={disabled}
          getData={getData}
          selectData={selectData}
          onChange={handleChange}
          value={values[name]}
          handleRemoveValue={handleRemoveValue}
          menuWrapper={menuWrapper}
          prepareOptionFunction={prepareOptionFunction}
          prepareBadgeFunction={prepareBadgeFunction}
          isLockedLogic={isLockedLogic}
          availableOptions={availableOptions}
          tooltipText={tooltipText}
          selectTotalCount={selectTotalCount}
          highlightValues={highlightValues}
          invertHighlight={invertHighlight}
          isCreatable={isCreatable}
        />
      </div>
      <ErrorMessage isTouched={!!touched[name]} error={errors[name]?.toString()} />
    </div>
  );
};
