import { Tag, TagListItem } from "src/shared/models";
import { PaginatedResponse, ActionWithPayload, Filter, BaseResponse } from "src/shared/interfaces";
import { hideOverlay, startLoading, stopLoading } from "src/shared/store/actions";
import { call, put, takeLatest } from "redux-saga/effects";
import { navigate } from "src/shared/store/actions";
import { generatePath } from "react-router";

import api from "../api/api";
import {
  getTags,
  getTag,
  createTag,
} from "./actions";
import { CreateTagSuccess } from "../interface";

function* getTagsSaga({ payload }: ActionWithPayload<Filter>) {
  try {
    yield put(startLoading());
    const response: PaginatedResponse<TagListItem> = yield call(api.getTags, payload);
    yield put(stopLoading());
    yield put(getTags.success({ ...response, clear: !payload.page }));
  } catch (error) {
    yield put(getTags.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getTagSaga({ payload }: ActionWithPayload<{ tag_id: string | number }>) {
  try {
    yield put(startLoading());
    const response: Tag = yield call(api.getTag, payload);
    yield put(stopLoading());
    yield put(getTag.success(response));
  } catch (error) {
    yield put(getTag.failure(error as Error));
    yield put(stopLoading());
  }
}

function* createTagSaga({ payload }: ActionWithPayload<Tag>) {
  try {
    yield put(startLoading());
    const response: CreateTagSuccess = yield call(api.createTag, payload);
    yield put(stopLoading());
    yield put(createTag.success(response));
  } catch (error) {
    yield put(createTag.failure(error as Error));
    yield put(stopLoading());
  }
}

function* tagSagas() {
  yield takeLatest(getTags.request, getTagsSaga);
  yield takeLatest(getTag.request, getTagSaga);
  yield takeLatest(createTag.request, createTagSaga);
}

export default tagSagas;
