export const baseEntitySchema = {
  type: "object",
  required: ["id", "name"],
  properties: {
    id: { type: "number" },
    name: { type: "string" },
  },
  additionalProperties: false,
};

export const baseFilterProperties = {
  page: { type: "number" },
  limit: { type: "number" },
  search: { type: ["string", "null"] },
};

export const getRequestSchema = (paramsProperties: unknown) => {
  return {
    type: "object",
    properties: {
      params: { type: "object", properties: paramsProperties },
    },
    additionalProperties: false,
  };
};

export const filterSchema = getRequestSchema({
  page: { type: "number" },
  limit: { type: "number" },
  status: { type: ["string", "null"] },
  role_id: { type: ["number", "null"] },
  industry_id: { type: ["number", "null"] },
  exclude: { type: ["number", "null"] },
});

export const baseFilterSchema = getRequestSchema(baseFilterProperties);

export const getPaginatedResponseSchema = (items: unknown) => {
  return {
    type: "object",
    additionalProperties: false,
    required: ["rows", "count"],
    properties: {
      rows: { type: "array", items: [items] },
      count: { type: "number" },
    },
  };
};

export const baseUpdateStatusRequestSchema = {
  type: "object",
  additionalProperties: false,
  required: ["status"],
  properties: {
    status: { type: "string" },
  },
};

export const fileProperties = {
  id: { type: "number" },
  name: { type: "string" },
  url: { type: "string" },
  created_at: { type: "string" },
  creator: {
    type: ["object", "null"],
    additionalProperties: false,
    required: ["id", "first_name", "last_name"],
    properties: {
      id: { type: "number" },
      first_name: { type: "string" },
      last_name: { type: "string" },
    },
  },
};

export const baseFileResponseSchema = {
  type: "object",
  additionalProperties: false,
  required: ["id", "name", "url", "created_at", "creator"],
  properties: fileProperties,
};

export const getFilesResponseSchema = {
  type: "array",
  additionalProperties: false,
  items: baseFileResponseSchema,
};

export const createFileResponseSchema = {
  type: "object",
  additionalProperties: false,
  required: ["message", "file"],
  properties: {
    message: { type: "string" },
    file: baseFileResponseSchema,
  },
};

export const createFileRequestSchema = {
  type: "object",
  required: ["name", "base64"],
  properties: {
    name: { type: "string" },
    base64: { type: "string" },
  },
  additionalProperties: false,
};

export const deleteFileResponseSchema = {
  type: "object",
  additionalProperties: false,
  required: ["file_id", "message"],
  properties: {
    message: { type: "string" },
    file_id: { type: "number" },
  },
};

export const shortUserSchema = {
  type: "object",
  additionalProperties: false,
  required: ["id", "first_name", "last_name"],
  properties: {
    id: { type: "number" },
    first_name: { type: "string" },
    last_name: { type: "string" },
  },
};

export const shortMemberSchema = {
  type: "object",
  additionalProperties: false,
  required: ["id", "first_name", "last_name"],
  properties: {
    id: { type: "number" },
    first_name: { type: "string" },
    last_name: { type: "string" },
    job: {
      type: ["object", "null"],
      properties: {
        id: { type: "number" },
        name: { type: "string" },
      },
    },
    user_id: { type: ["number", "null"] },
    is_auto: { type: ["boolean", "null"] },
    is_deleted: { type: ["boolean", "null"] },
  },
};

export const arrayOfBaseEntities = {
  type: "array",
  items: {
    type: "object",
    additionalProperties: false,
    properties: {
      id: { type: "number" },
      name: { type: "string" },
    },
  },
};

export const commonTaskProperties = {
  id: { type: "number" },
  index: { type: "string" },
  name: { type: "string" },
  status: { type: "string" },
  version: { type: "number" },
  created_at: { type: "string" },
  company_id: { type: ["string", "number", "null"] },
};

export const tagProperties = {
  tag_id: { type: "number" },
  tag_name: { type: "string" },
  created_by: { type: "string" },
  created_at: { type: "string" },
}

export const formTaskProperties = {
  steps: { type: ["string", "null"] },
  potential_hazards: { type: ["string", "null"] },
  hazard_control_proactive_measures: { type: ["string", "null"] },
  potential_risk: { type: ["string", "null"] },
  hazard_risk: { type: ["string", "null"] },
  parent_task_id: { type: ["number", "null"] },
  steps_media: { type: ["array", "null"] },
  potential_hazards_media: { type: ["array", "null"] },
  hazard_control_proactive_measures_media: { type: ["array", "null"] },
};
