import { createAction, createAsyncAction } from "typesafe-actions";
import { PaginatedResponse, Filter, CodeWithId, PayloadWithCode } from "src/shared/interfaces";
import { Alert, AlertListItem } from "src/shared/models";

import { AlertActionTypes } from "./constants";
import { AlertCreateUpdateShapeToRequest, DeleteAlertResponse, CreateUpdateAlertSuccess } from "../interface";

export const getAlerts = createAsyncAction(
  AlertActionTypes.GET_ALERTS,
  AlertActionTypes.GET_ALERTS_SUCCESS,
  AlertActionTypes.GET_ALERTS_FAILURE,
)<Filter, PaginatedResponse<AlertListItem>, Error>();

export const getAlert = createAsyncAction(
  AlertActionTypes.GET_ALERT,
  AlertActionTypes.GET_ALERT_SUCCESS,
  AlertActionTypes.GET_ALERT_FAILURE,
)<Required<CodeWithId>, Alert | null, Error>();

export const createAlert = createAsyncAction(
  AlertActionTypes.CREATE_ALERT,
  AlertActionTypes.CREATE_ALERT_SUCCESS,
  AlertActionTypes.CREATE_ALERT_FAILURE,
)<PayloadWithCode<AlertCreateUpdateShapeToRequest>, CreateUpdateAlertSuccess, Error>();

export const updateAlert = createAsyncAction(
  AlertActionTypes.UPDATE_ALERT,
  AlertActionTypes.UPDATE_ALERT_SUCCESS,
  AlertActionTypes.UPDATE_ALERT_FAILURE,
)<Required<CodeWithId<AlertCreateUpdateShapeToRequest>>, CreateUpdateAlertSuccess, Error>();

export const deleteAlert = createAsyncAction(
  AlertActionTypes.DELETE_ALERT,
  AlertActionTypes.DELETE_ALERT_SUCCESS,
  AlertActionTypes.DELETE_ALERT_FAILURE,
)<Required<CodeWithId>, DeleteAlertResponse, Error>();

export const clearAlerts = createAction(AlertActionTypes.CLEAR_ALERTS)();

export const setFilter = createAction(AlertActionTypes.SET_FILTER)<Filter | null>();
