import React, { FC } from "react";
import classnames from "classnames";
//import { CKEditor } from "@ckeditor/ckeditor5-react";
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-classic-plus";
import { useDispatch } from "react-redux";

import { uploadAdapterPlugin } from "./adapters/UploadAdapter";
import { REMOVED_PLUGINS, TOOLBAR_ITEMS } from "./constants";
import "./index.scss";

interface TextareaProps extends React.HTMLProps<HTMLTextAreaElement> {
  value: string;
  pathToUploadFile: string;
  className?: string;
  label?: string;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFieldValue?: (name: any, option: any) => void;
  isTouched?: boolean;
}

const RichTextArea: FC<TextareaProps> = (props) => {
  const dispatch = useDispatch();
  const { className, value, setFieldValue, label, disabled, pathToUploadFile, isTouched } = props;

  return (
    <div className={classnames("rich-textarea-wrapper", className, { disabled })}>
      <div>
        <label className={classnames({ disabled })}>{label}</label>
      </div>
      <CKEditor
        editor={ClassicEditor}
        data={value}
        disabled={disabled}
        config={{
          // mediaEmbed: {
          //   previewsInData: true,
          // },
          removePlugins: REMOVED_PLUGINS,
          toolbar: {
            shouldNotGroupWhenFull: true,
            items: TOOLBAR_ITEMS,
          },
        }}
        onBlur={(_, editor) => {
          setFieldValue && setFieldValue(props.name, editor.getData());
        }}
        onChange={(_, editor) => {
          if (isTouched) {
            setFieldValue && setFieldValue(props.name, editor.getData());
          }
        }}
        onReady={(event) => {
          uploadAdapterPlugin(event, pathToUploadFile, dispatch);
        }}
      />
    </div>
  );
};
export default RichTextArea;
