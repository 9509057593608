import { JsaListItem, Jsa, File } from "src/shared/models";
import {
  PaginatedResponse,
  ActionWithPayload,
  Filter,
  PayloadWithId,
  CreateFileSuccess,
  CreateFileShape,
  DeleteFileSuccess,
  IdWithOptionalCode,
  BaseResponse,
} from "src/shared/interfaces";
import { NameOfChildRoutes, NameOfRoutes } from "src/shared/constants";
import { startLoading, stopLoading, navigate, hideOverlay } from "src/shared/store/actions";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  JsaFormShapeToRequest,
  CreateJsaSuccess,
  UpdateJsaStatusShape,
  DeleteJsaFileShape,
  JsaImportShape,
} from "src/containers/Jsa/interface";
import { generatePath } from "react-router";

import api from "../api";
import {
  getJsa,
  getJsaItem,
  getJsaFiles,
  updateJsa,
  updateJsaStatus,
  createJsa,
  deleteJsaFile,
  addJsaFile,
  importJsa,
  setClearJsaForm,
} from "./actions";

function* getJsaSaga({ payload }: ActionWithPayload<Filter>) {
  try {
    yield put(startLoading());
    const response: PaginatedResponse<JsaListItem> = yield call(api.getJsa, payload);
    yield put(stopLoading());
    yield put(getJsa.success({ ...response, clear: !payload.page }));
  } catch (error) {
    yield put(getJsa.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getJsaItemSaga({ payload }: ActionWithPayload<{ id: string | number }>) {
  try {
    yield put(startLoading());
    const response: Jsa = yield call(api.getJsaItem, payload);
    yield put(stopLoading());
    yield put(getJsaItem.success(response));
  } catch (error) {
    yield put(getJsaItem.failure(error as Error));
    yield put(stopLoading());
  }
}

function* createJsaSaga({ payload }: ActionWithPayload<JsaFormShapeToRequest>) {
  try {
    yield put(startLoading());
    const { company_code } = payload;
    const { needRedirect, needReload, ...restPayload } = payload;
    const response: CreateJsaSuccess = yield call(api.createJsa, restPayload);
    yield put(stopLoading());
    yield put(createJsa.success(response));
    if (needRedirect && !needReload) {
      if (company_code) {
        yield put(
          navigate(
            generatePath(`${NameOfRoutes.COMPANIES}${NameOfChildRoutes.COMPANY.JSA_EDIT}`, {
              code: company_code,
              id: String(response.jsa.id),
            }),
          ),
        );
      } else {
        yield put(
          navigate(
            generatePath(`${NameOfRoutes.JSA_LIBRARY}${NameOfChildRoutes.JSA.JSA_EDIT}`, {
              id: String(response.jsa.id),
            }),
          ),
        );
      }
    }
    if (needReload) {
      yield put(setClearJsaForm(true));
    }
  } catch (error) {
    yield put(createJsa.failure(error as Error));
    yield put(stopLoading());
  }
}

function* updateJsaSaga({ payload }: ActionWithPayload<Required<PayloadWithId<JsaFormShapeToRequest>>>) {
  try {
    yield put(startLoading());
    const response: CreateJsaSuccess = yield call(api.updateJsa, payload);
    yield put(stopLoading());
    yield put(updateJsa.success(response));
  } catch (error) {
    yield put(updateJsa.failure(error as Error));
    yield put(stopLoading());
  }
}

function* updateJsaStatusSaga({ payload }: ActionWithPayload<Required<IdWithOptionalCode<UpdateJsaStatusShape>>>) {
  try {
    yield put(startLoading());
    const { code } = payload;
    const response: CreateJsaSuccess = yield call(api.updateJsaStatus, payload);
    yield put(stopLoading());
    yield put(updateJsaStatus.success(response));
    if (code) {
      yield put(navigate(generatePath(`${NameOfRoutes.COMPANIES}${NameOfChildRoutes.COMPANY.JSA_LIST}`, { code })));
    } else {
      yield put(navigate(NameOfRoutes.JSA_LIBRARY));
    }
  } catch (error) {
    yield put(updateJsaStatus.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getJsaFilesSaga({ payload }: ActionWithPayload<Required<{ id: string | number }>>) {
  try {
    yield put(startLoading());
    const response: File[] = yield call(api.getJsaFiles, payload);
    yield put(stopLoading());
    yield put(getJsaFiles.success(response));
  } catch (error) {
    yield put(getJsaFiles.failure(error as Error));
    yield put(stopLoading());
  }
}

function* addJsaFileSaga({ payload }: ActionWithPayload<Required<PayloadWithId<CreateFileShape>>>) {
  try {
    yield put(startLoading());
    const response: CreateFileSuccess = yield call(api.addJsaFile, payload);
    yield put(stopLoading());
    yield put(addJsaFile.success(response));
  } catch (error) {
    yield put(addJsaFile.failure(error as Error));
    yield put(stopLoading());
  }
}

function* deleteJsaFileSaga({ payload }: ActionWithPayload<DeleteJsaFileShape>) {
  try {
    yield put(startLoading());
    const response: DeleteFileSuccess = yield call(api.deleteJsaFile, payload);
    yield put(stopLoading());
    yield put(deleteJsaFile.success(response));
  } catch (error) {
    yield put(deleteJsaFile.failure(error as Error));
    yield put(stopLoading());
  }
}

function* importJsaSaga({ payload }: ActionWithPayload<JsaImportShape>) {
  try {
    yield put(startLoading());
    const response: BaseResponse = yield call(api.importJsa, payload);
    yield put(stopLoading());
    yield put(importJsa.success(response));
    yield put(hideOverlay());
  } catch (error) {
    yield put(importJsa.failure(error as Error));
    yield put(stopLoading());
  }
}

function* jsaSagas() {
  yield takeLatest(getJsa.request, getJsaSaga);
  yield takeLatest(getJsaItem.request, getJsaItemSaga);
  yield takeLatest(createJsa.request, createJsaSaga);
  yield takeLatest(updateJsa.request, updateJsaSaga);
  yield takeLatest(updateJsaStatus.request, updateJsaStatusSaga);
  yield takeLatest(getJsaFiles.request, getJsaFilesSaga);
  yield takeLatest(addJsaFile.request, addJsaFileSaga);
  yield takeLatest(deleteJsaFile.request, deleteJsaFileSaga);
  yield takeLatest(importJsa.request, importJsaSaga);
}

export default jsaSagas;
