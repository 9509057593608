import {
  PaginatedResponse,
  ActionWithPayload,
  Filter,
  PayloadWithId,
  BaseResponse,
  WithCallback,
} from "src/shared/interfaces";
import { startLoading, stopLoading, hideOverlay } from "src/shared/store/actions";
import { call, put, takeLatest } from "redux-saga/effects";
import { ActivityListItem, Activity } from "src/shared/models/Activity";
import {
  CreateActivitySuccess,
  ActivityFormShapeToRequest,
  UpdateStatusShape,
  ActivityImportShape,
} from "src/containers/Activity/interface";

import api from "../api";
import {
  getActivities,
  createActivity,
  updateActivity,
  updateActivityStatus,
  getActivity,
  importActivity,
} from "./actions";

function* getActivitiesSaga({ payload }: ActionWithPayload<Filter>) {
  try {
    yield put(startLoading());
    const response: PaginatedResponse<ActivityListItem> = yield call(api.getActivities, payload);
    yield put(stopLoading());
    yield put(getActivities.success({ ...response, clear: !payload.page }));
  } catch (error) {
    yield put(getActivities.failure(error as Error));
    yield put(stopLoading());
  }
}

function* createActivitySaga({ payload }: ActionWithPayload<WithCallback<ActivityFormShapeToRequest>>) {
  try {
    const { cb, ...restPayload } = payload;
    yield put(startLoading());
    const response: CreateActivitySuccess = yield call(api.createActivity, restPayload);
    yield put(stopLoading());
    yield put(createActivity.success({ ...response }));
    cb && cb();
    yield put(hideOverlay());
  } catch (error) {
    yield put(createActivity.failure(error as Error));
    yield put(stopLoading());
  }
}

function* updateActivitySaga({ payload }: ActionWithPayload<PayloadWithId<ActivityFormShapeToRequest>>) {
  try {
    yield put(startLoading());
    const response: CreateActivitySuccess = yield call(api.updateActivity, payload);
    yield put(stopLoading());
    yield put(updateActivity.success(response));
    yield put(hideOverlay());
  } catch (error) {
    yield put(updateActivity.failure(error as Error));
    yield put(stopLoading());
  }
}

function* updateActivityStatusSaga({ payload }: ActionWithPayload<PayloadWithId<UpdateStatusShape>>) {
  try {
    yield put(startLoading());
    const response: CreateActivitySuccess = yield call(api.updateActivityStatus, payload);
    yield put(stopLoading());
    yield put(updateActivity.success(response));
    yield put(hideOverlay());
  } catch (error) {
    yield put(updateActivityStatus.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getActivitySaga({ payload }: ActionWithPayload<{ id: number; company_code?: string }>) {
  try {
    yield put(startLoading());
    const response: Activity = yield call(api.getActivity, payload.id, payload.company_code);
    yield put(stopLoading());
    yield put(getActivity.success(response));
  } catch (error) {
    yield put(getActivity.failure(error as Error));
    yield put(stopLoading());
  }
}

function* importActivitySaga({ payload }: ActionWithPayload<ActivityImportShape>) {
  try {
    yield put(startLoading());
    const response: BaseResponse = yield call(api.importActivity, payload);
    yield put(stopLoading());
    yield put(importActivity.success(response));
    yield put(hideOverlay());
  } catch (error) {
    yield put(importActivity.failure(error as Error));
    yield put(stopLoading());
  }
}

function* activitySagas() {
  yield takeLatest(getActivities.request, getActivitiesSaga);
  yield takeLatest(createActivity.request, createActivitySaga);
  yield takeLatest(updateActivity.request, updateActivitySaga);
  yield takeLatest(updateActivityStatus.request, updateActivityStatusSaga);
  yield takeLatest(getActivity.request, getActivitySaga);
  yield takeLatest(importActivity.request, importActivitySaga);
}

export default activitySagas;
