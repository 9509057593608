import { optionToValue, request } from "src/shared/utils";
import { API, METHODS } from "src/shared/constants";
import { CodeWithId } from "src/shared/interfaces";

import {
  AssignmentCheckInOutFormShapeToRequest,
  AssignmentFilter,
  AssignmentFormShapeToRequest,
  UpdateAssignmentStatusShape,
  AssignmentCheckInOutFilter,
  AssignmentPassedJsaFilter,
  AssignmentJsaFilter,
} from "../interface";

export const assignmentSortFieldKeys = ["start_date", "project", "location", "status", "name"];
const formatAssignmentFilter = (filter: AssignmentFilter) => {
  const isSortBy = assignmentSortFieldKeys.find((asf) => filter.sort_by && filter.sort_by.find((sb) => sb === asf));

  return {
    page: filter.page,
    limit: filter.limit,
    is_pagination: filter.is_pagination,
    start_date: filter.start_date,
    end_date: filter.end_date,
    project_id: filter.project?.value,
    location_id: filter.location?.value,
    activities: filter.activities?.map(optionToValue),
    members: filter.members?.map(optionToValue),
    teams: filter.teams?.map(optionToValue),
    statuses: filter.statuses?.map(optionToValue),
    sort_by: isSortBy ? filter.sort_by : undefined,
    sort_order: isSortBy ? filter.sort_order : undefined,
  };
};

export const memberAssignmentSortFieldKeys = ["name", "position", "assignments_count", "last_assignment_start_date"];
const formatAssignmentMemberFilter = (filter: AssignmentFilter) => {
  const isSortBy = memberAssignmentSortFieldKeys.find(
    (asf) => filter.sort_by && filter.sort_by.find((sb) => sb === asf),
  );

  return {
    page: filter.page,
    limit: filter.limit,
    start_date: filter.start_date,
    end_date: filter.end_date,
    search: filter.search,
    without_assignments: !!filter.without_assignments,
    members: filter.members?.map(optionToValue),
    teams: filter.teams?.map(optionToValue),
    statuses: filter.statuses?.map(optionToValue),
    sort_by: isSortBy ? filter.sort_by : undefined,
    sort_order: isSortBy ? filter.sort_order : undefined,
  };
};

export default {
  getAssignments: (payload: AssignmentFilter) => {
    const { company_code, ...assignmentFilter } = payload;
    return request(
      METHODS.GET,
      API.ASSIGNMENT.GET_ASSIGNMENTS(company_code ?? ""),
    )({ params: formatAssignmentFilter(assignmentFilter) });
  },
  getMembersAssignments: (payload: AssignmentFilter) => {
    const { company_code, ...memberAssignmentFilter } = payload;
    return request(
      METHODS.GET,
      API.MEMBER.GET_MEMBERS_ASSIGNMENTS(company_code ?? ""),
    )({ params: formatAssignmentMemberFilter(memberAssignmentFilter) });
  },
  createAssignment: (payload: AssignmentFormShapeToRequest) => {
    const { company_code, ...assignmentPayload } = payload;
    return request(METHODS.POST, API.ASSIGNMENT.CREATE_ASSIGNMENT(company_code || ""))(assignmentPayload);
  },
  updateAssignment: (payload: Required<CodeWithId<AssignmentFormShapeToRequest>>) => {
    return request(METHODS.PUT, API.ASSIGNMENT.UPDATE_ASSIGNMENT(payload.code, payload.id))(payload.payload);
  },
  getAssignment: (payload: Required<CodeWithId>) => {
    return request(METHODS.GET, API.ASSIGNMENT.GET_ASSIGNMENT(payload.code, payload.id))();
  },
  getAssignmentCheckInOut: (payload: Required<CodeWithId<AssignmentCheckInOutFilter>>) => {
    return request(
      METHODS.GET,
      API.ASSIGNMENT.GET_ASSIGNMENT_CHECK_IN_OUT(payload.code, payload.id),
    )({ params: payload.payload });
  },
  getAssignmentPassedJsa: (payload: Required<CodeWithId<AssignmentPassedJsaFilter>>) => {
    return request(
      METHODS.GET,
      API.ASSIGNMENT.GET_ASSIGNMENT_PASSED_JSA(payload.code, payload.id),
    )({ params: payload.payload });
  },
  getAssignmentJsa: ({ code, id, payload }: Required<CodeWithId<AssignmentJsaFilter>>) => {
    return request(
      METHODS.GET,
      API.ASSIGNMENT.GET_ASSIGNMENT_JSA(code, id),
    )({
      params: payload,
    });
  },
  createAssignmentCheckIn: (payload: Required<CodeWithId<AssignmentCheckInOutFormShapeToRequest>>) => {
    return request(METHODS.POST, API.ASSIGNMENT.ADD_ASSIGNMENT_CHECK_IN(payload.code, payload.id))(payload.payload);
  },
  createAssignmentCheckOut: (payload: Required<CodeWithId<AssignmentCheckInOutFormShapeToRequest>>) => {
    return request(METHODS.POST, API.ASSIGNMENT.ADD_ASSIGNMENT_CHECK_OUT(payload.code, payload.id))(payload.payload);
  },
  updateAssignmentStatus: (payload: Required<CodeWithId<UpdateAssignmentStatusShape>>) => {
    return request(METHODS.PUT, API.ASSIGNMENT.UPDATE_ASSIGNMENT_STATUS(payload.code, payload.id))(payload.payload);
  },
};
