import { BaseEntity } from "src/shared/interfaces";

import { Creator } from "./User";
import { Activity } from "./Activity";
import { Task } from "./Task";
import { Tag } from "./Tag";

import { MemberListItem, Location, Member, CertificateTemplate, ActivityListItem } from "./";

export enum AssignmentStatus {
  Archived = "Archived",
  Past = "Past",
  Scheduled = "Scheduled",
  Draft = "Draft",
  InProgress = "In Progress",
}

interface BaseAssignment {
  id: number;
  start_date: Date;
  end_date: Date;
  location: Location;
  status: AssignmentStatus;
}

export interface AssignmentActivity {
  id?: number;
  activity: ActivityListItem | Activity;
  jsa: Array<BaseEntity | null>;
}

export interface Assignment extends BaseAssignment {
  check_in_count?: number;
  description: string;
  members: MemberListItem[];
  assignment_activities: AssignmentActivity[];
  creator: Creator;
  version: string;
  created_at: string;
  updated_at: string | null;
  updated_by: string | null;
  certificate_templates: CertificateTemplate[];
  project: {
    id: number;
    name: string;
    number: string;
  } | null;
}

export interface AssignmentListItem extends BaseAssignment {
  project: BaseEntity | null;
  activities: BaseEntity[];
}

export interface AssignmentCheckInOutState {
  id: number;
  member_id: number;
  member: Partial<Member>;
  check_in_date: Date;
  check_in_creator: Creator;
  check_in_location: Location;

  check_out_date?: Date | null;
  check_out_creator?: Creator | null;
  check_out_location?: Location | null;

  is_passed_all_jsa?: boolean;
  is_check_in_outside?: boolean;
  is_check_out_outside?: boolean;
}

export interface AssignmentPassedJsa {
  jsa_id: number;
  activity_id: number;
  created_at: Date;
}

export interface AssignmentJsa {
  id: number;
  jsa_id: number;
  name: string;
  tasks: Task[];
  tags: Tag[];
}

export interface AssignmentPassedTask {
  id: number;
  task_id: number;
  task: Task;
  tag: Tag;
}
export interface AssignmentExtendedPassedJsa {
  name: string;
  jsa_id: number;
  jsa_old_version_id: number;
  signature_url: string;
  signature_date: string;
  member_first_name: string;
  member_last_name: string;
  passed_tasks: AssignmentPassedTask[];
  tags: Tag[];
}

export interface AssignmentUnitedJsa {
  jsa_id: number;
  name: string;
  signature_url?: string | null;
  signature_date?: string | null;
  member_first_name?: string;
  member_last_name?: string;
  assignment_date: string | null;
  project_name: string | null;
  project_number: string | null;
  location_address: string | null;
  tasks: Task[];
}
