import React, { FC, useCallback } from "react";
import moment from "moment";
import classnames from "classnames";
import { UserNotification } from "src/shared/models";

import "./index.scss";

export interface UserNotificationListItemProps {
  notification: UserNotification;
  className?: string;
  onClick?: (notification: UserNotification) => void;
}

export const UserNotificationListItem: FC<UserNotificationListItemProps> = ({ notification, className, onClick }) => {
  const durationHours = moment.duration(moment().diff(notification.created_at)).asHours();
  const notificationTime =
    durationHours > 24 ? moment(notification.created_at).format("lll") : moment(notification.created_at).fromNow();

  const handleClick = useCallback(() => {
    onClick?.(notification);
  }, [onClick, notification]);

  return (
    <div
      onClick={handleClick}
      className={classnames(className, "user-notification", {
        "user-notification_unread": !notification.is_read,
      })}>
      {notification.text}
      <div className="user-notification-delay">{notificationTime.toString()}</div>
    </div>
  );
};

export default UserNotificationListItem;
