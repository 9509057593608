import React, { useCallback } from "react";
import { useHideModal, useShowModal } from "src/shared/hooks";
import { ContactUsContainer } from '../containers';


export function useContactUsModal(companyCode?: string | null) {
  const showModal = useShowModal();
  const { handleOnClose } = useHideModal();

  const showContactUsModal = useCallback(() => {
    if (!companyCode) return;

    showModal({
      title: "Contact Us",
      body: <ContactUsContainer companyCode={companyCode} onCancel={handleOnClose} />,
      wrapperClass: "contact-us-modal-wrapper",
      modalWidth: "470px",
    });
  }, [showModal, companyCode, handleOnClose]);

  return {
    showContactUsModal,
  };
}

export default useContactUsModal;
