import config from "../../config";

export enum API_ENUM {
  LOGIN = "LOGIN",
  REGISTRATION = "REGISTRATION",
  ACTIVATE = "ACTIVATE",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  SET_PASSWORD = "SET_PASSWORD",
  GET_USER_DETAILS = "GET_USER_DETAILS",
  GET_ROLES = "GET_ROLES",
  GET_JOBS = "GET_JOBS",
  GET_INDUSTRIES = "GET_INDUSTRIES",
  GET_USERS = "GET_USERS",
  GET_USER = "GET_USER",
  CREATE_USER = "CREATE_USER",
  UPDATE_USER = "UPDATE_USER",
  UPDATE_USER_STATUS = "UPDATE_USER_STATUS",
  CHECK_USER_EXISTS = "CHECK_USER_EXISTS",
  GET_COMPANIES = "GET_COMPANIES",
  CREATE_COMPANY = "CREATE_COMPANY",
  GET_COMPANY = "GET_COMPANY",
  UPDATE_COMPANY = "UPDATE_COMPANY",
  UPDATE_COMPANY_STATUS = "UPDATE_COMPANY_STATUS",
  GET_ADDRESS = "GET_ADDRESS",
  GET_LOCATION = "GET_LOCATION",
  GET_MEMBERS = "GET_MEMBERS",
  GET_MEMBERS_ASSIGNMENTS = "GET_MEMBERS_ASSIGNMENTS",
  GET_MEMBERS_TRAININGS = "GET_MEMBERS_TRAININGS",
  GET_MEMBERS_CERTIFICATES = "GET_MEMBERS_CERTIFICATES",
  GET_MEMBERS_JSA = "GET_MEMBERS_JSA",
  GET_TEAMS = "GET_TEAMS",
  GET_TEAM = "GET_TEAM",
  CREATE_TEAM = "CREATE_TEAM",
  UPDATE_TEAM = "UPDATE_TEAM",
  DELETE_TEAM = "DELETE_TEAM",
  GET_MEMBER = "GET_MEMBER",
  CREATE_MEMBER = "CREATE_MEMBER",
  UPDATE_MEMBER = "UPDATE_MEMBER",
  UPDATE_MEMBER_STATUS = "UPDATE_MEMBER_STATUS",
  GET_MEMBER_FILES = "GET_MEMBER_FILES",
  ADD_MEMBER_FILE = "ADD_MEMBER_FILE",
  DELETE_MEMBER_FILE = "DELETE_MEMBER_FILE",
  GET_MEMBER_NOTES = "GET_MEMBER_NOTES",
  GET_MEMBER_PASSED_JSA = "GET_MEMBER_PASSED_JSA",
  ADD_MEMBER_NOTE = "ADD_MEMBER_NOTE",
  DELETE_MEMBER_NOTE = "DELETE_MEMBER_NOTE",
  SET_MEMBER_SIGNATURE = "SET_MEMBER_SIGNATURE",
  DELETE_MEMBER_SIGNATURE = "DELETE_MEMBER_SIGNATURE",
  GET_ACTIVITIES = "GET_ACTIVITIES",
  GET_ACTIVITY = "GET_ACTIVITY",
  CREATE_ACTIVITY = "CREATE_ACTIVITY",
  UPDATE_ACTIVITY = "UPDATE_ACTIVITY",
  UPDATE_ACTIVITY_STATUS = "UPDATE_ACTIVITY_STATUS",
  IMPORT_ACTIVITY = "IMPORT_ACTIVITY",
  GET_TAGS = "GET_TAGS",
  GET_TAG = "GET_TAG",
  CREATE_TAG = "CREATE_TAG",
  DELETE_TAG = "DELETE_TAG",
  GET_TASKS = "GET_TASKS",
  GET_TASK = "GET_TASK",
  CREATE_TASK = "CREATE_TASK",
  UPLOAD_TEXT_EDITOR_FILE_TASK = "UPLOAD_TEXT_EDITOR_FILE_TASK",
  UPLOAD_FILE = "UPLOAD_FILE",
  UPDATE_TASK = "UPDATE_TASK",
  IMPORT_TASK = "IMPORT_TASK",
  UPDATE_TASK_STATUS = "UPDATE_TASK_STATUS",
  GET_TASK_FILES = "GET_TASK_FILES",
  ADD_TASK_FILE = "ADD_TASK_FILE",
  DELETE_TASK_FILE = "DELETE_TASK_FILE",
  GET_JSA_ITEM = "GET_JSA_ITEM",
  UPDATE_JSA = "UPDATE_JSA",
  CREATE_JSA = "CREATE_JSA",
  GET_JSA = "GET_JSA",
  IMPORT_JSA = "IMPORT_JSA",
  UPDATE_JSA_STATUS = "UPDATE_JSA_STATUS",
  GET_JSA_FILES = "GET_JSA_FILES",
  ADD_JSA_FILE = "ADD_JSA_FILE",
  DELETE_JSA_FILE = "DELETE_JSA_FILE",
  GET_ASSIGNMENTS = "GET_ASSIGNMENTS",
  CREATE_ASSIGNMENT = "CREATE_ASSIGNMENT",
  UPDATE_ASSIGNMENT = "UPDATE_ASSIGNMENT",
  UPDATE_ASSIGNMENT_STATUS = "UPDATE_ASSIGNMENT_STATUS",
  GET_ASSIGNMENT = "GET_ASSIGNMENT",
  GET_ASSIGNMENT_CHECK_IN_OUT = "GET_ASSIGNMENT_CHECK_IN_OUT",
  ADD_ASSIGNMENT_CHECK_IN = "ADD_ASSIGNMENT_CHECK_IN",
  ADD_ASSIGNMENT_CHECK_OUT = "ADD_ASSIGNMENT_CHECK_OUT",
  GET_ASSIGNMENT_JSA = "GET_ASSIGNMENT_JSA",
  GET_ASSIGNMENT_PASSED_JSA = "GET_ASSIGNMENT_PASSED_JSA",
  GET_PROJECTS = "GET_PROJECTS",
  GET_PROJECT = "GET_PROJECT",
  CREATE_PROJECT = "CREATE_PROJECT",
  UPDATE_PROJECT = "UPDATE_PROJECT",
  DELETE_PROJECT = "DELETE_PROJECT",
  GET_CLIENTS = "GET_CLIENTS",
  GET_TRAININGS = "GET_TRAININGS",
  GET_TRAINING = "GET_TRAINING",
  CREATE_TRAINING = "CREATE_TRAINING",
  UPDATE_TRAINING = "UPDATE_TRAINING",
  UPDATE_TRAINING_STATUS = "UPDATE_TRAINING_STATUS",
  GET_TRAINING_PASS = "GET_TRAINING_PASS",
  PASS_TRAINING = "PASS_TRAINING",
  CONFIRM_TRAINING = "CONFIRM_TRAINING",
  GET_CERTIFICATES = "GET_CERTIFICATES",
  GET_CERTIFICATE_TEMPLATES = "GET_CERTIFICATE_TEMPLATES",
  GET_CERTIFICATES_ISSUED_BY = "GET_CERTIFICATES_ISSUED_BY",
  GET_CERTIFICATE = "GET_CERTIFICATE",
  CREATE_CERTIFICATE = "CREATE_CERTIFICATE",
  UPDATE_CERTIFICATE = "UPDATE_CERTIFICATE",
  GET_CERTIFICATE_FILES = "GET_CERTIFICATE_FILES",
  ADD_CERTIFICATE_FILE = "ADD_CERTIFICATE_FILE",
  DELETE_CERTIFICATE_FILE = "DELETE_CERTIFICATE_FILE",
  UPDATE_CERTIFICATE_STATUS = "UPDATE_CERTIFICATE_STATUS",
  GET_DOCUMENTS = "GET_DOCUMENTS",
  GET_DOCUMENT = "GET_DOCUMENT",
  CREATE_DOCUMENT = "CREATE_DOCUMENT",
  UPDATE_DOCUMENT = "UPDATE_DOCUMENT",
  DELETE_DOCUMENT = "DELETE_DOCUMENT",
  GET_ALERT = "GET_ALERT",
  CREATE_ALERT = "CREATE_ALERT",
  UPDATE_ALERT = "UPDATE_ALERT",
  GET_ALERTS = "GET_ALERTS",
  DELETE_ALERT = "DELETE_ALERT",
  GET_USER_NOTIFICATIONS = "GET_USER_NOTIFICATIONS",
  GET_USER_NOTIFICATIONS_UNREAD_COUNT = "GET_USER_NOTIFICATIONS_UNREAD_COUNT",
  READ_USER_NOTIFICATIONS = "READ_USER_NOTIFICATIONS",
  READ_USER_NOTIFICATION = "READ_USER_NOTIFICATION",
  CREATE_SUPPORT_MESSAGE = "CREATE_SUPPORT_MESSAGE",
}

export default {
  AUTH: {
    [API_ENUM.LOGIN]: `${config.baseApiUrl}auth/login`,
    [API_ENUM.REGISTRATION]: `${config.baseApiUrl}auth/registration`,
    [API_ENUM.ACTIVATE]: `${config.baseApiUrl}auth/activate`,
    [API_ENUM.FORGOT_PASSWORD]: `${config.baseApiUrl}auth/forgot-password`,
    [API_ENUM.SET_PASSWORD]: `${config.baseApiUrl}auth/set-password`,
  },
  SHARED: {
    [API_ENUM.GET_USER_DETAILS]: `${config.baseApiUrl}users/details`,
    [API_ENUM.GET_ROLES]: `${config.baseApiUrl}shared/roles`,
    [API_ENUM.GET_JOBS]: `${config.baseApiUrl}shared/jobs`,
    [API_ENUM.GET_INDUSTRIES]: `${config.baseApiUrl}shared/industries`,
    [API_ENUM.GET_ADDRESS]: `${config.baseApiUrl}shared/locations`,
    [API_ENUM.GET_LOCATION]: `${config.baseApiUrl}shared/geocode`,
    [API_ENUM.GET_CLIENTS]: (code: string) => `${config.baseApiUrl}companies/${code}/clients`,
    [API_ENUM.UPLOAD_FILE]: `${config.baseApiUrl}shared/upload-file`,
  },
  USER: {
    [API_ENUM.GET_USERS]: `${config.baseApiUrl}users`,
    [API_ENUM.GET_USER]: (id: number | string) => `${config.baseApiUrl}users/${id}`,
    [API_ENUM.CREATE_USER]: `${config.baseApiUrl}users`,
    [API_ENUM.CHECK_USER_EXISTS]: `${config.baseApiUrl}users/exists`,
    [API_ENUM.UPDATE_USER]: (id: number | string) => `${config.baseApiUrl}users/${id}`,
    [API_ENUM.UPDATE_USER_STATUS]: (id: number | string) => `${config.baseApiUrl}users/${id}/status`,
  },
  COMPANY: {
    [API_ENUM.GET_COMPANIES]: `${config.baseApiUrl}companies`,
    [API_ENUM.CREATE_COMPANY]: `${config.baseApiUrl}companies`,
    [API_ENUM.GET_COMPANY]: (code: string) => `${config.baseApiUrl}companies/${code}`,
    [API_ENUM.UPDATE_COMPANY]: (code: string) => `${config.baseApiUrl}companies/${code}`,
    [API_ENUM.UPDATE_COMPANY_STATUS]: (code: string) => `${config.baseApiUrl}companies/${code}/status`,
  },
  MEMBER: {
    [API_ENUM.GET_MEMBERS]: (code: string) => `${config.baseApiUrl}companies/${code}/members`,
    [API_ENUM.GET_MEMBERS_TRAININGS]: (code: string) => `${config.baseApiUrl}companies/${code}/members/trainings`,
    [API_ENUM.GET_MEMBERS_ASSIGNMENTS]: (code: string) => `${config.baseApiUrl}companies/${code}/members/assignments`,
    [API_ENUM.GET_MEMBERS_JSA]: (code: string) => `${config.baseApiUrl}companies/${code}/members/jsa`,
    [API_ENUM.GET_MEMBERS_CERTIFICATES]: (code: string) => `${config.baseApiUrl}companies/${code}/members/certificates`,
    [API_ENUM.CREATE_MEMBER]: (code: string) => `${config.baseApiUrl}companies/${code}/members`,
    [API_ENUM.GET_MEMBER]: (code: string, id: number | string) => `${config.baseApiUrl}companies/${code}/members/${id}`,
    [API_ENUM.UPDATE_MEMBER]: (code: string, id: number | string) =>
      `${config.baseApiUrl}companies/${code}/members/${id}`,
    [API_ENUM.UPDATE_MEMBER_STATUS]: (code: string, id: number | string) =>
      `${config.baseApiUrl}companies/${code}/members/${id}/status`,
    [API_ENUM.GET_MEMBER_FILES]: (code: string, id: number | string) =>
      `${config.baseApiUrl}companies/${code}/members/${id}/files`,
    [API_ENUM.ADD_MEMBER_FILE]: (code: string, id: number | string) =>
      `${config.baseApiUrl}companies/${code}/members/${id}/files`,
    [API_ENUM.DELETE_MEMBER_FILE]: (code: string, member_id: number | string, id: number | string) =>
      `${config.baseApiUrl}companies/${code}/members/${member_id}/files/${id}`,
    [API_ENUM.GET_MEMBER_NOTES]: (code: string, id: number | string) =>
      `${config.baseApiUrl}companies/${code}/members/${id}/notes`,
    [API_ENUM.ADD_MEMBER_NOTE]: (code: string, id: number | string) =>
      `${config.baseApiUrl}companies/${code}/members/${id}/notes`,
    [API_ENUM.DELETE_MEMBER_NOTE]: (code: string, member_id: number | string, id: number | string) =>
      `${config.baseApiUrl}companies/${code}/members/${member_id}/notes/${id}`,
    [API_ENUM.SET_MEMBER_SIGNATURE]: (code: string, id: number | string) =>
      `${config.baseApiUrl}companies/${code}/members/${id}/signatures`,
    [API_ENUM.DELETE_MEMBER_SIGNATURE]: (code: string, id: number | string) =>
      `${config.baseApiUrl}companies/${code}/members/${id}/signatures`,
    [API_ENUM.GET_MEMBER_PASSED_JSA]: (code: string, id: number | string) =>
      `${config.baseApiUrl}companies/${code}/members/passed_jsa/${id}`,
  },
  ACTIVITIES: {
    [API_ENUM.GET_ACTIVITIES]: `${config.baseApiUrl}activities`,
    [API_ENUM.GET_ACTIVITY]: (id: number | string) => `${config.baseApiUrl}activities/${id}`,
    [API_ENUM.IMPORT_ACTIVITY]: `${config.baseApiUrl}activities/import`,
    [API_ENUM.CREATE_ACTIVITY]: `${config.baseApiUrl}activities`,
    [API_ENUM.UPDATE_ACTIVITY]: (id: number | string) => `${config.baseApiUrl}activities/${id}`,
    [API_ENUM.UPDATE_ACTIVITY_STATUS]: (id: number | string) => `${config.baseApiUrl}activities/${id}/status`,
  },
  TAG: {
    [API_ENUM.GET_TAGS]: `${config.baseApiUrl}tags`,
    [API_ENUM.GET_TAG]: (tag_id: number | string) => `${config.baseApiUrl}tags/${tag_id}`,
    [API_ENUM.CREATE_TAG]: `${config.baseApiUrl}tags`,
    [API_ENUM.DELETE_TAG]: `${config.baseApiUrl}tags`,
  },
  TASK: {
    [API_ENUM.GET_TASKS]: `${config.baseApiUrl}tasks`,
    [API_ENUM.GET_TASK]: (id: number | string) => `${config.baseApiUrl}tasks/${id}`,
    [API_ENUM.IMPORT_TASK]: `${config.baseApiUrl}tasks/import`,
    [API_ENUM.CREATE_TASK]: `${config.baseApiUrl}tasks`,
    [API_ENUM.UPDATE_TASK]: (id: number | string) => `${config.baseApiUrl}tasks/${id}`,
    [API_ENUM.UPDATE_TASK_STATUS]: (id: number | string) => `${config.baseApiUrl}tasks/${id}/status`,
    [API_ENUM.GET_TASK_FILES]: (id: number | string) => `${config.baseApiUrl}tasks/${id}/files`,
    [API_ENUM.ADD_TASK_FILE]: (id: number | string) => `${config.baseApiUrl}tasks/${id}/files`,
    [API_ENUM.DELETE_TASK_FILE]: (task_id: number | string, id: number | string) =>
      `${config.baseApiUrl}tasks/${task_id}/files/${id}`,
  },
  JSA: {
    [API_ENUM.GET_JSA]: `${config.baseApiUrl}jsa`,
    [API_ENUM.CREATE_JSA]: `${config.baseApiUrl}jsa`,
    [API_ENUM.IMPORT_JSA]: `${config.baseApiUrl}jsa/import`,
    [API_ENUM.GET_JSA_ITEM]: (id: number | string) => `${config.baseApiUrl}jsa/${id}`,
    [API_ENUM.UPDATE_JSA]: (id: number | string) => `${config.baseApiUrl}jsa/${id}`,
    [API_ENUM.UPDATE_JSA_STATUS]: (id: number | string) => `${config.baseApiUrl}jsa/${id}/status`,
    [API_ENUM.GET_JSA_FILES]: (id: number | string) => `${config.baseApiUrl}jsa/${id}/files`,
    [API_ENUM.ADD_JSA_FILE]: (id: number | string) => `${config.baseApiUrl}jsa/${id}/files`,
    [API_ENUM.DELETE_JSA_FILE]: (jsa_id: number | string, id: number | string) =>
      `${config.baseApiUrl}jsa/${jsa_id}/files/${id}`,
  },
  ASSIGNMENT: {
    [API_ENUM.GET_ASSIGNMENTS]: (code: string) => `${config.baseApiUrl}companies/${code}/assignments`,
    [API_ENUM.CREATE_ASSIGNMENT]: (code: string) => `${config.baseApiUrl}companies/${code}/assignments`,
    [API_ENUM.GET_ASSIGNMENT]: (code: string, id: number | string) =>
      `${config.baseApiUrl}companies/${code}/assignments/${id}`,
    [API_ENUM.UPDATE_ASSIGNMENT]: (code: string, id: number | string) =>
      `${config.baseApiUrl}companies/${code}/assignments/${id}`,
    [API_ENUM.UPDATE_ASSIGNMENT_STATUS]: (code: string, id: number | string) =>
      `${config.baseApiUrl}companies/${code}/assignments/${id}/status`,
    [API_ENUM.GET_ASSIGNMENT_CHECK_IN_OUT]: (code: string, id: number | string) =>
      `${config.baseApiUrl}companies/${code}/assignments/${id}/check-in-out`,
    [API_ENUM.ADD_ASSIGNMENT_CHECK_IN]: (code: string, id: number | string) =>
      `${config.baseApiUrl}companies/${code}/assignments/${id}/check-in`,
    [API_ENUM.ADD_ASSIGNMENT_CHECK_OUT]: (code: string, id: number | string) =>
      `${config.baseApiUrl}companies/${code}/assignments/${id}/check-out`,
    [API_ENUM.GET_ASSIGNMENT_PASSED_JSA]: (code: string, id: number | string) =>
      `${config.baseApiUrl}companies/${code}/assignments/${id}/pass-jsa`,
    [API_ENUM.GET_ASSIGNMENT_JSA]: (code: string, id: number | string) =>
      `${config.baseApiUrl}companies/${code}/assignments/${id}/jsa`,
  },
  TRAINING: {
    [API_ENUM.GET_TRAININGS]: (code: string) => `${config.baseApiUrl}companies/${code}/trainings`,
    [API_ENUM.GET_TRAINING]: (code: string, id: number | string) =>
      `${config.baseApiUrl}companies/${code}/trainings/${id}`,
    [API_ENUM.CREATE_TRAINING]: (code: string) => `${config.baseApiUrl}companies/${code}/trainings`,
    [API_ENUM.UPDATE_TRAINING]: (code: string, id: number | string) =>
      `${config.baseApiUrl}companies/${code}/trainings/${id}`,
    [API_ENUM.UPDATE_TRAINING_STATUS]: (code: string, id: number | string) =>
      `${config.baseApiUrl}companies/${code}/trainings/${id}/status`,
    [API_ENUM.GET_TRAINING_PASS]: (code: string, id: number | string) =>
      `${config.baseApiUrl}companies/${code}/trainings/${id}/pass`,
    [API_ENUM.PASS_TRAINING]: (code: string, id: number | string) =>
      `${config.baseApiUrl}companies/${code}/trainings/${id}/pass`,
    [API_ENUM.CONFIRM_TRAINING]: (code: string, id: number | string) =>
      `${config.baseApiUrl}companies/${code}/trainings/${id}/confirm`,
  },
  CERTIFICATE: {
    [API_ENUM.GET_CERTIFICATES]: (code: string) => `${config.baseApiUrl}companies/${code}/certificates`,
    [API_ENUM.GET_CERTIFICATE_TEMPLATES]: (code: string) =>
      `${config.baseApiUrl}companies/${code}/certificate_templates`,
    [API_ENUM.GET_CERTIFICATES_ISSUED_BY]: (code: string) =>
      `${config.baseApiUrl}companies/${code}/certificates/issued_by`,
    [API_ENUM.GET_CERTIFICATE]: (code: string, id: number | string) =>
      `${config.baseApiUrl}companies/${code}/certificates/${id}`,
    [API_ENUM.CREATE_CERTIFICATE]: (code: string) => `${config.baseApiUrl}companies/${code}/certificates`,
    [API_ENUM.UPDATE_CERTIFICATE]: (code: string, id: number | string) =>
      `${config.baseApiUrl}companies/${code}/certificates/${id}`,
    [API_ENUM.GET_CERTIFICATE_FILES]: (code: string, id: number | string) =>
      `${config.baseApiUrl}companies/${code}/certificates/${id}/files`,
    [API_ENUM.ADD_CERTIFICATE_FILE]: (code: string, id: number | string) =>
      `${config.baseApiUrl}companies/${code}/certificates/${id}/files`,
    [API_ENUM.DELETE_CERTIFICATE_FILE]: (code: string, id: number | string, file_id: number | string) =>
      `${config.baseApiUrl}companies/${code}/certificates/${id}/files/${file_id}`,
    [API_ENUM.UPDATE_CERTIFICATE_STATUS]: (code: string, id: number | string) =>
      `${config.baseApiUrl}companies/${code}/certificates/${id}/status`,
  },
  DOCUMENT: {
    [API_ENUM.GET_DOCUMENTS]: (code: string) => `${config.baseApiUrl}companies/${code}/documents`,
    [API_ENUM.GET_DOCUMENT]: (code: string, id: string | number) =>
      `${config.baseApiUrl}companies/${code}/documents/${id}`,
    [API_ENUM.CREATE_DOCUMENT]: (code: string) => `${config.baseApiUrl}companies/${code}/documents`,
    [API_ENUM.UPDATE_DOCUMENT]: (code: string, id: string | number) =>
      `${config.baseApiUrl}companies/${code}/documents/${id}`,
    [API_ENUM.DELETE_DOCUMENT]: (code: string, id: string | number) =>
      `${config.baseApiUrl}companies/${code}/documents/${id}`,
  },
  ALERT: {
    [API_ENUM.GET_ALERTS]: (code: string) => `${config.baseApiUrl}companies/${code}/alerts`,
    [API_ENUM.GET_ALERT]: (code: string, id: string | number) => `${config.baseApiUrl}companies/${code}/alerts/${id}`,
    [API_ENUM.CREATE_ALERT]: (code: string) => `${config.baseApiUrl}companies/${code}/alerts`,
    [API_ENUM.UPDATE_ALERT]: (code: string, id: string | number) =>
      `${config.baseApiUrl}companies/${code}/alerts/${id}`,
    [API_ENUM.DELETE_ALERT]: (code: string, id: string | number) =>
      `${config.baseApiUrl}companies/${code}/alerts/${id}`,
  },
  USER_NOTIFICATION: {
    [API_ENUM.GET_USER_NOTIFICATIONS]: (code: string) => `${config.baseApiUrl}companies/${code}/notifications`,
    [API_ENUM.GET_USER_NOTIFICATIONS_UNREAD_COUNT]: (code: string) =>
      `${config.baseApiUrl}companies/${code}/notifications/unread`,
    [API_ENUM.READ_USER_NOTIFICATIONS]: (code: string) => `${config.baseApiUrl}companies/${code}/notifications/read`,
    [API_ENUM.READ_USER_NOTIFICATION]: (code: string, id: string | number) =>
      `${config.baseApiUrl}companies/${code}/notifications/${id}/read`,
  },
  SUPPORT_MESSAGE: {
    [API_ENUM.CREATE_SUPPORT_MESSAGE]: (code: string) => `${config.baseApiUrl}companies/${code}/support_messages`,
  },
  PROJECT: {
    [API_ENUM.GET_PROJECTS]: (code: string) => `${config.baseApiUrl}companies/${code}/projects`,
    [API_ENUM.GET_PROJECT]: (code: string, id: string | number) =>
      `${config.baseApiUrl}companies/${code}/projects/${id}`,
    [API_ENUM.CREATE_PROJECT]: (code: string) => `${config.baseApiUrl}companies/${code}/projects`,
    [API_ENUM.UPDATE_PROJECT]: (code: string, id: string | number) =>
      `${config.baseApiUrl}companies/${code}/projects/${id}`,
    [API_ENUM.DELETE_PROJECT]: (code: string, id: string | number) =>
      `${config.baseApiUrl}companies/${code}/projects/${id}`,
  },
  TEAM: {
    [API_ENUM.GET_TEAMS]: (code: string) => `${config.baseApiUrl}companies/${code}/teams`,
    [API_ENUM.GET_TEAM]: (code: string, id: string | number) => `${config.baseApiUrl}companies/${code}/teams/${id}`,
    [API_ENUM.CREATE_TEAM]: (code: string) => `${config.baseApiUrl}companies/${code}/teams`,
    [API_ENUM.UPDATE_TEAM]: (code: string, id: string | number) => `${config.baseApiUrl}companies/${code}/teams/${id}`,
    [API_ENUM.DELETE_TEAM]: (code: string, id: string | number) => `${config.baseApiUrl}companies/${code}/teams/${id}`,
  },
};
