import React, { useCallback, useRef, useMemo } from "react";
import { useDispatch } from "react-redux";
import classnames from "classnames";
import { InputFile, Icon } from "src/shared/components";
import { MAX_AVATAR_SIZE } from "src/shared/constants";
import { actions } from "src/shared/store";

import "./index.scss";

export interface AvatarInputProps {
  className?: string;
  onChange: (data: { base64: string; name: string }) => void;
  onDelete: () => void;
  value?: string | null;
  isCompanyLogo?: boolean;
  readOnly?: boolean;
}

export default function AvatarInput(props: AvatarInputProps) {
  const { className, onChange, onDelete, value, isCompanyLogo, readOnly = false } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  const handleEdit = useCallback(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  }, []);

  const displayFileRejectMessage = useCallback(
    (message: string) => {
      dispatch(
        actions.showNotification({
          message,
          appearance: "error",
        }),
      );
    },
    [dispatch],
  );

  const renderDefaultBlock = useMemo(() => {
    if (isCompanyLogo) {
      return (
        <>
          <div className="company-default-logo" />
          <div className="avatar-controls">
            <div className="avatar-edit" onClick={handleEdit}>
              <Icon type="edit-white" />
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div className="avatar-create-block" onClick={handleEdit}>
          <div className="avatar-create-label">+</div>
          <div className="avatar-create-label">Add Image</div>
        </div>
      );
    }
  }, [isCompanyLogo, handleEdit]);

  return (
    <div className={classnames("avatar-input-wrapper", className, { "read-only": readOnly })}>
      {value ? (
        <>
          <img src={value} alt="" />
          <div className="avatar-controls">
            <div className="avatar-delete" onClick={onDelete}>
              <Icon type="delete-white" />
            </div>
            <div className="avatar-edit" onClick={handleEdit}>
              <Icon type="edit-white" />
            </div>
          </div>
        </>
      ) : (
        renderDefaultBlock
      )}
      <InputFile
        maxFileSize={MAX_AVATAR_SIZE}
        onReject={displayFileRejectMessage}
        accept="image/png,image/jpg,image/jpeg"
        onChange={onChange}
        inputRef={inputRef}
      />
    </div>
  );
}
