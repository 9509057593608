export enum TagActionTypes {
    GET_TAGS = "@@TAG/GET_TAGS",
    GET_TAGS_SUCCESS = "@@TAG/GET_TAGS_SUCCESS",
    GET_TAGS_FAILURE = "@@TAG/GET_TAGS_FAILURE",
  
    GET_TAG = "@@TAG/GET_TAG",
    GET_TAG_SUCCESS = "@@TAG/GET_TAG_SUCCESS",
    GET_TAG_FAILURE = "@@TAG/GET_TAG_FAILURE",
  
    CREATE_TAG = "@@TAG/CREATE_TAG",
    CREATE_TAG_SUCCESS = "@@TAG/CREATE_TAG_SUCCESS",
    CREATE_TAG_FAILURE = "@@TAG/CREATE_TAG_FAILURE",
  
    DELETE_TAG = "@@TAG/DELETE_TAG",
    DELETE_TAG_SUCCESS = "@@TAG/DELETE_TAG_SUCCESS",
    DELETE_TAG_FAILURE = "@@TAG/DELETE_TAG_FAILURE",
  
    CLEAR_TAGS = "@@TAG/CLEAR_TAGS",
  }
  