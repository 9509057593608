import { PERMISSION } from "src/shared/constants/permission";

import { MenuItem } from "../interfaces";
import { NameOfRoutes } from "./routes";

import { NameOfChildRoutes } from "src/shared/constants";

export enum MENU_ITEMS_PART {
  ADMIN = "ADMIN",
  USER = "USER",
}

export const getMenuItems = (part: MENU_ITEMS_PART, isTeamMember?: boolean): MenuItem[] => {
  const MENU_ITEMS: { [MENU_ITEMS_PART.ADMIN]: MenuItem[]; [MENU_ITEMS_PART.USER]: MenuItem[] } = {
    [MENU_ITEMS_PART.ADMIN]: [
      {
        iconType: "users",
        name: "Admin Users",
        path: NameOfRoutes.USERS,
        permission: PERMISSION.VIEW_USERS,
      },
      {
        iconType: "settings",
        name: "Company Management",
        path: NameOfRoutes.COMPANIES,
        permission: PERMISSION.VIEW_COMPANIES,
      },
      {
        iconType: "activities",
        name: "Activities Library",
        path: NameOfRoutes.ACTIVITIES_LIBRARY,
        permission: PERMISSION.VIEW_SYSTEM_ACTIVITY_LIBRARY,
      },
      {
        iconType: "jsa",
        name: "JSA Library",
        path: NameOfRoutes.JSA_LIBRARY,
        permission: PERMISSION.VIEW_SYSTEM_JSA_LIBRARY,
      },
      {
        iconType: "tasks",
        name: "Tasks Library",
        path: NameOfRoutes.TASKS,
        permission: PERMISSION.VIEW_SYSTEM_TASK_LIBRARY,
      },
    ],
    [MENU_ITEMS_PART.USER]: [
      {
        iconType: "users",
        name: "Users",
        path: `${NameOfRoutes.COMPANIES}${NameOfChildRoutes.COMPANY.USERS}`,
        permission: PERMISSION.VIEW_USERS,
      },
      {
        iconType: "members",
        name: "Team Members",
        path: `${NameOfRoutes.COMPANIES}${NameOfChildRoutes.COMPANY.MEMBERS}`,
        permission: PERMISSION.VIEW_MEMBERS,
      },
      {
        iconType: "teams",
        name: "Teams",
        path: `${NameOfRoutes.COMPANIES}${NameOfChildRoutes.COMPANY.TEAMS}`,
        permission: PERMISSION.VIEW_COMPANY_TEAMS,
      },
      {
        iconType: "projects",
        name: "Projects",
        path: `${NameOfRoutes.COMPANIES}${NameOfChildRoutes.COMPANY.PROJECTS}`,
        permission: PERMISSION.VIEW_COMPANY_PROJECTS,
      },
      {
        iconType: "assignments",
        name: "Assignments",
        path: `${NameOfRoutes.COMPANIES}${NameOfChildRoutes.COMPANY.ASSIGNMENTS}`,
        permission: PERMISSION.VIEW_COMPANY_ASSIGNMENTS,
      },
      {
        iconType: "activities",
        name: "Activities",
        path: `${NameOfRoutes.COMPANIES}${NameOfChildRoutes.COMPANY.ACTIVITIES}`,
        permission: PERMISSION.VIEW_COMPANY_CREATED_ACTIVITY,
      },
      {
        iconType: "jsa",
        name: "JSA",
        path: `${NameOfRoutes.COMPANIES}${NameOfChildRoutes.COMPANY.JSA_LIST}`,
        permission: PERMISSION.VIEW_COMPANY_CREATED_JSA,
      },
      {
        iconType: "tasks",
        name: "Tasks",
        path: `${NameOfRoutes.COMPANIES}${NameOfChildRoutes.COMPANY.TASKS}`,
        permission: PERMISSION.VIEW_COMPANY_CREATED_TASK,
      },
      {
        iconType: "trainings-and-certifcates",
        name: isTeamMember ? "Trainings" : "Trainings & Certificates",
        path: `${NameOfRoutes.COMPANIES}${NameOfChildRoutes.COMPANY.TRAININGS_AND_CERTIFICATES}`,
        permission: PERMISSION.VIEW_COMPANY_TRAININGS,
      },
      {
        iconType: "documents",
        name: "Files & Links",
        path: `${NameOfRoutes.COMPANIES}${NameOfChildRoutes.COMPANY.DOCUMENTS}`,
        permission: PERMISSION.VIEW_COMPANY_DOCUMENTS_AND_LINKS,
      },
      {
        iconType: "alerts",
        name: "Alerts Management",
        path: `${NameOfRoutes.COMPANIES}${NameOfChildRoutes.COMPANY.ALERTS}`,
        permission: PERMISSION.VIEW_COMPANY_ALERTS,
      },
      {
        iconType: "settings",
        name: "Settings",
        path: `${NameOfRoutes.COMPANIES}${NameOfChildRoutes.COMPANY.SETTINGS}`,
        permission: PERMISSION.VIEW_COMPANY_SETTINGS,
      },
    ],
  };
  return MENU_ITEMS[part];
};
