import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";
import { defaultFilter } from "src/shared/constants";

import { CompanyStateType } from "../interface";
import * as actions from "./actions";

type Action = ActionType<typeof actions>;

export const initialState: CompanyStateType = {
  companies: [],
  companiesTotal: 0,
  currentCompany: null,
  editedCompany: null,
  filter: defaultFilter,
};

const reducer = createReducer<CompanyStateType, Action>(initialState)
  .handleAction(actions.getCompanies.success, (state, action) =>
    produce(state, (nextState) => {
      const { rows, count, clear } = action.payload;
      nextState.companies = clear ? rows : [...nextState.companies, ...rows];
      nextState.companiesTotal = count;
    }),
  )
  .handleAction(actions.getCompany.success, (state, action) =>
    produce(state, (nextState) => {
      const { payload } = action;
      nextState.currentCompany = payload;
    }),
  )
  .handleAction(actions.getCompanyToEdit.success, (state, action) =>
    produce(state, (nextState) => {
      const { payload } = action;
      nextState.editedCompany = payload;
    }),
  )
  .handleAction(actions.createCompany.success, (state, action) =>
    produce(state, (nextState) => {
      const { company } = action.payload;
      nextState.companies = [{ ...company, users_count: company.users_count || 0 }, ...nextState.companies];
      nextState.companiesTotal = nextState.companiesTotal + 1;
    }),
  )
  .handleAction(actions.updateCompany.success, (state, action) =>
    produce(state, (nextState) => {
      const { company } = action.payload;
      const index = nextState.companies.findIndex((c) => c.code === company.code);
      if (index >= 0) {
        nextState.companies[index] = { ...company, users_count: nextState.companies[index].users_count };
      }
    }),
  )
  .handleAction(actions.setFilter, (state, action) =>
    produce(state, (nextState) => {
      nextState.filter = action.payload || { ...defaultFilter };
    }),
  );

export { reducer as CompanyReducer };
