import React, { FC } from "react";
import { CertificateListItem } from "src/shared/models";
import { Table } from "src/shared/components";
import { SortTable } from "src/shared/interfaces";

import { certificateTableProperties } from "./tableHelpers";

export interface CertificateTableProps extends SortTable {
  certificates: CertificateListItem[];
  onLoadNextPage: () => void;
  onClickCertificate: (training: CertificateListItem) => void;
}

const CertificateTable: FC<CertificateTableProps> = (props) => {
  const { certificates: trainings, onLoadNextPage, onClickCertificate, sortOrder, sortBy, onChangeSorting } = props;

  return (
    <div className="certificates-table">
      <Table<CertificateListItem>
        onRowClick={onClickCertificate}
        onFinishScroll={onLoadNextPage}
        items={trainings}
        properties={certificateTableProperties}
        sortOrder={sortOrder}
        sortBy={sortBy}
        onChangeSorting={onChangeSorting}
      />
    </div>
  );
};

export default CertificateTable;
