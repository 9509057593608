import React, { FC, useCallback } from "react";
import { generatePath } from "react-router";
import { useHideOverlay, useTeamMember } from "src/shared/hooks";
import { NameOfRoutes, NameOfChildRoutes } from "src/shared/constants";
import { openInNewTab } from "src/shared/utils";
import { CertificateView } from "src/containers/TrainingAndCertificate/components";
import { useCertificateDataFetch } from "src/containers/TrainingAndCertificate/hooks";

export interface CertificateViewContainerProps {
  certificateId?: string | number;
  companyCode: string;
  memberId?: string | number;
  onEdit?: () => void;
}

const CertificateViewContainer: FC<CertificateViewContainerProps> = (props) => {
  const { handleOnClose } = useHideOverlay();
  const { certificateId, companyCode, memberId, onEdit } = props;
  const { isTeamMember } = useTeamMember(companyCode);
  const { certificate, certificateFiles } = useCertificateDataFetch({ certificateId, companyCode });

  const onClickMember = useCallback(() => {
    if (certificate) {
      openInNewTab(
        generatePath(`${NameOfRoutes.COMPANIES}${NameOfChildRoutes.COMPANY.MEMBERS_EDIT}?tab=2&cid=${certificate.id}`, {
          code: companyCode,
          id: String(certificate?.member.id),
        }),
      );
    }
  }, [companyCode, certificate]);

  return (
    <div className="certificate-view-container">
      {certificate && (
        <CertificateView
          isTeamMember={isTeamMember || Boolean(memberId)}
          certificate={certificate}
          onCancel={handleOnClose}
          onEdit={!isTeamMember ? onEdit : undefined}
          files={certificateFiles}
          onClickMember={onClickMember}
        />
      )}
    </div>
  );
};

export default CertificateViewContainer;
