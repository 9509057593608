import { TableProperty } from "src/shared/components";
import { getDateAfterPeriod, getUSFormattedDate } from "src/shared/utils";
import { CertificateListItem } from "src/shared/models";
import { unitOfTime } from "moment";

export const certificateTableProperties: TableProperty<CertificateListItem>[] = [
  {
    title: "Name",
    key: "name",
    get: (item) => item.name,
    sort: ["name"],
  },
  {
    title: "Team Member",
    key: "name",
    get: (item) => `${item.member_first_name} ${item.member_last_name}`,
    sort: ["member_name"],
  },
  {
    title: "Issued By",
    key: "issued_by",
    get: (item) => item.issued_by,
    sort: ["issued_by"],
  },
  {
    title: "Issued On",
    key: "issued_on",
    get: (item) => getUSFormattedDate(item.issued_on),
    sort: ["issued_on"],
  },
  {
    title: "Valid",
    key: "valid",
    sort: ["valid"],
    get: (item) => {
      const validDate = getDateAfterPeriod(
        item.issued_on,
        item.valid,
        item.valid_period.toLowerCase() as unitOfTime.Base,
      );
      return getUSFormattedDate(validDate);
    },
  },
];
