import React, { FC, ReactElement, useMemo } from "react";
import classNames from "classnames";
import { AccessControl, Button, BackButton, VerticalDivider, ArchiveButton, TextButton } from "src/shared/components";
import { IRightComplexFormHeader } from "src/containers/Task/components/TaskCreateEditContainer/RightComplexFormHeader/RightComplexFormHeader";

import "./index.scss";

export interface FormHeaderProps {
  title?: string;
  permission?: string;
  companyCode?: string | null;
  onCancel?: () => void;
  onDelete?: () => void;
  onSubmit?: () => void;
  onBack?: () => void;
  disabled?: boolean;
  submitText?: string | null;
  cancelText?: string;
  deleteText?: string;
  deleteClass?: string;
  submitClass?: string;
  rightComplexFormHeaderWithSubmit?: boolean;
  rightComplexFormHeader?: ReactElement<IRightComplexFormHeader>;
  leftComplexFormHeader?: ReactElement;
}

const FormHeader: FC<FormHeaderProps> = (props) => {
  const {
    title,
    onCancel,
    onDelete,
    onSubmit,
    onBack,
    disabled,
    submitText,
    cancelText = "Cancel",
    permission,
    companyCode,
    submitClass,
    deleteText,
    deleteClass,
    rightComplexFormHeaderWithSubmit,
    rightComplexFormHeader,
    leftComplexFormHeader,
  } = props;

  const submitButton = useMemo(
    () =>
      submitText && (
        <div className="form-header-submit">
          <Button type="submit" className={classNames(submitClass)} disabled={disabled} onClick={onSubmit}>
            {submitText}
          </Button>
        </div>
      ),
    [submitText, submitClass, disabled, onSubmit],
  );

  const optionPermission = useMemo(() => ({ companyCode, onlyAppAdmin: !companyCode }), [companyCode]);

  return (
    <div className="form-header">
      <div className="form-header-title">
        {onBack && <BackButton onClick={onBack} />}
        <div className="title">{title}</div>
        {leftComplexFormHeader}
      </div>
      <div className="form-header-buttons">
        <AccessControl permission={permission || ""} option={optionPermission}>
          {onDelete && (
            <>
              <ArchiveButton onClick={onDelete} text={deleteText} className={deleteClass} />
              <VerticalDivider />
            </>
          )}
        </AccessControl>
        {onCancel && (
          <div className="form-header-cancel">
            <TextButton text={cancelText} type="cancel" onClick={onCancel} />
          </div>
        )}
        <AccessControl permission={permission || ""} option={optionPermission}>
          {rightComplexFormHeaderWithSubmit ? (
            <>
              {rightComplexFormHeader}
              {submitButton}
            </>
          ) : (
            rightComplexFormHeader ?? submitButton
          )}
        </AccessControl>
      </div>
    </div>
  );
};

export default FormHeader;
