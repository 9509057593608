import { User } from "src/shared/models";
import { PaginatedResponse, ActionWithPayload, Filter, CodeWithId } from "src/shared/interfaces";
import { startLoading, stopLoading, hideOverlay } from "src/shared/store/actions";
import { createUser, updateUser, updateUserStatus, checkUserExists, getUser } from "src/containers/User/store/actions";
import {
  UserFormShape,
  CreateUserSuccess,
  UserFormUpdateShape,
  UpdateStatusShape,
  CheckUserExistsResponse,
  CheckUserExistsShape,
} from "src/containers/User/interface";
import { call, put, takeLatest } from "redux-saga/effects";

import api from "../api";
import { getUsers } from "./actions";

function* getUsersSaga({ payload }: ActionWithPayload<Filter>) {
  try {
    yield put(startLoading());
    const response: PaginatedResponse<User> = yield call(api.getUsers, payload);
    yield put(stopLoading());
    yield put(
      getUsers.success({
        ...response,
        clear: !payload.page,
      }),
    );
  } catch (error) {
    yield put(getUsers.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getUserSaga({ payload }: ActionWithPayload<Required<CodeWithId>>) {
  try {
    yield put(startLoading());
    const response: User = yield call(api.getUser, payload);
    yield put(stopLoading());
    yield put(getUser.success(response));
  } catch (error) {
    yield put(getUser.failure(error as Error));
    yield put(stopLoading());
  }
}

function* createUserSaga({ payload }: ActionWithPayload<UserFormShape>) {
  try {
    yield put(startLoading());
    const response: CreateUserSuccess = yield call(api.createUser, payload);
    yield put(stopLoading());
    yield put(createUser.success(response));
  } catch (error) {
    yield put(createUser.failure(error as Error));
    yield put(stopLoading());
  }
}

function* updateUserSaga({ payload }: ActionWithPayload<UserFormUpdateShape>) {
  try {
    yield put(startLoading());
    const response: CreateUserSuccess = yield call(api.updateUser, payload);
    yield put(stopLoading());
    yield put(updateUser.success(response));
  } catch (error) {
    yield put(updateUser.failure(error as Error));
    yield put(stopLoading());
  }
}

function* updateUserStatusSaga({ payload }: ActionWithPayload<UpdateStatusShape>) {
  try {
    yield put(startLoading());
    const response: CreateUserSuccess = yield call(api.updateUserStatus, payload);
    yield put(stopLoading());
    yield put(hideOverlay());
    yield put(updateUser.success(response));
  } catch (error) {
    yield put(updateUserStatus.failure(error as Error));
    yield put(stopLoading());
  }
}

function* checkUserExistsSaga({ payload }: ActionWithPayload<CheckUserExistsShape>) {
  try {
    yield put(startLoading());
    const response: CheckUserExistsResponse = yield call(api.checkUserExists, payload);
    payload.callback(response.exists);
    yield put(stopLoading());
  } catch (error) {
    yield put(stopLoading());
  }
}

function* userSagas() {
  yield takeLatest(getUsers.request, getUsersSaga);
  yield takeLatest(getUser.request, getUserSaga);
  yield takeLatest(createUser.request, createUserSaga);
  yield takeLatest(updateUser.request, updateUserSaga);
  yield takeLatest(updateUserStatus.request, updateUserStatusSaga);
  yield takeLatest(checkUserExists.request, checkUserExistsSaga);
}

export default userSagas;
