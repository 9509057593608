import React from "react";
import type { FC, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { generatePath } from "react-router";
import { useSelector } from "react-redux";
import { NameOfChildRoutes, NameOfRoutes } from "src/shared/constants";
import { getDefaultUrlForAppLevel, getUserCompanies, hasAdminRole, getDefaultUrlForCompanyLevel } from "src/shared/utils";
import { getUserDetails } from "src/shared/store/selectors";

import { authentificated } from "../../containers/Auth/store/selectors";

interface GuestGuardProps {
  children: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const isAuthenticated = useSelector(authentificated());
  const user = useSelector(getUserDetails());

  const userCompanies = getUserCompanies();
  const companyCode = userCompanies.find(Boolean);

  if (!user || !isAuthenticated || !userCompanies.length) {
    return <>{children}</>;
  }

  if (hasAdminRole(user)) {
    return <Navigate to={getDefaultUrlForAppLevel(user)} />;
  }

  if (user && companyCode && userCompanies.length === 1) {
    const route = getDefaultUrlForCompanyLevel(user, companyCode);
    return <Navigate to={generatePath(route ? route : NameOfRoutes.AUTH_LOGIN, { code: companyCode })} />;
  }

  return (
    <Navigate
      to={generatePath(`${NameOfRoutes.COMPANIES}${NameOfChildRoutes.COMPANY.SWITCH}`, {
        code: String(companyCode),
      })}
    />
  );
};

export default GuestGuard;
