export enum SharedActionTypes {
  NAVIGATE = "@src/shared/NAVIGATE",

  START_LOADING = "@src/shared/START_LOADING",
  STOP_LOADING = "@src/shared/STOP_LOADING",
  ADD_LOADING_TYPE = "@src/shared/ADD_LOADING_TYPE",
  REMOVE_LOADING_TYPE = "@src/shared/REMOVE_LOADING_TYPE",

  ADD_LOADING_SECTION = "@src/shared/ADD_LOADING_SECTION",
  REMOVE_LOADING_SECTION = "@src/shared/REMOVE_LOADING_SECTION",
  SHOW_NOTIFICATION = "@src/shared/SHOW_NOTIFICATION",

  GET_USER_DETAIL = "@src/shared/GET_USER_DETAIL",
  GET_USER_DETAIL_SUCCESS = "@src/shared/GET_USER_DETAIL_SUCCESS",
  GET_USER_DETAIL_FAILURE = "@src/shared/GET_USER_DETAIL_FAILURE",

  GET_ROLES = "@src/shared/GET_ROLES",
  GET_ROLES_SUCCESS = "@src/shared/GET_ROLES_SUCCESS",
  GET_ROLES_FAILURE = "@src/shared/GET_ROLES_FAILURE",

  SHOW_OVERLAY = "@src/shared/SHOW_OVERLAY",
  HIDE_OVERLAY = "@src/shared/HIDE_OVERLAY",

  SHOW_MODAL = "@src/shared/SHOW_MODAL",
  HIDE_MODAL = "@src/shared/HIDE_MODAL",

  GET_JOBS = "@src/shared/GET_JOBS",
  GET_JOBS_SUCCESS = "@src/shared/GET_JOBS_SUCCESS",
  GET_JOBS_FAILURE = "@src/shared/GET_JOBS_FAILURE",

  GET_INDUSTRIES = "@src/shared/GET_INDUSTRIES",
  GET_INDUSTRIES_SUCCESS = "@src/shared/GET_INDUSTRIES_SUCCESS",
  GET_INDUSTRIES_FAILURE = "@src/shared/GET_INDUSTRIES_FAILURE",

  GET_ADDRESS = "@src/shared/GET_ADDRESS",
  GET_ADDRESS_SUCCESS = "@src/shared/GET_ADDRESS_SUCCESS",
  GET_ADDRESS_FAILURE = "@src/shared/GET_ADDRESS_FAILURE",

  GET_LOCATION = "@src/shared/GET_LOCATION",
  GET_LOCATION_SUCCESS = "@src/shared/GET_LOCATION_SUCCESS",
  GET_LOCATION_FAILURE = "@src/shared/GET_LOCATION_FAILURE",

  GET_TEAMS = "@src/shared/GET_TEAMS",
  GET_TEAMS_SUCCESS = "@src/shared/GET_TEAMS_SUCCESS",
  GET_TEAMS_FAILURE = "@src/shared/GET_TEAMS_FAILURE",

  CREATE_TEAM = "@src/shared/CREATE_TEAM",
  CREATE_TEAM_SUCCESS = "@src/shared/CREATE_TEAM_SUCCESS",
  CREATE_TEAM_FAILURE = "@src/shared/CREATE_TEAM_FAILURE",

  SET_LAST_CREATED_TEAM = "@src/shared/SET_LAST_CREATED_TEAM",

  GET_JSA = "@src/shared/GET_JSA",
  GET_JSA_SUCCESS = "@src/shared/GET_JSA_SUCCESS",
  GET_JSA_FAILURE = "@src/shared/GET_JSA_FAILURE",

  CREATE_PROJECT = "@src/shared/CREATE_PROJECT",
  CREATE_PROJECT_SUCCESS = "@src/shared/CREATE_PROJECT_SUCCESS",
  CREATE_PROJECT_FAILURE = "@src/shared/CREATE_PROJECT_FAILURE",

  GET_CLIENTS = "@src/shared/GET_CLIENTS",
  GET_CLIENTS_SUCCESS = "@src/shared/GET_CLIENTS_SUCCESS",
  GET_CLIENTS_FAILURE = "@src/shared/GET_CLIENTS_FAILURE",
}
