import React, { useCallback, useMemo, useRef } from "react";
import { File as FileModel } from "src/shared/models";
import { actions } from "src/shared/store";
import { useDispatch } from "react-redux";
import { validateFile } from "src/shared/utils";
import classnames from "classnames";
import { CreateFileShape } from "src/shared/interfaces";
import { ACCEPT_FILE_TYPES } from "src/shared/constants";

import { FileList } from "./components";

import "./index.scss";
import { AccessControl } from '../AccessControl';
import { FormCard } from '../FormCard';

export interface FilesBlockViewProps {
  files: FileModel[];
  emptyFileText?: string | null;
  withoutContainer?: boolean;
  className?: string;
}
export interface FilesBlockProps extends FilesBlockViewProps {
  isDisabled?: boolean;
  onDeleteFile: (file: FileModel) => void;
  onCreateFile: (payload: CreateFileShape) => void;
  permission?: string;
  companyCode?: string | null;
  onReject: (errorMessage: string) => void;
  maxFileSize?: number;
  deprecatedText?: string;
  deprecatedClick?: boolean;
  acceptFileTypes?: string;
}

export const FilesBlockView = (props: FilesBlockViewProps) => {
  const { files, emptyFileText, withoutContainer, className } = props;

  const ContainerComponent = useMemo(() => {
    return withoutContainer ? "div" : FormCard;
  }, [withoutContainer]);

  return (
    <ContainerComponent className={classnames("files-block", className)}>
      <div className="files-header form-subtitle-wrapper">
        <div className="left-files-header form-subtitle-title">Files</div>
      </div>
      <div className="files-list">
        {files.length ? (
          <FileList files={files} isDisabled={true} />
        ) : (
          emptyFileText !== null && <div className="empty-list">{emptyFileText ?? "No files yet"}</div>
        )}
      </div>
    </ContainerComponent>
  );
};

const FilesBlock = (props: FilesBlockProps) => {
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    files,
    onDeleteFile,
    onCreateFile,
    permission,
    companyCode,
    maxFileSize,
    onReject,
    emptyFileText,
    deprecatedClick,
    deprecatedText,
    isDisabled = false,
    acceptFileTypes,
    withoutContainer,
    className,
  } = props;

  const deleteFile = useCallback(
    (file: FileModel) => {
      dispatch(
        actions.showModal({
          title: "You want to delete file",
          body: "Are you sure you want to delete this file?",
          confirmText: "Delete",
          onConfirm: () => onDeleteFile(file),
        }),
      );
    },
    [dispatch, onDeleteFile],
  );

  const handleFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target;
      const rawFile = files && files[0];
      const errorMessage = validateFile(rawFile, { maxSize: maxFileSize });
      if (errorMessage) {
        onReject(errorMessage);
        return;
      }

      const reader = new FileReader();
      const fileName = rawFile ? rawFile.name : undefined;

      if (fileName && rawFile) {
        reader.onload = (e) => {
          const file = e.target ? e.target.result : null;

          if (file) {
            onCreateFile({
              base64: String(file),
              name: fileName,
            });
          }
        };

        reader.readAsDataURL(rawFile);
      }

      e.target.value = "";
    },
    [onCreateFile, onReject, maxFileSize],
  );

  const handleAddFileClick = useCallback(() => {
    if (!deprecatedClick && inputRef && inputRef.current) {
      inputRef.current.click();
    } else if (deprecatedText) {
      onReject(deprecatedText);
      return;
    }
  }, [deprecatedClick, deprecatedText, onReject]);

  const ContainerComponent = useMemo(() => {
    return withoutContainer ? "div" : FormCard;
  }, [withoutContainer]);

  const optionPermission = useMemo(() => ({ companyCode, onlyAppAdmin: !companyCode }), [companyCode]);

  return (
    <ContainerComponent className={classnames("files-block", className)}>
      <div className="files-header form-subtitle-wrapper">
        <div className="left-files-header form-subtitle-title">Files</div>
        <AccessControl permission={permission || ""} option={optionPermission}>
          {!isDisabled ? (
            <div
              className={classnames("right-files-header form-subtitle-add", { disabled: deprecatedClick })}
              onClick={handleAddFileClick}>
              + Add File
            </div>
          ) : null}
          <input
            ref={inputRef}
            type="file"
            className="hidden"
            onChange={handleFileChange}
            accept={acceptFileTypes ? acceptFileTypes : ACCEPT_FILE_TYPES}
          />
        </AccessControl>
      </div>
      <div className="files-list">
        {files.length ? (
          <FileList
            onDelete={deleteFile}
            files={files}
            permission={permission}
            companyCode={companyCode}
            isDisabled={isDisabled}
          />
        ) : (
          <div className="empty-list">{emptyFileText ?? "No files yet"}</div>
        )}
      </div>
    </ContainerComponent>
  );
};

export default FilesBlock;
