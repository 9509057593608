import React, { FC } from "react";
import classnames from "classnames";
import { Icon } from "src/shared/components";
import { Location } from "src/shared/models";
import { getMapLocationLinkUrl } from "src/shared/utils";

import "./index.scss";

export interface LocationLabelProps {
  location: Location;
  isHref?: boolean;
  className?: string;
}

const LocationLabel: FC<LocationLabelProps> = (props) => {
  const { location, className, isHref = true } = props;

  return (
    <div className={classnames("location-label-wrapper", className)}>
      <a
        rel="noreferrer"
        href={isHref ? getMapLocationLinkUrl(location.place_id) : ""}
        target="_blank"
        className="location-label">
        <Icon className="location-label-icon" type="marker" />
        {location.address}
      </a>
    </div>
  );
};

export default LocationLabel;
