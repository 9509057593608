import React, { FC, useCallback } from "react";
import { AddSelectOption } from "src/shared/components";
import { AnyType } from "src/shared/interfaces";
import { useDebouncedFunction } from "src/shared/hooks";
import { components, MenuListProps } from "react-select";

import "./index.scss";

interface CustomMenuListProps {
  onAddClick?: () => void;
  baseProps: MenuListProps;
  addText?: string | React.ReactNode;
  onMenuScrollToBottom?: (event: AnyType) => void;
  hintText?: React.ReactNode;
  footerComponent?: React.ReactNode;
}

const CustomMenuList: FC<CustomMenuListProps> = (props) => {
  const { onAddClick, addText, baseProps, onMenuScrollToBottom, hintText, footerComponent } = props;
  const debouncedOnMenuScroll = useDebouncedFunction(onMenuScrollToBottom);

  const onScroll = useCallback(
    (event) => {
      const maxScroll = event.currentTarget.scrollHeight - event.currentTarget.clientHeight;
      const bottom = event.currentTarget.scrollTop >= maxScroll - 1;

      if (bottom && debouncedOnMenuScroll) {
        debouncedOnMenuScroll(event);
      }
    },
    [debouncedOnMenuScroll],
  );

  const refCallback = useCallback((instance: HTMLDivElement | null) => {
    if (instance) {
      instance.onscroll = null;
    }
  }, []);

  return (
    <div className="custom-select-menu-list">
      <components.MenuList {...baseProps} innerRef={refCallback}>
        <div className="custom-select-list" onScroll={onScroll}>
          {baseProps.children}
        </div>
        {footerComponent || <AddSelectOption hintText={hintText} onClick={onAddClick} buttonText={addText} />}
      </components.MenuList>
    </div>
  );
};

export default CustomMenuList;
