import { API, METHODS, SCHEMAS } from "src/shared/constants";
import { API_ENUM } from "src/shared/constants/api";
import { SchemasMap } from "src/shared/interfaces";
import { mapToSchema } from "src/shared/utils";
import {
  activateRequestSchema,
  activateResponseSchema,
  loginRequestSchema,
  loginResponseSchema,
  registrationRequestSchema,
  registrationResponseSchema,
  restorePasswordRequestSchema,
  restorePasswordResponseSchema,
  setPasswordRequestSchema,
  setPasswordResponseSchema,
} from "src/shared/validators";

const schemaShapes: SchemasMap<keyof typeof API.AUTH> = {
  [API_ENUM.LOGIN]: {
    request: {
      name: `${API.AUTH.LOGIN}${SCHEMAS.REQUEST}${METHODS.POST}`,
      schema: loginRequestSchema,
    },
    response: {
      name: `${API.AUTH.LOGIN}${SCHEMAS.RESPONSE}${METHODS.POST}`,
      schema: loginResponseSchema,
    },
  },
  [API_ENUM.FORGOT_PASSWORD]: {
    request: {
      name: `${API.AUTH.FORGOT_PASSWORD}${SCHEMAS.REQUEST}${METHODS.POST}`,
      schema: restorePasswordRequestSchema,
    },
    response: {
      name: `${API.AUTH.FORGOT_PASSWORD}${SCHEMAS.RESPONSE}${METHODS.POST}`,
      schema: restorePasswordResponseSchema,
    },
  },
  [API_ENUM.SET_PASSWORD]: {
    request: {
      name: `${API.AUTH.SET_PASSWORD}${SCHEMAS.REQUEST}${METHODS.POST}`,
      schema: setPasswordRequestSchema,
    },
    response: {
      name: `${API.AUTH.SET_PASSWORD}${SCHEMAS.RESPONSE}${METHODS.POST}`,
      schema: setPasswordResponseSchema,
    },
  },
  [API_ENUM.REGISTRATION]: {
    request: {
      name: `${API.AUTH.REGISTRATION}${SCHEMAS.REQUEST}${METHODS.POST}`,
      schema: registrationRequestSchema,
    },
    response: {
      name: `${API.AUTH.REGISTRATION}${SCHEMAS.RESPONSE}${METHODS.POST}`,
      schema: registrationResponseSchema,
    },
  },
  [API_ENUM.ACTIVATE]: {
    request: {
      name: `${API.AUTH.ACTIVATE}${SCHEMAS.REQUEST}${METHODS.POST}`,
      schema: activateRequestSchema,
    },
    response: {
      name: `${API.AUTH.ACTIVATE}${SCHEMAS.RESPONSE}${METHODS.POST}`,
      schema: activateResponseSchema,
    },
  },
};

export default mapToSchema(schemaShapes);
