import { generatePath } from "react-router";
import {
  PaginatedResponse,
  ActionWithPayload,
  CodeWithId,
  CreateFileSuccess,
  DeleteFileSuccess,
  CreateFileShape,
  BaseFilter,
} from "src/shared/interfaces";
import { navigate, startLoading, stopLoading } from "src/shared/store/actions";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  Certificate,
  CertificateListItem,
  File,
  Training,
  TrainingListItem,
  TrainingPass,
  CertificateIssuedByItem,
  CertificateTemplate,
} from "src/shared/models";
import { NameOfChildRoutes, NameOfRoutes } from "src/shared/constants";
import { addMemberCerificate, updateMemberCerificate } from "src/containers/Member/store/actions";

import api from "../api";
import {
  createTraining,
  getCertificates,
  getTraining,
  getTrainings,
  updateTraining,
  updateTrainingStatus,
  getTrainingPass,
  passTraining,
  confirmTraining,
  updateCertificate,
  createCertificate,
  getCertificate,
  updateCertificateStatus,
  getCertificateFiles,
  addCertificateFile,
  deleteCertificateFile,
  getCertificatesIssuedBy,
  getCertificatesTemplates,
  setClearTrainingForm,
} from "./actions";
import {
  CreateTrainingSuccess,
  TrainingAndCertificateFilter,
  TrainingFormShapeToRequest,
  UpdateTrainingStatusShape,
  TrainingPassFilter,
  TrainingPassPayload,
  TrainingPassResponse,
  CreateUpdateCertificateSuccess,
  CertificateFormShapeToRequest,
  UpdateCertificateStatusShape,
  DeleteCertificateFileShape,
  CertificateTemplatesFilter,
} from "../interface";

function* redirectToTrainings(companyCode?: string) {
  yield put(
    navigate(
      generatePath(`${NameOfRoutes.COMPANIES}${NameOfChildRoutes.COMPANY.TRAININGS_AND_CERTIFICATES}`, {
        code: companyCode,
      }),
    ),
  );
}

function* redirectToViewTraining(id: string, companyCode?: string) {
  yield put(
    navigate(
      generatePath(`${NameOfRoutes.COMPANIES}${NameOfChildRoutes.COMPANY.TRAINING_VIEW}`, {
        training_id: id,
        code: companyCode,
      }),
    ),
  );
}

function* getTrainingsSaga({ payload }: ActionWithPayload<TrainingAndCertificateFilter>) {
  try {
    yield put(startLoading());
    const response: PaginatedResponse<TrainingListItem> = yield call(api.getTrainings, payload);
    yield put(stopLoading());
    yield put(getTrainings.success({ ...response, clear: !payload.page }));
  } catch (error) {
    yield put(getTrainings.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getTrainingSaga({ payload }: ActionWithPayload<Required<CodeWithId>>) {
  yield put(startLoading());

  try {
    const trainingResponse: Training = yield call(api.getTraining, payload);
    yield put(getTraining.success(trainingResponse));
  } catch (error) {
    yield put(getTraining.failure(error as Error));
  }

  yield put(stopLoading());
}

function* createTrainingSaga({ payload }: ActionWithPayload<TrainingFormShapeToRequest>) {
  try {
    const { company_code } = payload;
    const { needRedirect, needReload, ...restPayload } = payload;
    yield put(startLoading());
    const response: CreateTrainingSuccess = yield call(api.createTraining, restPayload);
    yield put(stopLoading());
    yield put(createTraining.success(response));
    if (needRedirect && !needReload) {
      yield redirectToViewTraining(String(response.training.id), company_code);
    }
    if (needReload) {
      yield put(setClearTrainingForm(true));
    }
  } catch (error) {
    yield put(createTraining.failure(error as Error));
    yield put(stopLoading());
  }
}

function* updateTrainingSaga({ payload }: ActionWithPayload<Required<CodeWithId<TrainingFormShapeToRequest>>>) {
  try {
    yield put(startLoading());
    const response: CreateTrainingSuccess = yield call(api.updateTraining, payload);
    yield put(stopLoading());
    yield put(updateTraining.success(response));
  } catch (error) {
    yield put(updateTraining.failure(error as Error));
    yield put(stopLoading());
  }
}

function* updateTrainingStatusSaga({ payload }: ActionWithPayload<Required<CodeWithId<UpdateTrainingStatusShape>>>) {
  try {
    yield put(startLoading());
    const response: CreateTrainingSuccess = yield call(api.updateTrainingStatus, payload);
    yield put(stopLoading());
    yield put(updateTrainingStatus.success(response));
    yield redirectToTrainings(payload.code);
  } catch (error) {
    yield put(updateTrainingStatus.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getTrainingPassSaga({ payload }: ActionWithPayload<Required<CodeWithId<TrainingPassFilter>>>) {
  yield put(startLoading());

  try {
    const response: TrainingPass[] = yield call(api.getTrainingPass, payload);
    yield put(getTrainingPass.success(response));
  } catch (error) {
    yield put(getTrainingPass.failure(error as Error));
  }

  yield put(stopLoading());
}

function* passTrainingSaga({ payload }: ActionWithPayload<Required<CodeWithId<TrainingPassPayload>>>) {
  yield put(startLoading());

  try {
    const response: TrainingPassResponse = yield call(api.passTraining, payload);
    yield put(passTraining.success(response));
  } catch (error) {
    yield put(passTraining.failure(error as Error));
  }

  yield put(stopLoading());
}

function* confirmTrainingSaga({ payload }: ActionWithPayload<Required<CodeWithId<TrainingPassPayload>>>) {
  yield put(startLoading());

  try {
    const response: TrainingPassResponse = yield call(api.confirmTraining, payload);
    yield put(confirmTraining.success(response));
  } catch (error) {
    yield put(confirmTraining.failure(error as Error));
  }

  yield put(stopLoading());
}

function* getCertificatesSaga({ payload }: ActionWithPayload<TrainingAndCertificateFilter>) {
  try {
    yield put(startLoading());
    const response: PaginatedResponse<CertificateListItem> = yield call(api.getCertificates, payload);
    yield put(stopLoading());
    yield put(getCertificates.success({ ...response, clear: !payload.page }));
  } catch (error) {
    yield put(getCertificates.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getCertificatesTemplatesSaga({ payload }: ActionWithPayload<CertificateTemplatesFilter>) {
  try {
    yield put(startLoading());
    const { additional, ...restPayload } = payload;
    const response: PaginatedResponse<CertificateTemplate> = yield call(api.getCertificatesTemplates, restPayload);
    yield put(stopLoading());
    yield put(getCertificatesTemplates.success({ ...response, clear: !payload.page, additional }));
  } catch (error) {
    yield put(getCertificatesTemplates.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getCertificatesIssuedBySaga({ payload }: ActionWithPayload<BaseFilter>) {
  try {
    yield put(startLoading());
    const response: PaginatedResponse<CertificateIssuedByItem> = yield call(api.getCertificatesIssuedBy, payload);
    yield put(stopLoading());
    yield put(getCertificatesIssuedBy.success({ ...response, clear: !payload.page }));
  } catch (error) {
    yield put(getCertificates.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getCertificateSaga({ payload }: ActionWithPayload<Required<CodeWithId>>) {
  yield put(startLoading());

  try {
    const certificateResponse: Certificate = yield call(api.getCertificate, payload);
    yield put(getCertificate.success(certificateResponse));
  } catch (error) {
    yield put(getCertificate.failure(error as Error));
  }

  yield put(stopLoading());
}

function* createCertificateSaga({ payload }: ActionWithPayload<CodeWithId<CertificateFormShapeToRequest>>) {
  try {
    yield put(startLoading());
    const response: CreateUpdateCertificateSuccess = yield call(api.createCertificate, payload);
    yield put(stopLoading());
    yield put(createCertificate.success(response));
    yield put(addMemberCerificate(response.certificate));
  } catch (error) {
    yield put(createCertificate.failure(error as Error));
    yield put(stopLoading());
  }
}

function* updateCertificateSaga({ payload }: ActionWithPayload<Required<CodeWithId<CertificateFormShapeToRequest>>>) {
  try {
    yield put(startLoading());
    const response: CreateUpdateCertificateSuccess = yield call(api.updateCertificate, payload);
    yield put(stopLoading());
    yield put(updateCertificate.success(response));
    yield put(updateMemberCerificate(response.certificate));
  } catch (error) {
    yield put(updateCertificate.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getCertificateFilesSaga({ payload }: ActionWithPayload<Required<CodeWithId>>) {
  try {
    yield put(startLoading());
    const response: File[] = yield call(api.getCertificateFiles, payload);
    yield put(stopLoading());
    yield put(getCertificateFiles.success(response));
  } catch (error) {
    yield put(getCertificateFiles.failure(error as Error));
    yield put(stopLoading());
  }
}

function* addCertificateFileSaga({ payload }: ActionWithPayload<Required<CodeWithId<CreateFileShape>>>) {
  try {
    yield put(startLoading());
    const response: CreateFileSuccess = yield call(api.addCertificateFile, payload);
    yield put(stopLoading());
    yield put(addCertificateFile.success(response));
  } catch (error) {
    yield put(addCertificateFile.failure(error as Error));
    yield put(stopLoading());
  }
}

function* deleteCertificateFileSaga({ payload }: ActionWithPayload<DeleteCertificateFileShape>) {
  try {
    yield put(startLoading());
    const response: DeleteFileSuccess = yield call(api.deleteCertificateFile, payload);
    yield put(stopLoading());
    yield put(deleteCertificateFile.success(response));
  } catch (error) {
    yield put(deleteCertificateFile.failure(error as Error));
    yield put(stopLoading());
  }
}

function* updateCertificateStatusSaga({
  payload,
}: ActionWithPayload<Required<CodeWithId<UpdateCertificateStatusShape>>>) {
  try {
    yield put(startLoading());
    const response: CreateUpdateCertificateSuccess = yield call(api.updateCertificateStatus, payload);
    yield put(stopLoading());
    yield put(updateCertificateStatus.success(response));
    yield put(updateMemberCerificate(response.certificate));
  } catch (error) {
    yield put(updateCertificateStatus.failure(error as Error));
    yield put(stopLoading());
  }
}

function* trainingSagas() {
  yield takeLatest(getTrainings.request, getTrainingsSaga);
  yield takeLatest(getTraining.request, getTrainingSaga);
  yield takeLatest(createTraining.request, createTrainingSaga);
  yield takeLatest(updateTraining.request, updateTrainingSaga);
  yield takeLatest(updateTrainingStatus.request, updateTrainingStatusSaga);
  yield takeLatest(getCertificates.request, getCertificatesSaga);
  yield takeLatest(getCertificatesTemplates.request, getCertificatesTemplatesSaga);
  yield takeLatest(getCertificatesIssuedBy.request, getCertificatesIssuedBySaga);
  yield takeLatest(getCertificate.request, getCertificateSaga);
  yield takeLatest(createCertificate.request, createCertificateSaga);
  yield takeLatest(updateCertificate.request, updateCertificateSaga);
  yield takeLatest(getCertificateFiles.request, getCertificateFilesSaga);
  yield takeLatest(addCertificateFile.request, addCertificateFileSaga);
  yield takeLatest(deleteCertificateFile.request, deleteCertificateFileSaga);
  yield takeLatest(updateCertificateStatus.request, updateCertificateStatusSaga);
  yield takeLatest(getTrainingPass.request, getTrainingPassSaga);
  yield takeLatest(passTraining.request, passTrainingSaga);
  yield takeLatest(confirmTraining.request, confirmTrainingSaga);
}

export default trainingSagas;
