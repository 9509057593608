import { getPaginatedResponseSchema } from "./shared.schema";

const alertForListSchema = {
  type: "object",
  required: ["id", "text", "send_date", "is_everyone", "created_by", "members_count", "status"],
  properties: {
    id: { type: "number" },
    text: { type: "string" },
    send_date: { type: "string" },
    is_everyone: { type: "boolean" },
    created_by: { type: "string" },
    members_count: { type: "string" },
    status: { type: "string" },
    action: { type: ["string", "null"] },
  },
  additionalProperties: false,
};

export const alertSchema = {
  type: "object",
  required: ["id", "text", "send_date", "is_everyone", "created_by", "status"],
  properties: {
    id: { type: "number" },
    text: { type: "string" },
    send_date: { type: "string" },
    is_everyone: { type: "boolean" },
    created_by: { type: "string" },
    status: { type: "string" },

    members: { type: "array" },
    updated_by: { type: ["string", "null"] },
    action: { type: ["string", "null"] },
  },
  additionalProperties: false,
};

export const getAlertsResponseSchema = getPaginatedResponseSchema(alertForListSchema);

export const alertCreateUpdateRequestSchema = {
  type: "object",
  required: ["text", "send_date", "is_everyone"],
  properties: {
    text: { type: "string" },
    send_date: { type: "string" },
    is_everyone: { type: "boolean" },
    members: { type: "array", items: "number" },
    action: { type: ["string", "null"] },
  },
  additionalProperties: false,
};

export const alertResponseSchema = {
  type: "object",
  additionalProperties: false,
  required: ["message", "alert"],
  properties: {
    message: { type: "string" },
    notification: alertSchema,
  },
};

export const alertDeleteResponseSchema = {
  type: "object",
  additionalProperties: true,
  required: ["message", "alert_id"],
  properties: {
    message: { type: "string" },
    alert_id: { type: "number" },
  },
};
