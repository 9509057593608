import { API, METHODS, SCHEMAS } from "src/shared/constants";
import { API_ENUM } from "src/shared/constants/api";
import { SchemasMap } from "src/shared/interfaces";
import { mapToSchema, generateDynamicSchemaKeyNumeric, generateDynamicSchemaKeyString } from "src/shared/utils";
import {
  getUserDetailsResponseSchema,
  getRolesResponseSchema,
  getRolesRequestSchema,
  baseFilterSchema,
  getPaginatedResponseSchema,
  baseEntitySchema,
} from "src/shared/validators";

const schemaShapes: SchemasMap<keyof typeof API.SHARED> = {
  [API_ENUM.GET_USER_DETAILS]: {
    request: {
      name: `${API.SHARED.GET_USER_DETAILS}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: {},
    },
    response: {
      name: `${API.SHARED.GET_USER_DETAILS}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: getUserDetailsResponseSchema,
    },
  },
  [API_ENUM.GET_ROLES]: {
    request: {
      name: `${API.SHARED.GET_ROLES}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: getRolesRequestSchema,
    },
    response: {
      name: `${API.SHARED.GET_ROLES}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: getRolesResponseSchema,
    },
  },
  [API_ENUM.GET_JOBS]: {
    request: {
      name: `${API.SHARED.GET_JOBS}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: baseFilterSchema,
    },
    response: {
      name: `${API.SHARED.GET_JOBS}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: getPaginatedResponseSchema(baseEntitySchema),
    },
  },
  [API_ENUM.GET_INDUSTRIES]: {
    request: {
      name: `${API.SHARED.GET_INDUSTRIES}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: baseFilterSchema,
    },
    response: {
      name: `${API.SHARED.GET_INDUSTRIES}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: getPaginatedResponseSchema(baseEntitySchema),
    },
  },
  [API_ENUM.GET_ADDRESS]: {
    request: {
      name: `${API.SHARED.GET_ADDRESS}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: {},
    },
    response: {
      name: `${API.SHARED.GET_ADDRESS}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: {},
    },
  },
  [API_ENUM.GET_LOCATION]: {
    request: {
      name: `${generateDynamicSchemaKeyNumeric(API.SHARED.GET_LOCATION)}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: {},
    },
    response: {
      name: `${generateDynamicSchemaKeyNumeric(API.SHARED.GET_LOCATION)}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: {},
    },
  },
  [API_ENUM.GET_CLIENTS]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.SHARED.GET_CLIENTS("$id"))}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: baseFilterSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.SHARED.GET_CLIENTS("$id"))}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: getPaginatedResponseSchema(baseEntitySchema),
    },
  },
  [API_ENUM.UPLOAD_FILE]: {
    request: {
      name: `${API.SHARED.UPLOAD_FILE}${SCHEMAS.REQUEST}${METHODS.POST}`,
      schema: {},
    },
    response: {
      name: `${API.SHARED.UPLOAD_FILE}${SCHEMAS.RESPONSE}${METHODS.POST}`,
      schema: {},
    },
  },
};

export default mapToSchema(schemaShapes);
