import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

//import configureStore from "./store";
import configureAppStore from './store/store';

import App from "./containers/App/App";
import * as serviceWorker from "./serviceWorker";
import "./assets/styles/index.scss";
import { NamesOfParentRoutes } from './shared/constants';
import { AuthContainer } from './containers/Auth';
import { AuthGuard } from './shared/guards';
import ApplicationContainer from './containers/App/ApplicationContainer';


const { store } = configureAppStore();
const routes = [
  {    
    element: <App />,
    children: [ {
      path: `${NamesOfParentRoutes.AUTH}`,
      element: <AuthContainer />,
    },
    {
      path: `${NamesOfParentRoutes.AUTHORIZED}`,
      element: (
        <AuthGuard>
          <ApplicationContainer />
        </AuthGuard>
      ),
    },]
  },
 
];
const router = createBrowserRouter(routes);

ReactDOM.render(
  
    <Provider store={store}>
      <RouterProvider router={router} />      
    </Provider>
  ,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
