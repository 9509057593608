import { request } from "src/shared/utils";
import { API, METHODS } from "src/shared/constants";
import { Filter } from "src/shared/interfaces";

export default {
  getUserDetails: () => request(METHODS.GET, API.SHARED.GET_USER_DETAILS)(),
  getRoles: (payload: { is_app_admin: boolean }) => request(METHODS.GET, API.SHARED.GET_ROLES)({ params: payload }),
  getJobs: (payload: Partial<Filter>) => request(METHODS.GET, API.SHARED.GET_JOBS)({ params: payload }),
  getAddress: (payload: Partial<Filter>) => request(METHODS.GET, API.SHARED.GET_ADDRESS)({ params: payload }),
  getLocation: (place_id: number | string) => request(METHODS.GET, API.SHARED.GET_LOCATION)({ params: { place_id } }),
  getIndustries: (payload: Partial<Filter>) => request(METHODS.GET, API.SHARED.GET_INDUSTRIES)({ params: payload }),
  getClients: (payload: Partial<Filter>) => {
    const { company_code, ...clientFilter } = payload;
    return request(METHODS.GET, API.SHARED.GET_CLIENTS(company_code ?? ""))({ params: clientFilter });
  },
};
