import React, { FC, useEffect, lazy, useCallback, useMemo, useState } from "react";
import { generatePath } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { useRoutes, useLocation } from "react-router-dom";
import { NameOfRoutes, getMenuItems, MENU_ITEMS_PART, NameOfChildRoutes } from "src/shared/constants";
import { Sidebar, Loadable, LoadingIndicator } from "src/shared/components";
import { selectors as authSelectors, actions as authActions } from "src/containers/Auth/store";
import { selectors as companySelectors } from "src/containers/Company/store";
import { actions as sharedActions, selectors as sharedSelectors } from "src/shared/store";
import { useShowModal, useCompanySwitchHook, useTeamMember } from "src/shared/hooks";
import { AuthGuard } from "src/shared/guards";
import { getCompanyCodeFromLocationPath } from "src/shared/utils";
import classnames from "classnames";
const UserContainer = Loadable(lazy(() => import("../User/containers/UserContainer/UserContainer")));
const CompanyContainer = Loadable(lazy(() => import("../Company/containers/CompanyContainer/CompanyContainer")));
const ActivityContainer = Loadable(lazy(() => import("../Activity/containers/ActivityContainer/ActivityContainer")));
const TaskContainer = Loadable(lazy(() => import("../Task/containers/TaskContainer/TaskContainer")));
const JsaContainer = Loadable(lazy(() => import("../Jsa/containers/JsaContainer/JsaContainer")));

const routes = [
  {
    path: NameOfRoutes.USERS + "/*",
    element: (
      <AuthGuard onlyAppAdmin={true}>
        <UserContainer />
      </AuthGuard>
    ),
  },
  {
    path: NameOfRoutes.COMPANIES + "/*",
    element: (
      <AuthGuard>
        <CompanyContainer />
      </AuthGuard>
    ),
  },
  {
    path: NameOfRoutes.TASKS + "/*",
    element: (
      <AuthGuard>
        <TaskContainer />
      </AuthGuard>
    ),
  },
  {
    path: NameOfRoutes.ACTIVITIES_LIBRARY + "/*",
    element: (
      <AuthGuard>
        <ActivityContainer />
      </AuthGuard>
    ),
  },
  {
    path: NameOfRoutes.JSA_LIBRARY + "/*",
    element: (
      <AuthGuard>
        <JsaContainer />
      </AuthGuard>
    ),
  },
  {
    path: "/",
    element: <span />,
  },
];

const ApplicationContainer: FC = () => {
  const content = useRoutes(routes);
  const location = useLocation();
  const dispatch = useDispatch();
  const showModal = useShowModal();
  const user = useSelector(sharedSelectors.getUserDetails());
  const loading = useSelector(sharedSelectors.getLoading());
  const loadingSections = useSelector(sharedSelectors.getLoadingSections());
  const company = useSelector(companySelectors.getCurrentCompany());
  const isAuthentificated = useSelector(authSelectors.authentificated());

  useCompanySwitchHook();

  const isLoader = useMemo(() => loading || !!loadingSections.length, [loading, loadingSections]);
  const companyCode = useMemo(() => getCompanyCodeFromLocationPath(location.pathname), [location.pathname]);
  const authorizedMember = useSelector(sharedSelectors.getAuthorizedMemberByCompany(companyCode || undefined));
  const { isTeamMember } = useTeamMember(companyCode);

  const [collapsedSidebar, setCollapsedSidebar] = useState(false);

  useEffect(() => {
    if (!user && isAuthentificated) {
      dispatch(sharedActions.getUserDetails.request());
    }
  }, [dispatch, user, isAuthentificated]);

  const logout = useCallback(() => {
    showModal({
      title: "Logout",
      body: "Are you sure you want to logout?",
      confirmText: "Logout",
      onConfirm: () => dispatch(authActions.logout.request()),
    });
  }, [dispatch, showModal]);

  const onBackClick = useCallback(() => {
    dispatch(sharedActions.navigate(NameOfRoutes.COMPANIES));
  }, [dispatch]);

  const onSwitch = useCallback(() => {
    if (company) {
      dispatch(
        sharedActions.navigate(
          generatePath(`${NameOfRoutes.COMPANIES}${NameOfChildRoutes.COMPANY.SWITCH}`, { code: company.code }),
        ),
      );
    }
  }, [dispatch, company]);

  const selectedCompany = useMemo(() => {
    return location.pathname !== NameOfRoutes.COMPANIES ? company : null;
  }, [company, location]);

  return (
    <div className="app-container">
      {isLoader ? <LoadingIndicator /> : null}
      <Sidebar
        authorizedMember={authorizedMember}
        companyCode={companyCode}
        company={selectedCompany}
        items={companyCode ? getMenuItems(MENU_ITEMS_PART.USER, isTeamMember) : getMenuItems(MENU_ITEMS_PART.ADMIN)}
        activePath={location.pathname}
        user={user}
        onLogout={logout}
        onBackClick={onBackClick}
        onSwitch={onSwitch}
        onCollapse={setCollapsedSidebar}
      />
      <div className={classnames("app-content", { extended: !collapsedSidebar })}>{content}</div>
    </div>
  );
};

export default ApplicationContainer;
