import React from "react";
import { CertificateTemplate, MemberListItem } from "src/shared/models";
import { TableProperty } from "src/shared/components";
import { ExtraKeyType } from "src/shared/interfaces";

import { TeamMemberSelectorRow } from "../TeamMemberSelectorRow";

function isSelected(selectedMembers: ExtraKeyType<MemberListItem>, member: MemberListItem) {
  return Boolean(member.id) && Boolean(selectedMembers[member.id]);
}

export const getMemberTableProperties = (
  selectedMembers: ExtraKeyType<MemberListItem>,
  certificateTemplates?: (CertificateTemplate | null)[],
): TableProperty<MemberListItem>[] => {
  return [
    {
      title: "Name",
      key: "first_name",
      get: (item) => (
        <TeamMemberSelectorRow
          member={item}
          certificateTemplates={certificateTemplates}
          isSelected={isSelected(selectedMembers, item)}
        />
      ),
    },
  ];
};
