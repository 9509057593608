import { UserNotification } from "src/shared/models";
import { PaginatedResponse, ActionWithPayload, Filter, PayloadWithCode, CodeWithId } from "src/shared/interfaces";
import { startLoading, stopLoading } from "src/shared/store/actions";
import { call, put, takeLatest } from "redux-saga/effects";

import api from "../api";
import {
  getUserNotifications,
  getUserNotificationsUnreadCount,
  readUserNotification,
  readUserNotifications,
} from "./actions";
import {
  ReadUserNotificationResponse,
  ReadUserNotificationsResponse,
  UserNotificationUnreadCountResponse,
} from "../interface";

function* getUserNotificationsSaga({ payload }: ActionWithPayload<Filter>) {
  try {
    yield put(startLoading());
    const response: PaginatedResponse<UserNotification> = yield call(api.getUserNotifications, payload);
    yield put(
      getUserNotifications.success({
        ...response,
        clear: !payload.page,
      }),
    );
    yield put(stopLoading());
  } catch (error) {
    yield put(getUserNotifications.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getUserNotificationsUnreadCountSaga({ payload }: ActionWithPayload<PayloadWithCode>) {
  try {
    yield put(startLoading());
    const response: UserNotificationUnreadCountResponse = yield call(api.getUserNotificationsUnreadCount, payload);
    yield put(getUserNotificationsUnreadCount.success(response));
    yield put(stopLoading());
  } catch (error) {
    yield put(getUserNotificationsUnreadCount.failure(error as Error));
    yield put(stopLoading());
  }
}

function* readUserNotificationsSaga({ payload }: ActionWithPayload<PayloadWithCode>) {
  try {
    yield put(startLoading());
    const response: ReadUserNotificationsResponse = yield call(api.readUserNotifications, payload);
    yield put(stopLoading());
    yield put(readUserNotifications.success(response));
  } catch (error) {
    yield put(readUserNotifications.failure(error as Error));
    yield put(stopLoading());
  }
}

function* readUserNotificationSaga({ payload }: ActionWithPayload<Required<CodeWithId>>) {
  try {
    yield put(startLoading());
    const response: ReadUserNotificationResponse = yield call(api.readUserNotification, payload);
    yield put(stopLoading());
    yield put(readUserNotification.success(response));
  } catch (error) {
    yield put(readUserNotification.failure(error as Error));
    yield put(stopLoading());
  }
}

function* userNotificationSagas() {
  yield takeLatest(getUserNotifications.request, getUserNotificationsSaga);
  yield takeLatest(getUserNotificationsUnreadCount.request, getUserNotificationsUnreadCountSaga);
  yield takeLatest(readUserNotifications.request, readUserNotificationsSaga);
  yield takeLatest(readUserNotification.request, readUserNotificationSaga);
}

export default userNotificationSagas;
