import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";
import { defaultFilter } from "src/shared/constants";
import { ORDER_TYPE } from "src/shared/interfaces";

import { TagStateType } from "../interface";
import * as actions from "./actions";

type Action = ActionType<typeof actions>;

const tagDefaultFilter = {
  ...defaultFilter,
  sort_by: ["created_at"],
  sort_order: ORDER_TYPE.DESC,
};

export const initialState: TagStateType = {
  tags: [],
  currentTag: null,
  tagsTotal: 0,
  filter: { ...tagDefaultFilter },
  clearTagForm: false,
};

const reducer = createReducer<TagStateType, Action>(initialState)
  .handleAction(actions.getTags.success, (state, action) =>
    produce(state, (nextState) => {
      const { rows, count, clear } = action.payload;
      nextState.tags = clear ? rows : [...nextState.tags, ...rows];
      nextState.tagsTotal = count;
    }),
  )
  .handleAction(actions.getTag.success, (state, action) =>
    produce(state, (nextState) => {
      const { payload } = action;
      nextState.currentTag = payload;
    }),
  )
  .handleAction(actions.createTag.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.tags.push(action.payload.tag);
    }),
  )
  
export { reducer as TagReducer };
