import { createAction, createAsyncAction } from "typesafe-actions";
import { User, Role, Location, Team } from "src/shared/models";
import { CodeWithId, LoadingType } from "src/shared/interfaces";
import { CreateUpdateTeamSuccess, TeamFormShapeToRequest } from "src/containers/Team/interface";

import {
  Notification,
  Filter,
  GetRolesFilter,
  ModalProps,
  OverlayProps,
  PaginatedResponse,
  BaseEntity,
  Address,
} from "../interfaces";
import { SharedActionTypes } from "./constants";

export const startLoading = createAction(SharedActionTypes.START_LOADING)();
export const stopLoading = createAction(SharedActionTypes.STOP_LOADING)();
export const addLoadingType = createAction(SharedActionTypes.ADD_LOADING_TYPE)<LoadingType>();
export const removeLoadingType = createAction(SharedActionTypes.REMOVE_LOADING_TYPE)<string>();

export const addLoadingSection = createAction(SharedActionTypes.ADD_LOADING_SECTION)<{
  loadingSection: string;
  requestName: string;
}>();
export const removeLoadingSection = createAction(SharedActionTypes.REMOVE_LOADING_SECTION)<{
  loadingSection: string;
  requestName: string;
}>();

export const showNotification = createAction(SharedActionTypes.SHOW_NOTIFICATION)<Notification>();

export const navigate = createAction(SharedActionTypes.NAVIGATE)<string>();

export const getUserDetails = createAsyncAction(
  SharedActionTypes.GET_USER_DETAIL,
  SharedActionTypes.GET_USER_DETAIL_SUCCESS,
  SharedActionTypes.GET_USER_DETAIL_FAILURE,
)<undefined, User | null, Error>();

export const getRoles = createAsyncAction(
  SharedActionTypes.GET_ROLES,
  SharedActionTypes.GET_ROLES_SUCCESS,
  SharedActionTypes.GET_ROLES_FAILURE,
)<GetRolesFilter, Role[], Error>();

export const showOverlay = createAction(SharedActionTypes.SHOW_OVERLAY)<OverlayProps>();
export const hideOverlay = createAction(SharedActionTypes.HIDE_OVERLAY)();

export const showModal = createAction(SharedActionTypes.SHOW_MODAL)<ModalProps>();
export const hideModal = createAction(SharedActionTypes.HIDE_MODAL)();

export const getJobs = createAsyncAction(
  SharedActionTypes.GET_JOBS,
  SharedActionTypes.GET_JOBS_SUCCESS,
  SharedActionTypes.GET_JOBS_FAILURE,
)<Partial<Filter>, PaginatedResponse<BaseEntity>, Error>();

export const getIndustries = createAsyncAction(
  SharedActionTypes.GET_INDUSTRIES,
  SharedActionTypes.GET_INDUSTRIES_SUCCESS,
  SharedActionTypes.GET_INDUSTRIES_FAILURE,
)<Partial<Filter>, PaginatedResponse<BaseEntity>, Error>();

export const getAddress = createAsyncAction(
  SharedActionTypes.GET_ADDRESS,
  SharedActionTypes.GET_ADDRESS_SUCCESS,
  SharedActionTypes.GET_ADDRESS_FAILURE,
)<Partial<Filter>, Address[], Error>();

export const getLocation = createAsyncAction(
  SharedActionTypes.GET_LOCATION,
  SharedActionTypes.GET_LOCATION_SUCCESS,
  SharedActionTypes.GET_LOCATION_FAILURE,
)<string, { location: Location } | null, Error>();

export const getTeams = createAsyncAction(
  SharedActionTypes.GET_TEAMS,
  SharedActionTypes.GET_TEAMS_SUCCESS,
  SharedActionTypes.GET_TEAMS_FAILURE,
)<Partial<Filter>, PaginatedResponse<Team>, Error>();

export const createTeam = createAsyncAction(
  SharedActionTypes.CREATE_TEAM,
  SharedActionTypes.CREATE_TEAM_SUCCESS,
  SharedActionTypes.CREATE_TEAM_FAILURE,
)<CodeWithId<TeamFormShapeToRequest>, CreateUpdateTeamSuccess, Error>();

export const setLastCreatedTeam = createAction(SharedActionTypes.SET_LAST_CREATED_TEAM)<Team | null>();

export const getJsa = createAsyncAction(
  SharedActionTypes.GET_JSA,
  SharedActionTypes.GET_JSA_SUCCESS,
  SharedActionTypes.GET_JSA_FAILURE,
)<Partial<Filter>, PaginatedResponse<BaseEntity>, Error>();

export const getClients = createAsyncAction(
  SharedActionTypes.GET_CLIENTS,
  SharedActionTypes.GET_CLIENTS_SUCCESS,
  SharedActionTypes.GET_CLIENTS_FAILURE,
)<Partial<Filter>, PaginatedResponse<BaseEntity>, Error>();
