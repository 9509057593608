import { createSelector } from "reselect";
import { AppState } from "src/shared/interfaces/State.interface";

const selectAssignment = (state: AppState) => state.assignment;

export const getAssignments = () => createSelector(selectAssignment, (state) => state.assignments);
export const getAssignmentsTotal = () => createSelector(selectAssignment, (state) => state.assignmentsTotal);
export const getMembersAssignments = () => createSelector(selectAssignment, (state) => state.membersAssignments);
export const getMembersAssignmentsTotal = () =>
  createSelector(selectAssignment, (state) => state.membersAssignmentsTotal);
export const getFilter = () => createSelector(selectAssignment, (state) => state.filter);
export const getCurrentAssignment = () => createSelector(selectAssignment, (state) => state.currentAssignment);
export const getCurrentAssignmentCheckInOut = () =>
  createSelector(selectAssignment, (state) => state.currentAssignmentCheckInOut);
export const getCurrentAssignmentPassedJsa = () =>
  createSelector(selectAssignment, (state) => state.currentAssignmentPassedJsa);
export const getCurrentAssignmentJsa = () => createSelector(selectAssignment, (state) => state.currentAssignmentJsa);
export const getLastAssignmentView = () => createSelector(selectAssignment, (state) => state.lastAssignmentView);
export const getClickedCalendarDay = () => createSelector(selectAssignment, (state) => state.clickedCalendarDay);
export const getClearAssignmentForm = () => createSelector(selectAssignment, (state) => state.clearAssignmentForm);
