import { commonTaskProperties, formTaskProperties, getPaginatedResponseSchema } from "src/shared/validators";


const taskCreator = {
  type: "object",
  additionalProperties: false,
  required: ["id", "first_name", "last_name"],
  properties: {
    id: { type: "number" },
    first_name: { type: "string" },
    last_name: { type: "string" },
  },
};

const taskForListSchema = {
  type: "object",
  required: ["id", "index", "status", "created_at", "name"],
  properties: {
    creator: taskCreator,
    ...commonTaskProperties,
    ...formTaskProperties,
  },
  additionalProperties: false,
};

export const getTasksResponseSchema = getPaginatedResponseSchema(taskForListSchema);

const formProperties = {
  index: { type: "string" },
  name: { type: "string" },
  status: { type: "string" },
  steps: { type: ["string", "null"] },
  potential_hazards: { type: ["string", "null"] },
  steps_media: { type: ["array"] },
  potential_hazards_media: { type: ["array"] },
  hazard_control_proactive_measures_media: { type: ["array"] },
  hazard_control_proactive_measures: { type: ["string", "null"] },
  potential_risk: { type: ["string", "null"] },
  hazard_risk: { type: ["string", "null"] },
  parent_task_id: { type: ["number", "null"] },
  company_code: { type: ["string", "null"] },
};

export const createTaskRequestSchema = {
  type: "object",
  additionalProperties: false,
  required: ["index", "name", "status"],
  properties: formProperties,
};

export const createTaskResponseSchema = {
  type: "object",
  additionalProperties: false,
  required: ["task", "message"],
  properties: {
    message: { type: "string" },
    task: taskForListSchema,
  },
};

export const getTaskResponseSchema = {
  type: "object",
  required: ["id", "index", "name", "status", "created_at"],
  properties: {
    creator: taskCreator,
    ...commonTaskProperties,
    ...formTaskProperties,
  },
  additionalProperties: false,
};

export const updateTaskStatusRequestSchema = {
  type: "object",
  additionalProperties: false,
  required: ["status"],
  properties: {
    status: { type: "string" },
  },
};

export const importTaskRequestSchema = {
  type: "object",
  additionalProperties: false,
  required: ["company_code", "tasks"],
  properties: {
    company_code: { type: "string" },
    tasks: { type: "array" },
  },
};

export const importTaskResponseSchema = {
  type: "object",
  additionalProperties: false,
  required: ["message"],
  properties: {
    message: { type: "string" },
  },
};
