import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath } from "react-router";
import { UserNotification, UserNotificationAction } from "src/shared/models";
import { actions as sharedActions, selectors as sharedSelectors } from "src/shared/store";
import { useShowCertificateView } from "src/containers/TrainingAndCertificate/hooks";
import { NameOfChildRoutes, NameOfRoutes } from "src/shared/constants";

export const useUserNotificationAction = (companyCode?: string) => {
  const dispatch = useDispatch();

  const { showViewCertificateOverlay } = useShowCertificateView({ companyCode });
  const authorizedMember = useSelector(sharedSelectors.getAuthorizedMemberByCompany(companyCode || undefined));

  const notificationAction = useCallback(
    (notification: UserNotification) => {
      const navigate = (path: string) => dispatch(sharedActions.navigate(path));

      switch (notification.action) {
        case UserNotificationAction.OPEN_ASSIGNMENTS:
          navigate(
            generatePath(`${NameOfRoutes.COMPANIES}${NameOfChildRoutes.COMPANY.ASSIGNMENTS}`, {
              code: companyCode,
            }),
          );
          break;
        case UserNotificationAction.OPEN_TRAININGS:
          navigate(
            generatePath(`${NameOfRoutes.COMPANIES}${NameOfChildRoutes.COMPANY.TRAININGS_AND_CERTIFICATES}`, {
              code: companyCode,
            }),
          );
          break;
        case UserNotificationAction.OPEN_CERTIFICATES:
          navigate(
            authorizedMember && authorizedMember.id
              ? generatePath(`${NameOfRoutes.COMPANIES}${NameOfChildRoutes.COMPANY.MEMBERS_EDIT}?tab=2`, {
                  code: companyCode,
                  id: String(authorizedMember.id),
                })
              : generatePath(
                  `${NameOfRoutes.COMPANIES}${NameOfChildRoutes.COMPANY.TRAININGS_AND_CERTIFICATES}?tab=Certificates`,
                  {
                    code: companyCode,
                  },
                ),
          );
          break;
        case UserNotificationAction.OPEN_ASSIGNMENT:
          navigate(
            generatePath(`${NameOfRoutes.COMPANIES}${NameOfChildRoutes.COMPANY.ASSIGNMENT_VIEW}`, {
              id: String(notification.assignment_id),
              code: companyCode,
            }),
          );
          break;
        case UserNotificationAction.OPEN_ASSIGNMENT_MEMBER:
          navigate(
            generatePath(`${NameOfRoutes.COMPANIES}${NameOfChildRoutes.COMPANY.ASSIGNMENT_MEMBER_VIEW}`, {
              code: companyCode,
              id: String(notification.assignment_id),
              member_id: String(notification.member_id),
            }),
          );
          break;
        case UserNotificationAction.OPEN_TRAINING:
          navigate(
            generatePath(`${NameOfRoutes.COMPANIES}${NameOfChildRoutes.COMPANY.TRAINING_VIEW}`, {
              training_id: String(notification.training_id),
              code: companyCode,
            }),
          );
          break;
        case UserNotificationAction.OPEN_CERTIFICATE:
          notification.certificate_id && showViewCertificateOverlay(notification.certificate_id);
          break;
        default:
          break;
      }
    },
    [dispatch, companyCode, showViewCertificateOverlay, authorizedMember],
  );

  return { notificationAction };
};

export default useUserNotificationAction;
