import Ajv from "ajv";
import authSchema from "src/containers/Auth/api/schemas";
import userSchema from "src/containers/User/api/schemas";
import companySchema from "src/containers/Company/api/schemas";
import memberSchema from "src/containers/Member/api/schemas";
import activitySchema from "src/containers/Activity/api/schemas";
import taskSchema from "src/containers/Task/api/schemas";
import jsaSchema from "src/containers/Jsa/api/schemas";
import trainingAndCertificateSchema from "src/containers/TrainingAndCertificate/api/schemas";
import assignmentSchema from "src/containers/Assignment/api/schemas";
import documentSchema from "src/containers/Documents/api/schemas";
import teamSchema from "src/containers/Team/api/schemas";
import projectSchema from "src/containers/Project/api/schemas";
import sharedSchema from "src/shared/api/schemas";

const ajv = new Ajv({ schemaId: "auto" });

const allSchemas = {
  ...authSchema,
  ...userSchema,
  ...companySchema,
  ...memberSchema,
  ...activitySchema,
  ...taskSchema,
  ...jsaSchema,
  ...trainingAndCertificateSchema,
  ...assignmentSchema,
  ...documentSchema,
  ...teamSchema,
  ...projectSchema,
  ...sharedSchema,
};

const compiledSchemas = Object.entries(allSchemas).reduce(
  (acc, [key, value]) => {
    acc[key] = ajv.compile(value);
    return acc;
  },
  {} as { [key: string]: Ajv.ValidateFunction },
);

export default compiledSchemas;
