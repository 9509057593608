import React, { FC, useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useParams, useBlocker } from "react-router";
import { selectors, actions } from "src/containers/TrainingAndCertificate/store";
import { actions as projectActions } from "src/containers/Project/store";
import { ContainerWithCompanyCode } from "src/shared/interfaces";
import { useLeaveModalData, useShowModal } from "src/shared/hooks";
import { actions as sharedActions } from "src/shared/store";
import { TrainingForm } from "src/containers/TrainingAndCertificate/components";
import { NameOfChildRoutes, NameOfRoutes } from "src/shared/constants";
import { TrainingFormShapeToRequest } from "src/containers/TrainingAndCertificate/interface";
import { TrainingStatus } from "src/shared/models";

import "./index.scss";

export enum TypeTraining {
  SAVE_AS_DRAFT = "Save as Draft",
  CREATE_TRAINING = "Create Training",
  CREATE_AND_DUPLICATE = "Create and duplicate",
  CREATE_AND_NEW = "Publish and New",
}

const TrainingCreateEditContainer: FC<ContainerWithCompanyCode> = (props) => {
  const { companyCode } = props;
  const dispatch = useDispatch();
  const [formWasChanged, setFormWasChanged] = useState(false);
  const [typeTraining, setTypeTraining] = useState(TypeTraining.CREATE_TRAINING);
  const leaveModalData = useLeaveModalData();
  const showModal = useShowModal();
  const { training_id } = useParams();
  const currentTraining = useSelector(selectors.getCurrentTraining());
  const trainingPass = useSelector(selectors.getTrainingPass());

  useEffect(() => {
    if (training_id && companyCode) {
      dispatch(
        actions.getTraining.request({
          id: training_id,
          code: companyCode,
          payload: null,
        }),
      );
      dispatch(
        actions.getTrainingPass.request({
          id: training_id,
          code: companyCode,
          payload: { member_id: undefined },
        }),
      );
    }
  }, [dispatch, training_id, companyCode]);

  useEffect(() => {
    return () => {
      dispatch(actions.getTraining.success(null));
      dispatch(actions.getTrainingPass.success([]));
    };
  }, [dispatch]);

  const onCancel = useCallback(() => {
    if (companyCode) {
      dispatch(
        sharedActions.navigate(
          currentTraining && currentTraining.id !== undefined
            ? generatePath(`${NameOfRoutes.COMPANIES}${NameOfChildRoutes.COMPANY.TRAINING_VIEW}`, {
                code: companyCode,
                training_id: String(currentTraining.id),
              })
            : generatePath(`${NameOfRoutes.COMPANIES}${NameOfChildRoutes.COMPANY.TRAININGS_AND_CERTIFICATES}`, {
                code: companyCode,
              }),
        ),
      );
    }
  }, [dispatch, companyCode, currentTraining]);

  const onUpdateStatus = useCallback(
    (status: TrainingStatus) => {
      if (currentTraining) {
        showModal({
          body: `Please note you will not be able to restore the Training later. Are you sure you want to archive ${currentTraining.name}?`,
          confirmText: "Yes",
          title: "Archive Training",
          closeOnOutside: true,
          onConfirm: () => {
            if (currentTraining.id && companyCode) {
              dispatch(
                actions.updateTrainingStatus.request({
                  id: currentTraining.id,
                  code: companyCode,
                  payload: { status },
                }),
              );
            }
          },
        });
      }
    },
    [showModal, dispatch, currentTraining, companyCode],
  );

  const onSubmit = useCallback(
    (values: TrainingFormShapeToRequest) => {
      setFormWasChanged(false);
      if (companyCode) {
        if (training_id) {
          dispatch(
            actions.updateTraining.request({
              id: training_id,
              code: companyCode,
              payload: {
                ...values,
                company_code: undefined,
                status: typeTraining === TypeTraining.SAVE_AS_DRAFT ? TrainingStatus.Draft : TrainingStatus.Active,
              },
            }),
          );
        } else {
          dispatch(
            actions.createTraining.request({
              ...values,
              needRedirect: typeTraining !== TypeTraining.CREATE_AND_DUPLICATE,
              needReload: typeTraining === TypeTraining.CREATE_AND_NEW,
              status: typeTraining === TypeTraining.SAVE_AS_DRAFT ? TrainingStatus.Draft : TrainingStatus.Active,
            }),
          );
        }
      }
      dispatch(projectActions.clearCurrentProject());
    },
    [dispatch, companyCode, typeTraining, training_id],
  );


  useBlocker(({ currentLocation, nextLocation }) => {
    if (formWasChanged) {
      console.log(currentLocation, nextLocation);
      showModal({
        ...leaveModalData,
        onConfirm: () => {
          setFormWasChanged(false);
          dispatch(sharedActions.navigate(nextLocation.pathname));
        },
      });
      return true;
    } else {
      return false;
    }
  });

  return (
    <div className="training-create-container">
      <TrainingForm
        trainingPass={trainingPass}
        training={currentTraining}
        onCancel={onCancel}
        onChangeForm={() => {
          setFormWasChanged(true);
        }}
        submitHandler={onSubmit}
        company_code={companyCode}
        setTypeTraining={setTypeTraining}
        onUpdateStatus={onUpdateStatus}
      />
    </div>
  );
};

export default TrainingCreateEditContainer;
