import React, { FC, forwardRef, useEffect, useMemo } from "react";
import classnames from "classnames";
import moment from "moment";
import DatePickerOrigin, { DatePickerProps } from "rsuite/DatePicker";
import { RsRefForwardingComponent } from "rsuite/esm/internals/types";

import "./rsuite.css";
import "./index.scss";
//import DatePicker from 'rsuite/DatePicker';

interface ModifiedDatePickerProps extends Omit<DatePickerProps, "value"> {
  value?: Date | null;
}

const DatePicker = DatePickerOrigin as RsRefForwardingComponent<"div", ModifiedDatePickerProps>;

interface CustomDatePickerProps {
  className?: string;
  label?: string;
  placeholder?: string;
  onChange: (value: moment.Moment | null) => void;
  value?: moment.Moment | null;
  disablePast?: boolean;
  disabledDate?: (date?: Date) => boolean;
  calendarDefaultDate?: Date;
  disabled?: boolean;
  view?: "date" | "month" | "time";
  format?: string;
  showMeridian?: boolean;
  cleanable?: boolean;
}

const CustomDatePicker: FC<CustomDatePickerProps> = (props) => {
  const {
    className,
    label,
    onChange,
    value,
    disabled,
    cleanable,
    view = "date",
    format = "HH:mm aa",
    showMeridian = true,
    disablePast,
    disabledDate,
    placeholder,
    calendarDefaultDate,
  } = props;

  const pickerProperties: ModifiedDatePickerProps = useMemo(() => {
    const propObject: ModifiedDatePickerProps = {
      disabled,
      cleanable,
      value: value ? value.toDate() : null,
      onChange: (date) => onChange(date ? moment(date) : null),
      ranges: [],
      className: "custom-datepicker-wrapper",
      disabledDate: disabledDate || (disablePast ? (date) => moment(date) < moment().subtract(1, "day") : undefined),
      placeholder,
      calendarDefaultDate,
    };

    switch (view) {
      case "date":
        propObject.menuClassName = "custom-datepicker-menu-date";
        propObject.format = "MM/dd/yyyy";
        propObject.onClean = () => {
          onChange(null);
        };
        break;
      case "month":
        propObject.menuClassName = "custom-datepicker-menu-month";
        propObject.format = "MMM, yyyy";
        propObject.size = "lg";
        break;
      case "time":
        propObject.menuClassName = "custom-datepicker-menu-time";
        propObject.format = format;
        propObject.showMeridian = showMeridian;
        propObject.placeholder = "00:00";
        propObject.onClean = () => {
          onChange(null);
        };
    }
    return propObject;
  }, [
    value,
    onChange,
    disabled,
    view,
    format,
    showMeridian,
    cleanable,
    disablePast,
    placeholder,
    disabledDate,
    calendarDefaultDate,
  ]);

  return (
    <div className={classnames("custom-datepicker input-wrapper", className, { disabled })}>
      {label && <label className="custom-input-label">{label}</label>}
      <DatePicker
        disabled={pickerProperties.disabled}
        cleanable={pickerProperties.cleanable}
        value={pickerProperties.value}
        onChange={pickerProperties.onChange}
        ranges={pickerProperties.ranges}
        className={pickerProperties.className}
        disabledDate={pickerProperties.disabledDate}
        placeholder={pickerProperties.placeholder}
        calendarDefaultDate={pickerProperties.calendarDefaultDate}
        menuClassName={pickerProperties.menuClassName}
        format={pickerProperties.format}
        size={pickerProperties.size}
        showMeridian={pickerProperties.showMeridian}
        onClean={pickerProperties.onClean}
      />
    </div>
  );
};

export default CustomDatePicker;
