import React, { useState, FC } from "react";
import { Formik } from "formik";
import { RegistrationShape } from "src/containers/Auth/interface/AuthForm.interface";
import { FormProps } from "src/shared/interfaces";
import { FormGenerator, Button } from "src/shared/components";
import { User } from "src/shared/models";

import { validationSchema, fields, getInitValues } from "./formHelpers";

import "./index.scss";

export interface RegistrationFormProps {
  user: Partial<User>;
}

const RegistrationForm: FC<FormProps<RegistrationShape> & RegistrationFormProps> = (props) => {
  const [formValues] = useState(getInitValues(props.user));

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        props.submitHandler && props.submitHandler(values);
        setSubmitting(false);
      }}
      validateOnMount={true}
      validateOnChange={true}
      initialValues={formValues}>
      {(formikProps) => (
        <>
          <FormGenerator fields={fields} formikProps={formikProps}>
            <Button type="submit" disabled={props.loading || !validationSchema.isValidSync(formikProps.values)}>
              Sign Up
            </Button>
          </FormGenerator>
        </>
      )}
    </Formik>
  );
};

export default RegistrationForm;
