import React, { FC, useCallback } from "react";
import { useDispatch } from "react-redux";
import { actions } from "src/containers/TrainingAndCertificate/store";
import { useOverlay, useShowOverlay, useHideOverlay, useShowModal } from "src/shared/hooks";
import { hideOverlay } from "src/shared/store/actions";
import { CertificateForm } from "src/containers/TrainingAndCertificate/components/CertificateCreateEditContainer";
import { CertificateFormShape, CertificateFormShapeToRequest } from "src/containers/TrainingAndCertificate/interface";
import { CertificateStatus, File } from "src/shared/models";
import { optionToValue } from "src/shared/utils";
import { CreateFileShape } from "src/shared/interfaces";
import { useCertificateDataFetch } from "src/containers/TrainingAndCertificate/hooks";

import "./index.scss";

export interface CertificateCreateEditContainerProps {
  certificateId?: string | number;
  memberId?: number;
  companyCode: string;
}

const CertificateCreateEditContainer: FC<CertificateCreateEditContainerProps> = (props) => {
  const dispatch = useDispatch();
  const { overlay } = useOverlay();
  const showModal = useShowModal();
  const showOverlay = useShowOverlay();
  const { handleOnClose } = useHideOverlay();
  const { companyCode, memberId, certificateId } = props;
  const { certificate, certificateFiles } = useCertificateDataFetch({ certificateId, companyCode });

  const onSubmit = useCallback(
    (formData: CertificateFormShape) => {
      if (certificate && certificate.id) {
        dispatch(
          actions.updateCertificate.request({
            id: certificate.id,
            code: companyCode,
            payload: convertFormData(formData),
          }),
        );
      } else {
        dispatch(
          actions.createCertificate.request({
            code: companyCode,
            payload: convertFormData(formData, memberId),
          }),
        );
      }
      dispatch(hideOverlay());
    },
    [dispatch, certificate, companyCode, memberId],
  );

  const onChangeForm = useCallback(() => {
    showOverlay({
      ...overlay,
      leaveConfirm: true,
    });
  }, [showOverlay, overlay]);

  const onArchive = useCallback(() => {
    if (!certificate || !certificate.id) return;

    showModal({
      body: `Please note you will not be able to restore the Certificate later. Are you sure you want to archive ${certificate.name}?`,
      title: "Archive Certificate",
      onConfirm: () => {
        dispatch(
          actions.updateCertificateStatus.request({
            id: certificate.id,
            code: companyCode,
            payload: { status: CertificateStatus.Archived },
          }),
        );
        dispatch(hideOverlay());
      },
    });
  }, [dispatch, showModal, certificate, companyCode]);

  const createFile = useCallback(
    (payload: CreateFileShape) => {
      if (!certificate || !certificate.id) return;

      dispatch(
        actions.addCertificateFile.request({
          id: certificate.id,
          code: companyCode,
          payload,
        }),
      );
    },
    [dispatch, certificate, companyCode],
  );

  const deleteFile = useCallback(
    (file: File) => {
      if (!certificate || !certificate.id) return;

      dispatch(
        actions.deleteCertificateFile.request({
          code: companyCode,
          certificate_id: certificate.id,
          id: file.id,
        }),
      );
    },
    [dispatch, certificate, companyCode],
  );

  return (
    <div className="certificate-create-container">
      <CertificateForm
        files={certificateFiles}
        onCreateFile={createFile}
        onDeleteFile={deleteFile}
        onArchive={onArchive}
        onCancel={handleOnClose}
        submitHandler={onSubmit}
        certificate={certificate}
        companyCode={companyCode}
        onChangeForm={onChangeForm}
      />
    </div>
  );
};

export const convertFormData = (formData: CertificateFormShape, memberId?: number): CertificateFormShapeToRequest => {
  const convertedFormData: CertificateFormShapeToRequest = {
    ...formData,
    activities: formData.activities?.map((opt) => optionToValue<string>(opt)) || [],
    projects: formData.projects?.map((opt) => optionToValue<string>(opt)) || [],
  };

  if (memberId) {
    convertedFormData.member_id = memberId;
  }

  return convertedFormData;
};

export default CertificateCreateEditContainer;
