import { Moment } from "moment";
import {
  MemberWithAssignmentsInfoListItem,
  AssignmentListItem,
  Assignment,
  AssignmentCheckInOutState,
  AssignmentPassedJsa,
  AssignmentUnitedJsa,
} from "src/shared/models";

import { AssignmentFilter } from "./AssignmentFilter.interface";

export enum LAST_ASSIGNMENT_VIEW {
  calendar = "calendar",
  list = "list",
}

export interface AssignmentStateType {
  membersAssignments: MemberWithAssignmentsInfoListItem[];
  membersAssignmentsTotal: number;
  assignments: AssignmentListItem[];
  assignmentsTotal: number;
  filter: AssignmentFilter;
  currentAssignment: Assignment | null;
  currentAssignmentCheckInOut: AssignmentCheckInOutState[];
  currentAssignmentPassedJsa: AssignmentPassedJsa[];
  currentAssignmentJsa: AssignmentUnitedJsa[];
  lastAssignmentView: LAST_ASSIGNMENT_VIEW;
  clickedCalendarDay: Moment | null;
  clearAssignmentForm: boolean;
}
