import { FieldItem } from "src/shared/components/FormGenerator";
import { FORM_ERROR_MESSAGES } from "src/shared/constants";
import { FieldItemType } from "src/shared/interfaces";
import * as Yup from "yup";

import { NoteFormShape } from "../../interfaces";

export const validationSchema: Yup.SchemaOf<NoteFormShape> = Yup.object().shape({
  description: Yup.string().required(FORM_ERROR_MESSAGES.REQUIRED).max(500, `Note ${FORM_ERROR_MESSAGES.LONG}`),
});

export const fields: FieldItem[] = [
  {
    type: FieldItemType.TEXTAREA,
    name: "description",
    placeholder: "Type message here",
    label: "",
    wrapperClass: "input-line",
  },
];

export const initValues: NoteFormShape = {
  description: "",
};
