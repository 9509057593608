import React, { FC } from "react";
import { Button } from "src/shared/components";

import "./index.scss";

export interface AddButtonProps {
  onClick?: () => void;
}

const AddButton: FC<AddButtonProps> = (props) => {
  const { onClick } = props;

  return (
    <Button type="button" className="add-button" onClick={onClick}>
      +
    </Button>
  );
};

export default AddButton;
