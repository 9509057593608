import { createSelector } from "reselect";
import { AppState } from "src/shared/interfaces/State.interface";

const selectTasks = (state: AppState) => state.task;

export const getTasks = () => createSelector(selectTasks, (state) => state.tasks);
export const getTask = () => createSelector(selectTasks, (state) => state.currentTask);
export const getTaskFiles = () => createSelector(selectTasks, (state) => state.currentTaskFiles);
export const getTasksTotal = () => createSelector(selectTasks, (state) => state.tasksTotal);
export const getFilter = () => createSelector(selectTasks, (state) => state.filter);
export const getClearTaskForm = () => createSelector(selectTasks, (state) => state.clearTaskForm);