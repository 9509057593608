import { locationProjectSchema } from "src/shared/validators/location.schema";
import { commonCertificateTemplateSchema } from "src/shared/validators/certificate.schema";

export const projectSchema = {
  type: "object",
  required: ["id", "name", "created_at", "created_by", "status"],
  properties: {
    id: { type: "number" },
    name: { type: "string" },
    client: {
      type: ["object", "null"],
      required: ["id", "name"],
      properties: {
        id: { type: "number" },
        name: { type: "string" },
      },
      additionalProperties: false,
    },
    number: { type: ["string", "null"] },
    created_by: { type: ["string", "null"] },
    created_at: { type: ["string"] },
    status: { type: ["string"] },
    location: locationProjectSchema,
    certificate_templates: { type: "array", items: [commonCertificateTemplateSchema] },
  },
  additionalProperties: false,
};

export const createUpdateProjectRequestSchema = {
  type: "object",
  required: ["name", "location_place_id"],
  additionalProperties: false,
  properties: {
    name: { type: "string" },
    client_name: { type: "string" },
    number: { type: ["string", "null"] },
    location_place_id: { type: "string" },
    certificate_templates: { type: ["array", "null"] },
  },
};

export const createUpdateProjectResponseSchema = {
  type: "object",
  additionalProperties: false,
  required: ["message", "project"],
  properties: {
    message: { type: "string" },
    project: projectSchema,
  },
};

export const projectDeleteResponseSchema = {
  type: "object",
  additionalProperties: true,
  required: ["message", "project_id"],
  properties: {
    message: { type: "string" },
    project_id: { type: "number" },
  },
};
