import React, { FC, useMemo, useState, useEffect } from "react";
import { FormCard, LocationLabel, LabelArray } from "src/shared/components";
import { TrainingPassMember, Training, TrainingPass } from "src/shared/models";
import { ExtraKeyType } from "src/shared/interfaces";
import { TrainingTeamMemberCard, TrainingViewCertificate } from "src/containers/TrainingAndCertificate/components";
import { useTrainingExtraInfo } from "src/containers/TrainingAndCertificate/hooks";

import "./index.scss";

export interface TrainingViewProps {
  training: Training;
  trainingPass: TrainingPass[];
  onMemberClick: (member: TrainingPassMember) => void;
  onPassClick: (member: TrainingPassMember) => void;
  onConfirmClick: (member: TrainingPassMember) => void;
}

const TrainingView: FC<TrainingViewProps> = (props) => {
  const { training, onMemberClick, onPassClick, onConfirmClick, trainingPass = [] } = props;
  const [trainingPassObject, setTrainingPassObject] = useState<ExtraKeyType<TrainingPass>>({});

  const { updateInfo, recurringInfo } = useTrainingExtraInfo(training);

  useEffect(() => {
    if (trainingPass) {
      setTrainingPassObject(
        trainingPass.reduce((acc: ExtraKeyType<TrainingPass>, item) => {
          acc[item.member_id] = item;
          return acc;
        }, {}),
      );
    }
  }, [trainingPass]);

  const trainingDescription = useMemo(() => {
    if (!training.description) return null;

    return (
      <div
        className="form-card-row training-view-description"
        dangerouslySetInnerHTML={{ __html: training.description }}
      />
    );
  }, [training]);

  const trainingRecurring = useMemo(() => {
    if (!recurringInfo) {
      return null;
    }
    return <div className="form-card-row training-recurring">{recurringInfo}</div>;
  }, [recurringInfo]);

  const trainingUpdate = useMemo(() => {
    return <div className="form-card-row training-view-update-info">{updateInfo}</div>;
  }, [updateInfo]);

  const activitiesList = useMemo(() => {
    return training.activities.length ? (
      <LabelArray className="training-label-array" items={training.activities} title="Activities" />
    ) : null;
  }, [training]);

  const projectsList = useMemo(() => {
    return training.projects.length ? (
      <LabelArray className="training-label-array" items={training.projects} title="Projects" />
    ) : null;
  }, [training]);

  const trainingActivitiesProjects = useMemo(() => {
    if (!training.projects.length && !training.activities.length) {
      return null;
    }
    return (
      <div className="form-card-row">
        {activitiesList}
        {projectsList}
      </div>
    );
  }, [activitiesList, projectsList, training]);

  const assignedMembers = useMemo(() => {
    return training.members.filter((m) => !m.is_auto && (!m.is_deleted || trainingPassObject[m.id]));
  }, [training, trainingPassObject]);

  const trainingPassMembers = useMemo(() => {
    return training.members.filter((m) => m.is_auto && (!m.is_deleted || trainingPassObject[m.id]));
  }, [training, trainingPassObject]);

  return (
    <div className="training-view-wrapper">
      <div className="training-view-column">
        <FormCard className="training-view-card-general">
          {training.location && <LocationLabel className="form-card-row" location={training.location} />}
          {trainingRecurring}
          {trainingDescription}
          <TrainingViewCertificate training={training} />
          {trainingActivitiesProjects}
          {trainingUpdate}
        </FormCard>
      </div>
      <div className="training-view-column">
        {Boolean(assignedMembers.length) && (
          <FormCard className="training-view-card-members">
            <div className="form-card-subtitle">Assigned Team Members</div>
            {assignedMembers.map((member) => (
              <TrainingTeamMemberCard
                onPassClick={onPassClick}
                onConfirmClick={onConfirmClick}
                onClick={onMemberClick}
                training={training}
                member={member}
                key={member.id}
                trainingPassItem={trainingPassObject[member.id]}
              />
            ))}
          </FormCard>
        )}
        {Boolean(trainingPassMembers.length) && (
          <FormCard className="training-view-card-members">
            <div className="form-card-subtitle">Team Members that passed the training</div>
            {trainingPassMembers.map((member) => (
              <TrainingTeamMemberCard
                onPassClick={onPassClick}
                onConfirmClick={onConfirmClick}
                onClick={onMemberClick}
                training={training}
                member={member}
                key={member.id}
                trainingPassItem={trainingPassObject[member.id]}
              />
            ))}
          </FormCard>
        )}
      </div>
    </div>
  );
};

export default TrainingView;
