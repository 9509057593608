import { PaginatedResponse, ActionWithPayload, CodeWithId } from "src/shared/interfaces";
import { startLoading, stopLoading, navigate, hideModal } from "src/shared/store/actions";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  Assignment,
  AssignmentCheckInOutState,
  AssignmentListItem,
  AssignmentPassedJsa,
  MemberWithAssignmentsInfoListItem,
} from "src/shared/models";
import {
  AssignmentFormShapeToRequest,
  CreateAssignmentSuccess,
  CreateAssignmentCheckInOutSuccess,
  UpdateAssignmentStatusShape,
  AssignmentCheckInOutFormShapeToRequest,
  AssignmentPassedJsaFilter,
  AssignmentJsaFilter,
  AssignmentJsaResponse,
} from "src/containers/Assignment/interface";
import { NameOfChildRoutes, NameOfRoutes } from "src/shared/constants";
import { generatePath } from "react-router";

import api from "../api";
import {
  getAssignments,
  getMembersAssignments,
  createAssignment,
  createAssignmentCheckIn,
  createAssignmentCheckOut,
  getAssignment,
  updateAssignment,
  updateAssignmentStatus,
  getAssignmentCheckInOut,
  GetAssignmentPayload,
  getAssignmentPassedJsa,
  getAssignmentJsa,
  setClearAssignmentForm,
} from "./actions";
import { AssignmentFilter } from "../interface";

function* redirectToEditAssignment(id: string, companyCode?: string) {
  yield put(
    navigate(
      generatePath(`${NameOfRoutes.COMPANIES}${NameOfChildRoutes.COMPANY.ASSIGNMENT_EDIT}`, {
        id,
        code: companyCode,
      }),
    ),
  );
}

function* redirectToAssignments(companyCode?: string) {
  yield put(
    navigate(
      generatePath(`${NameOfRoutes.COMPANIES}${NameOfChildRoutes.COMPANY.ASSIGNMENTS}`, {
        code: companyCode,
      }),
    ),
  );
}

function* getAssignmentsSaga({ payload }: ActionWithPayload<AssignmentFilter>) {
  try {
    yield put(startLoading());
    const response: PaginatedResponse<AssignmentListItem> = yield call(api.getAssignments, payload);
    yield put(stopLoading());
    yield put(getAssignments.success({ ...response, clear: !payload.page }));
  } catch (error) {
    yield put(getAssignments.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getMembersAssignmentsSaga({ payload }: ActionWithPayload<AssignmentFilter>) {
  try {
    yield put(startLoading());
    const response: PaginatedResponse<MemberWithAssignmentsInfoListItem> = yield call(
      api.getMembersAssignments,
      payload,
    );
    yield put(stopLoading());
    yield put(getMembersAssignments.success({ ...response, clear: !payload.page }));
  } catch (error) {
    yield put(getMembersAssignments.failure(error as Error));
    yield put(stopLoading());
  }
}

function* createAssignmentSaga({ payload }: ActionWithPayload<AssignmentFormShapeToRequest>) {
  try {
    const { company_code } = payload;
    const { needRedirect, needReload, ...restPayload } = payload;
    yield put(startLoading());
    const response: CreateAssignmentSuccess = yield call(api.createAssignment, restPayload);
    yield put(stopLoading());
    yield put(createAssignment.success(response));
    if (needRedirect && !needReload) {
      yield redirectToEditAssignment(String(response.assignment.id), company_code);
    }
    if (needReload) {
      yield put(setClearAssignmentForm(true));
    }
  } catch (error) {
    yield put(createAssignment.failure(error as Error));
    yield put(stopLoading());
  }
}

function* createAssignmentCheckInSaga({
  payload,
}: ActionWithPayload<Required<CodeWithId<AssignmentCheckInOutFormShapeToRequest>>>) {
  try {
    yield put(startLoading());
    const response: CreateAssignmentCheckInOutSuccess = yield call(api.createAssignmentCheckIn, payload);
    yield put(stopLoading());
    yield put(hideModal());
    yield put(createAssignmentCheckIn.success(response));
  } catch (error) {
    yield put(createAssignmentCheckIn.failure(error as Error));
    yield put(stopLoading());
  }
}

function* createAssignmentCheckOutSaga({
  payload,
}: ActionWithPayload<Required<CodeWithId<AssignmentCheckInOutFormShapeToRequest>>>) {
  try {
    yield put(startLoading());
    const response: CreateAssignmentCheckInOutSuccess = yield call(api.createAssignmentCheckOut, payload);
    yield put(stopLoading());
    yield put(hideModal());
    yield put(createAssignmentCheckOut.success(response));
  } catch (error) {
    yield put(createAssignmentCheckOut.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getAssignmentSaga({ payload }: ActionWithPayload<GetAssignmentPayload>) {
  const { withCheckInOut, memberId, ...restPayload } = payload;
  yield put(startLoading());

  try {
    const assignmentResponse: Assignment = yield call(api.getAssignment, restPayload);
    yield put(getAssignment.success(assignmentResponse));
  } catch (error) {
    yield put(getAssignment.failure(error as Error));
  }

  if (withCheckInOut) {
    try {
      const assignmentCheckInOutResponse: Array<AssignmentCheckInOutState> = yield call(api.getAssignmentCheckInOut, {
        ...restPayload,
        payload: { member_id: memberId },
      });
      yield put(getAssignmentCheckInOut.success(assignmentCheckInOutResponse));
    } catch (error) {
      yield put(getAssignmentCheckInOut.failure(error as Error));
    }
  }

  yield put(stopLoading());
}

function* getAssignmentPassedJsaSaga({ payload }: ActionWithPayload<Required<CodeWithId<AssignmentPassedJsaFilter>>>) {
  yield put(startLoading());

  try {
    const assignmentPassedJsaResponse: AssignmentPassedJsa[] = yield call(api.getAssignmentPassedJsa, payload);
    yield put(getAssignmentPassedJsa.success(assignmentPassedJsaResponse));
  } catch (error) {
    yield put(getAssignmentPassedJsa.failure(error as Error));
  }

  yield put(stopLoading());
}

function* getAssignmentJsaSaga({ payload }: ActionWithPayload<Required<CodeWithId<AssignmentJsaFilter>>>) {
  yield put(startLoading());

  try {
    const assignmentJsaResponse: AssignmentJsaResponse = yield call(api.getAssignmentJsa, payload);
    yield put(getAssignmentJsa.success(assignmentJsaResponse));
  } catch (error) {
    yield put(getAssignmentJsa.failure(error as Error));
  }

  yield put(stopLoading());
}

function* updateAssignmentSaga({ payload }: ActionWithPayload<Required<CodeWithId<AssignmentFormShapeToRequest>>>) {
  try {
    yield put(startLoading());
    const response: CreateAssignmentSuccess = yield call(api.updateAssignment, payload);
    yield put(stopLoading());
    yield put(updateAssignment.success(response));
  } catch (error) {
    yield put(updateAssignment.failure(error as Error));
    yield put(stopLoading());
  }
}

function* updateAssignmentStatusSaga({
  payload,
}: ActionWithPayload<Required<CodeWithId<UpdateAssignmentStatusShape>>>) {
  try {
    yield put(startLoading());
    const response: CreateAssignmentSuccess = yield call(api.updateAssignmentStatus, payload);
    yield put(stopLoading());
    yield put(updateAssignmentStatus.success(response));
    yield redirectToAssignments(payload.code);
  } catch (error) {
    yield put(updateAssignmentStatus.failure(error as Error));
    yield put(stopLoading());
  }
}

function* assignmentSagas() {
  yield takeLatest(getAssignments.request, getAssignmentsSaga);
  yield takeLatest(getMembersAssignments.request, getMembersAssignmentsSaga);
  yield takeLatest(createAssignment.request, createAssignmentSaga);
  yield takeLatest(createAssignmentCheckIn.request, createAssignmentCheckInSaga);
  yield takeLatest(createAssignmentCheckOut.request, createAssignmentCheckOutSaga);
  yield takeLatest(updateAssignment.request, updateAssignmentSaga);
  yield takeLatest(getAssignment.request, getAssignmentSaga);
  yield takeLatest(updateAssignmentStatus.request, updateAssignmentStatusSaga);
  yield takeLatest(getAssignmentPassedJsa.request, getAssignmentPassedJsaSaga);
  yield takeLatest(getAssignmentJsa.request, getAssignmentJsaSaga);
}

export default assignmentSagas;
