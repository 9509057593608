import { Option } from "src/shared/interfaces";
import {
  UserStatus,
  CompanyStatus,
  MemberStatus,
  ActivityStatus,
  TaskStatus,
  JsaStatus,
  AssignmentStatus,
  MemberTrainingStatus,
  DocumentType,
  MemberCertificateStatus,
  AlertStatus,
  TrainingStatus,
  ProjectStatus,
  TeamStatus,
} from "src/shared/models";

export const userStatusesOptions = [
  { label: UserStatus.Active, value: UserStatus.Active },
  { label: UserStatus.Inactive, value: UserStatus.Inactive },
  { label: UserStatus.Invited, value: UserStatus.Invited },
];

export const companyStatusesOptions = [
  { label: CompanyStatus.Active, value: CompanyStatus.Active },
  { label: CompanyStatus.Inactive, value: CompanyStatus.Inactive },
  { label: CompanyStatus.Deactivated, value: CompanyStatus.Deactivated },
];

export const memberStatusesOptions = [
  { label: MemberStatus.Active, value: MemberStatus.Active },
  { label: MemberStatus.Inactive, value: MemberStatus.Inactive },
];

export const activityStatusesOptions = [
  { label: ActivityStatus.Active, value: ActivityStatus.Active },
  { label: ActivityStatus.Archived, value: ActivityStatus.Archived },
];

export const taskStatusesOptions = [
  { label: TaskStatus.Active, value: TaskStatus.Active },
  { label: TaskStatus.Archived, value: TaskStatus.Archived },
  { label: TaskStatus.Draft, value: TaskStatus.Draft },
];

export const jsaStatusesOptions = [
  { label: JsaStatus.Active, value: JsaStatus.Active },
  { label: JsaStatus.Archived, value: JsaStatus.Archived },
  { label: JsaStatus.Draft, value: JsaStatus.Draft },
];

export const assignmentStatusesOptions = [
  // { label: AssignmentStatus.Archived, value: AssignmentStatus.Archived },
  { label: AssignmentStatus.Draft, value: AssignmentStatus.Draft },
  { label: AssignmentStatus.Past, value: AssignmentStatus.Past },
  { label: AssignmentStatus.Scheduled, value: AssignmentStatus.Scheduled },
];

export const trainingMemberStatusesOptions: Option[] = [
  { label: MemberTrainingStatus.MISSED, value: MemberTrainingStatus.MISSED },
  { label: MemberTrainingStatus.COMPLITED, value: MemberTrainingStatus.COMPLITED },
  { label: MemberTrainingStatus.SCHEDULED, value: MemberTrainingStatus.SCHEDULED },
];

export const trainingStatusesOptions: Option[] = [
  { label: TrainingStatus.Active, value: TrainingStatus.Active },
  { label: TrainingStatus.Archived, value: TrainingStatus.Archived },
  { label: TrainingStatus.Draft, value: TrainingStatus.Draft },
];

export const certificateMemberStatusesOptions: Option[] = [
  { label: MemberCertificateStatus.Valid, value: MemberCertificateStatus.Valid },
  { label: MemberCertificateStatus.Expired, value: MemberCertificateStatus.Expired },
];

export const documentsTypesOptions: Option[] = [
  { label: "Files", value: DocumentType.FILE },
  { label: "Links", value: DocumentType.LINK },
];

export const alertStatusesOptions: Option[] = [
  { label: AlertStatus.Archived, value: AlertStatus.Archived },
  { label: AlertStatus.Scheduled, value: AlertStatus.Scheduled },
  { label: AlertStatus.Sent, value: AlertStatus.Sent },
];

export const projectStatusesOptions: Option[] = [
  { label: ProjectStatus.Active, value: ProjectStatus.Active },
  { label: ProjectStatus.Archived, value: ProjectStatus.Archived },
];

export const teamStatusesOptions: Option[] = [
  { label: TeamStatus.Active, value: TeamStatus.Active },
  { label: TeamStatus.Archived, value: TeamStatus.Archived },
];
