import { all, fork } from "redux-saga/effects";
import { saga as authSaga } from "src/containers/Auth/store";
import { saga as userSaga } from "src/containers/User/store";
import { saga as companySaga } from "src/containers/Company/store";
import { saga as memberSaga } from "src/containers/Member/store";
import { saga as activitySaga } from "src/containers/Activity/store";
import { saga as taskSaga } from "src/containers/Task/store";
import { saga as jsaSaga } from "src/containers/Jsa/store";
import { saga as assignmentSaga } from "src/containers/Assignment/store";
import { saga as trainingsSaga } from "src/containers/TrainingAndCertificate/store";
import { saga as documentSaga } from "src/containers/Documents/store";
import { saga as alertSaga } from "src/containers/Alert/store";
import { saga as userNotificationSaga } from "src/containers/UserNotification/store";
import { saga as contactUsSaga } from "src/containers/ContactUs/store";
import { saga as projectSaga } from "src/containers/Project/store";
import { saga as teamSaga } from "src/containers/Team/store";
import { saga as sharedSaga } from "src/shared/store";
import {saga as tagSaga } from "src/containers/Tag/store";

const allSagas = [
  authSaga,
  sharedSaga,
  userSaga,
  companySaga,
  memberSaga,
  activitySaga,
  taskSaga,
  jsaSaga,
  assignmentSaga,
  trainingsSaga,
  documentSaga,
  alertSaga,
  userNotificationSaga,
  contactUsSaga,
  projectSaga,
  teamSaga,
  tagSaga
];

export default function* appSagas() {
  yield all(allSagas.map(fork));
}
