import React, { FC, useCallback } from "react";
import { Formik } from "formik";
import { FormProps } from "src/shared/interfaces";
import { Button, FormGenerator } from "src/shared/components";
import { ContactUsFormShape } from "src/containers/ContactUs/interface";

import { getFields, getInitValues, validationSchema } from "./formHelpers";

import "./index.scss";

const fields = getFields();
const formValues = getInitValues();

const ContactUsForm: FC<FormProps<ContactUsFormShape>> = (props) => {
  const { submitHandler, loading } = props;

  const onSubmit = useCallback(
    (values, { setSubmitting }) => {
      submitHandler?.(values);
      setSubmitting(false);
    },
    [submitHandler],
  );

  return (
    <div className="contact-us-form">
      <p>Please note that the message will be submitted to the app support.</p>
      <Formik
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnMount={false}
        initialValues={formValues}>
        {(formikProps) => (
          <FormGenerator fields={fields} formikProps={formikProps}>
            <Button
              className="contact-us-form-submit"
              type="submit"
              buttonStyle="accent"
              disabled={loading || !validationSchema.isValidSync(formikProps.values)}>
              Submit
            </Button>
          </FormGenerator>
        )}
      </Formik>
    </div>
  );
};

export default ContactUsForm;
