import React, { useCallback, useEffect, useState } from "react";
import { useDebounce } from "src/shared/hooks";
import { Icon } from "src/shared/components";

import "./index.scss";

export interface InputSearchProps extends React.HTMLProps<HTMLInputElement> {
  onChangeSearch: (value: string) => void;
  showClearText?: boolean;
  showClose?: boolean;
  onChangeShowSearch?: (value: boolean) => void;
}

const InputSearch = (props: InputSearchProps) => {
  const { onChangeSearch, value, showClearText, showClose, onChangeShowSearch, ...inputProps } = props;
  const [inputValue, setInputValue] = useState<string>(value ? String(value) : "");
  const [showSearch, setShowSearch] = useState(!showClose);
  const debounceValue = useDebounce<string>(inputValue, 500);

  useEffect(() => {
    setInputValue(value ? String(value) : "");
    if (value) {
      setShowSearch(true);
    }
  }, [value]);

  useEffect(() => {
    onChangeShowSearch && onChangeShowSearch(showSearch);
  }, [onChangeShowSearch, showSearch]);

  useEffect(() => {
    if (debounceValue !== value && debounceValue !== undefined) {
      onChangeSearch(debounceValue);
    }
  }, [debounceValue, onChangeSearch, inputValue, value]);

  const onChangeInput = (event: React.SyntheticEvent) => {
    setInputValue((event.target as HTMLInputElement).value);
  };

  const clearInput = useCallback(() => {
    setInputValue("");
  }, []);

  const closeSearch = useCallback(() => {
    setShowSearch(false);
    clearInput();
  }, [clearInput]);

  return (
    <div className="search custom-input">
      <Icon type="find" onClick={() => setShowSearch(true)} className="search-icon-block" />
      {showSearch && (
        <>
          <input className="input-search" {...inputProps} onChange={onChangeInput} value={inputValue} />
          <div className="search-controls">
            {showClearText ? (
              <div className="clear-text" onClick={clearInput}>
                <Icon type="clear" />
              </div>
            ) : null}
            {showClose && (
              <>
                <div className="close-divider" />
                <div className="close-button" onClick={closeSearch}>
                  Close
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default InputSearch;
