import React, { FC, useMemo } from "react";
import { Training, TrainingPass } from "src/shared/models";
import { getUSFormattedDateShort } from "src/shared/utils";

import "./index.scss";

export interface TrainingViewCertificateProps {
  training: Training;
  isTeamMember?: boolean;
  trainingPass?: TrainingPass;
}

const TrainingViewCertificate: FC<TrainingViewCertificateProps> = (props) => {
  const { training, trainingPass, isTeamMember } = props;

  const certificate = useMemo(() => {
    return trainingPass?.certificate;
  }, [trainingPass]);

  const certificateName = useMemo(() => {
    return certificate?.name || training.certificate_name;
  }, [certificate, training]);

  const certificateIssiedBy = useMemo(() => {
    return certificate?.issued_by || training.certificate_issued_by;
  }, [certificate, training]);

  const certificateValid = useMemo(() => {
    return certificate?.valid || training.certificate_valid;
  }, [certificate, training]);

  const certificateValidPeriod = useMemo(() => {
    return certificate?.valid_period || training.certificate_valid_period;
  }, [certificate, training]);

  const certificateContent = useMemo(() => {
    if (isTeamMember && !certificate) {
      return (
        <div className="training-certificate-title">
          Certificate will appear here once the training is marked as passed
        </div>
      );
    }

    return (
      <>
        <div className="training-certificate-title">Certificate</div>
        <div className="training-certificate-content">
          <div className="training-certificate-column">
            <div className="training-certificate-subtitle">Certificate Name</div>
            <div className="training-certificate-value">{certificateName}</div>
          </div>
          <div className="training-certificate-column">
            <div className="training-certificate-subtitle">Issued By</div>
            <div className="training-certificate-value">{certificateIssiedBy}</div>
          </div>
          {certificate && (
            <div className="training-certificate-column">
              <div className="training-certificate-subtitle">Issued On</div>
              <div className="training-certificate-value">{getUSFormattedDateShort(certificate.issued_on)}</div>
            </div>
          )}
          <div className="training-certificate-column">
            <div className="training-certificate-subtitle">Valid</div>
            <div className="training-certificate-value">
              {certificateValid} {certificateValidPeriod}
            </div>
          </div>
        </div>
      </>
    );
  }, [certificate, isTeamMember, certificateName, certificateIssiedBy, certificateValid, certificateValidPeriod]);

  return training.certificate_name ? (
    <div className="form-card-row training-certificate-wrapper">{certificateContent}</div>
  ) : null;
};

export default TrainingViewCertificate;
