import { CompanyListItem, Company } from "src/shared/models";
import { PaginatedResponse, ActionWithPayload, Filter } from "src/shared/interfaces";
import { startLoading, stopLoading, hideOverlay, getUserDetails } from "src/shared/store/actions";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  CompanyFormShape,
  CreateCompanySuccess,
  CompanyFormUpdateShape,
  UpdateStatusShape,
} from "src/containers/Company/interface";

import api from "../api";
import {
  getCompanies,
  createCompany,
  updateCompany,
  updateCompanyStatus,
  getCompany,
  getCompanyToEdit,
} from "./actions";

function* getCompaniesSaga({ payload }: ActionWithPayload<Filter>) {
  try {
    yield put(startLoading());
    const response: PaginatedResponse<CompanyListItem> = yield call(api.getCompanies, payload);
    yield put(stopLoading());
    yield put(
      getCompanies.success({
        ...response,
        clear: !payload.page,
      }),
    );
  } catch (error) {
    yield put(getCompanies.failure(error as Error));
    yield put(stopLoading());
  }
}

function* createCompanySaga({ payload }: ActionWithPayload<CompanyFormShape>) {
  try {
    yield put(startLoading());
    const response: CreateCompanySuccess = yield call(api.createCompany, payload);
    response.company.users_count = payload.is_sent_invitation ? 1 : 0;
    yield put(stopLoading());
    yield put(hideOverlay());
    yield put(createCompany.success(response));
  } catch (error) {
    yield put(createCompany.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getCompanySaga({ payload }: ActionWithPayload<string>) {
  try {
    yield put(startLoading());
    const response: Company = yield call(api.getCompany, payload);
    yield put(stopLoading());
    yield put(getCompany.success(response));
  } catch (error) {
    yield put(getUserDetails.request());
    yield put(getCompany.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getCompanyToEditSaga({ payload }: ActionWithPayload<string>) {
  try {
    yield put(startLoading());
    const response: Company = yield call(api.getCompany, payload);
    yield put(stopLoading());
    yield put(getCompanyToEdit.success(response));
  } catch (error) {
    yield put(getCompanyToEdit.failure(error as Error));
    yield put(stopLoading());
  }
}

function* updateCompanySaga({ payload }: ActionWithPayload<CompanyFormUpdateShape>) {
  try {
    yield put(startLoading());
    const response: CreateCompanySuccess = yield call(api.updateCompany, payload);
    yield put(stopLoading());
    yield put(updateCompany.success(response));
  } catch (error) {
    yield put(updateCompany.failure(error as Error));
    yield put(stopLoading());
  }
}

function* updateCompanyStatusSaga({ payload }: ActionWithPayload<UpdateStatusShape>) {
  try {
    yield put(startLoading());
    const response: CreateCompanySuccess = yield call(api.updateCompanyStatus, payload);
    yield put(stopLoading());
    yield put(updateCompany.success(response));
  } catch (error) {
    yield put(updateCompanyStatus.failure(error as Error));
    yield put(stopLoading());
  }
}

function* companySagas() {
  yield takeLatest(getCompanies.request, getCompaniesSaga);
  yield takeLatest(getCompany.request, getCompanySaga);
  yield takeLatest(getCompanyToEdit.request, getCompanyToEditSaga);
  yield takeLatest(createCompany.request, createCompanySaga);
  yield takeLatest(updateCompany.request, updateCompanySaga);
  yield takeLatest(updateCompanyStatus.request, updateCompanyStatusSaga);
}

export default companySagas;
