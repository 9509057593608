import React from "react";
import classnames from "classnames";
import { Input, ErrorMessage } from "src/shared/components/Common";
import { FieldItemType } from "src/shared/interfaces";

import { RenderField, GenerateRenderField } from "../../interfaces";

export interface NumberFieldItem extends RenderField {
  type: FieldItemType.NUMBER;
  label: string;
  disabled?: boolean;
  placeholder?: string;
  min?: number;
  max?: number;
}

export const GenerateNumberField: GenerateRenderField<NumberFieldItem> = (props) => {
  const {
    formikProps: { errors, touched, handleBlur, handleChange, values },
    wrapperClass,
    name,
    placeholder,
    disabled,
    type,
    label,
    min,
    max,
  } = props;

  return (
    <div className={classnames(wrapperClass, { error: errors[name] && touched[name] })} id={name}>
      <Input
        type={type}
        label={label}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values[name]}
        min={min}
        max={max}
      />
      <ErrorMessage isTouched={!!touched[name]} error={errors[name]?.toString()} />
    </div>
  );
};
