import { API, METHODS, SCHEMAS } from "src/shared/constants";
import { API_ENUM } from "src/shared/constants/api";
import { SchemasMap } from "src/shared/interfaces";
import { mapToSchema, generateDynamicSchemaKeyString } from "src/shared/utils";
import {
  baseFilterSchema,
  getPaginatedResponseSchema,
  createUpdateProjectRequestSchema,
  createUpdateProjectResponseSchema,
  projectSchema,
  projectDeleteResponseSchema,
} from "src/shared/validators";

const schemaShapes: SchemasMap<keyof typeof API.PROJECT> = {
  [API_ENUM.GET_PROJECTS]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.PROJECT.GET_PROJECTS("$id"))}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: baseFilterSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.PROJECT.GET_PROJECTS("$id"))}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: getPaginatedResponseSchema(projectSchema),
    },
  },
  [API_ENUM.GET_PROJECT]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.PROJECT.GET_PROJECT("$id", "$id"))}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: {},
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.PROJECT.GET_PROJECT("$id", "$id"))}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: projectSchema,
    },
  },
  [API_ENUM.CREATE_PROJECT]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.PROJECT.CREATE_PROJECT("$id"))}${SCHEMAS.REQUEST}${METHODS.POST}`,
      schema: createUpdateProjectRequestSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.PROJECT.CREATE_PROJECT("$id"))}${SCHEMAS.RESPONSE}${METHODS.POST}`,
      schema: createUpdateProjectResponseSchema,
    },
  },
  [API_ENUM.UPDATE_PROJECT]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.PROJECT.UPDATE_PROJECT("$id", "$id"))}${SCHEMAS.REQUEST}${
        METHODS.PUT
      }`,
      schema: createUpdateProjectRequestSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.PROJECT.UPDATE_PROJECT("$id", "$id"))}${SCHEMAS.RESPONSE}${
        METHODS.PUT
      }`,
      schema: createUpdateProjectResponseSchema,
    },
  },
  [API_ENUM.DELETE_PROJECT]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.PROJECT.DELETE_PROJECT("$id", "$id"))}${SCHEMAS.REQUEST}${
        METHODS.DELETE
      }`,
      schema: {},
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.PROJECT.DELETE_PROJECT("$id", "$id"))}${SCHEMAS.RESPONSE}${
        METHODS.DELETE
      }`,
      schema: projectDeleteResponseSchema,
    },
  },
};

export default mapToSchema(schemaShapes);
