import { optionToValue, request } from "src/shared/utils";
import { API, METHODS } from "src/shared/constants";
import {
  CreateMemberNoteShape,
  DeleteMemberFileShape,
  DeleteMemberNoteShape,
  MemberFormShapeToRequest,
  MemberFormUpdateShape,
  UpdateMemberStatusShape,
  SetMemberSignatureRequestShape,
  TrainingAndCertificateMemberFilter,
} from "src/containers/Member/interface";
import { Filter, CodeWithId, CreateFileShape } from "src/shared/interfaces";

const formatTrainingAndCertificateMemberFilter = (filter: Partial<TrainingAndCertificateMemberFilter>) => {
  return {
    search: filter.search,
    page: filter.page,
    limit: filter.limit,
    start_date: filter.start_date,
    end_date: filter.end_date,
    teams: filter.teams?.map(optionToValue),
    status: filter.status,
    activities: filter.activities?.map(optionToValue),
    member_id: filter.member_id,
    project_id: filter.project_id,

    sort_by: filter.sort_by,
    sort_order: filter.sort_order,
  };
};

export default {
  getMembers: (payload: Filter) => {
    const { company_code, ...memberFilter } = payload;
    return request(METHODS.GET, API.MEMBER.GET_MEMBERS(company_code ?? ""))({ params: memberFilter });
  },
  getMembersCertificates: (payload: TrainingAndCertificateMemberFilter) => {
    const { company_code, ...memberCertificateFilter } = payload;
    return request(
      METHODS.GET,
      API.MEMBER.GET_MEMBERS_CERTIFICATES(company_code ?? ""),
    )({ params: formatTrainingAndCertificateMemberFilter(memberCertificateFilter) });
  },
  getMembersTrainings: (payload: TrainingAndCertificateMemberFilter) => {
    const { company_code, ...memberTrainingFilter } = payload;
    return request(
      METHODS.GET,
      API.MEMBER.GET_MEMBERS_TRAININGS(company_code ?? ""),
    )({ params: formatTrainingAndCertificateMemberFilter(memberTrainingFilter) });
  },
  getMembersJsa: (payload: TrainingAndCertificateMemberFilter) => {
    const { company_code, ...memberTrainingFilter } = payload;
    return request(
      METHODS.GET,
      API.MEMBER.GET_MEMBERS_JSA(company_code ?? ""),
    )({ params: formatTrainingAndCertificateMemberFilter(memberTrainingFilter) });
  },
  createMember: (payload: CodeWithId<MemberFormShapeToRequest & { is_connect_user?: boolean }>) => {
    return request(METHODS.POST, API.MEMBER.CREATE_MEMBER(payload.code))(payload.payload);
  },
  updateMember: ({ code, id, payload }: Required<CodeWithId<MemberFormUpdateShape>>) => {
    delete payload.needRedirect;
    return request(METHODS.PUT, API.MEMBER.UPDATE_MEMBER(code, id))(payload);
  },
  updateMemberStatus: (payload: Required<CodeWithId<UpdateMemberStatusShape>>) => {
    return request(METHODS.PUT, API.MEMBER.UPDATE_MEMBER_STATUS(payload.code, payload.id))(payload.payload);
  },
  getMember: (payload: Required<CodeWithId>) => {
    return request(METHODS.GET, API.MEMBER.GET_MEMBER(payload.code, payload.id))();
  },
  getMemberFiles: (payload: Required<CodeWithId>) => {
    return request(METHODS.GET, API.MEMBER.GET_MEMBER_FILES(payload.code, payload.id))();
  },
  getMemberNotes: (payload: Required<CodeWithId>) => {
    return request(METHODS.GET, API.MEMBER.GET_MEMBER_NOTES(payload.code, payload.id))();
  },
  addMemberNote: (payload: Required<CodeWithId & CreateMemberNoteShape>) => {
    return request(
      METHODS.POST,
      API.MEMBER.ADD_MEMBER_NOTE(payload.code, payload.id),
    )({ description: payload.description });
  },
  addMemberFile: (payload: Required<CodeWithId & CreateFileShape>) => {
    return request(
      METHODS.POST,
      API.MEMBER.ADD_MEMBER_FILE(payload.code, payload.id),
    )({ base64: payload.base64, name: payload.name });
  },

  deleteMemberNote: (payload: DeleteMemberNoteShape) => {
    return request(
      METHODS.DELETE,
      API.MEMBER.DELETE_MEMBER_NOTE(payload.company_code, payload.member_id, payload.note_id),
    )();
  },
  deleteMemberFile: (payload: DeleteMemberFileShape) => {
    return request(
      METHODS.DELETE,
      API.MEMBER.DELETE_MEMBER_FILE(payload.company_code, payload.member_id, payload.file_id),
    )();
  },
  setMemberSignature: (payload: Required<CodeWithId<SetMemberSignatureRequestShape>>) => {
    return request(
      METHODS.POST,
      API.MEMBER.SET_MEMBER_SIGNATURE(payload.code, payload.id),
    )({ base64: payload.payload.base64 });
  },
  deleteMemberSignature: (payload: Required<CodeWithId>) => {
    return request(METHODS.DELETE, API.MEMBER.DELETE_MEMBER_SIGNATURE(payload.code, payload.id))();
  },
  getMemberPassedJsa: (payload: Required<CodeWithId>) => {
    return request(METHODS.GET, API.MEMBER.GET_MEMBER_PASSED_JSA(payload.code, payload.id))();
  },
};
