import { PaginatedResponse, ActionWithPayload, Filter, CodeWithId, PayloadWithCode } from "src/shared/interfaces";
import { hideOverlay, startLoading, stopLoading } from "src/shared/store/actions";
import { call, put, takeLatest } from "redux-saga/effects";
import { AlertListItem, Alert } from "src/shared/models";

import api from "../api";
import { createAlert, deleteAlert, getAlert, getAlerts, updateAlert } from "./actions";
import { AlertCreateUpdateShapeToRequest, DeleteAlertResponse, CreateUpdateAlertSuccess } from "../interface";

function* getAlertsSaga({ payload }: ActionWithPayload<Filter>) {
  try {
    yield put(startLoading());
    const response: PaginatedResponse<AlertListItem> = yield call(api.getAlerts, payload);
    yield put(stopLoading());
    yield put(getAlerts.success({ ...response, clear: !payload.page }));
  } catch (error) {
    yield put(getAlerts.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getAlertSaga({ payload }: ActionWithPayload<Required<CodeWithId>>) {
  try {
    yield put(startLoading());
    const response: Alert = yield call(api.getAlert, payload);
    yield put(stopLoading());
    yield put(getAlert.success(response));
  } catch (error) {
    yield put(getAlert.failure(error as Error));
    yield put(stopLoading());
  }
}

function* createAlertSaga({ payload }: ActionWithPayload<PayloadWithCode<AlertCreateUpdateShapeToRequest>>) {
  try {
    yield put(startLoading());
    const response: CreateUpdateAlertSuccess = yield call(api.createAlert, payload);
    yield put(stopLoading());
    yield put(createAlert.success(response));
  } catch (error) {
    yield put(createAlert.failure(error as Error));
    yield put(stopLoading());
  }
}

function* updateAlertSaga({ payload }: ActionWithPayload<Required<CodeWithId<AlertCreateUpdateShapeToRequest>>>) {
  try {
    yield put(startLoading());
    const response: CreateUpdateAlertSuccess = yield call(api.updateAlert, payload);
    yield put(stopLoading());
    yield put(updateAlert.success(response));
  } catch (error) {
    yield put(updateAlert.failure(error as Error));
    yield put(stopLoading());
  }
}

function* deleteAlertSaga({ payload }: ActionWithPayload<Required<CodeWithId>>) {
  try {
    yield put(startLoading());
    const response: DeleteAlertResponse = yield call(api.deleteAlert, payload);
    yield put(stopLoading());
    yield put(deleteAlert.success(response));
    yield put(hideOverlay());
  } catch (error) {
    yield put(deleteAlert.failure(error as Error));
    yield put(stopLoading());
  }
}

function* alertSagas() {
  yield takeLatest(getAlerts.request, getAlertsSaga);
  yield takeLatest(getAlert.request, getAlertSaga);
  yield takeLatest(createAlert.request, createAlertSaga);
  yield takeLatest(updateAlert.request, updateAlertSaga);
  yield takeLatest(deleteAlert.request, deleteAlertSaga);
}

export default alertSagas;
