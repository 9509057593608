import { createSelector } from "reselect";
import { AppState } from "src/shared/interfaces/State.interface";

const selectMember = (state: AppState) => state.member;

export const getMembers = () => createSelector(selectMember, (state) => state.members);
export const getMember = () => createSelector(selectMember, (state) => state.currentMember);
export const getMemberFiles = () => createSelector(selectMember, (state) => state.currentMemberFiles);
export const getMemberNotes = () => createSelector(selectMember, (state) => state.currentMemberNotes);
export const getMembersTotal = () => createSelector(selectMember, (state) => state.membersTotal);
export const getFilter = () => createSelector(selectMember, (state) => state.filter);
export const getMembersTrainings = () => createSelector(selectMember, (state) => state.memberTrainings);
export const getMembersTrainingsTotal = () => createSelector(selectMember, (state) => state.memberTrainingsTotal);
export const getMembersCertificates = () => createSelector(selectMember, (state) => state.memberCertificates);
export const getMembersCertificatesTotal = () => createSelector(selectMember, (state) => state.memberCertificatesTotal);
export const getMembersJsa = () => createSelector(selectMember, (state) => state.memberJsa);
export const getMembersJsaTotal = () => createSelector(selectMember, (state) => state.memberJsaTotal);
export const getTrainingAndCertificateMemberFilter = () =>
  createSelector(selectMember, (state) => state.trainingAndCertificateMemberFilter);
export const getCurrentPassedJsa = () => createSelector(selectMember, (state) => state.currentPassedJsa);
export const getMembersAdditonal = () => createSelector(selectMember, (state) => state.membersAdditional);
export const getMembersTotalAdditional = () => createSelector(selectMember, (state) => state.membersTotalAdditional);
