import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";
import { defaultFilter } from "src/shared/constants";
import { getFirstDayOfMonth, getLastDayOfMonth } from "src/shared/utils";
import { getMemberWithCertificateInfoFromCertificate } from "src/shared/models";

import { MemberStateType, TrainingAndCertificateMemberFilter } from "../interface";
import * as actions from "./actions";

type Action = ActionType<typeof actions>;

const DEFAULT_LIMIT = 50;

export const defaultMemberFilter: TrainingAndCertificateMemberFilter = {
  page: 0,
  limit: DEFAULT_LIMIT,
  start_date: getFirstDayOfMonth(new Date()),
  end_date: getLastDayOfMonth(new Date()),
  teams: [],
  status: undefined,
};

export const initialState: MemberStateType = {
  members: [],
  membersTotal: 0,
  currentMember: null,
  currentMemberFiles: [],
  currentMemberNotes: [],
  filter: defaultFilter,
  trainingAndCertificateMemberFilter: defaultMemberFilter,
  memberTrainings: [],
  memberTrainingsTotal: 0,
  memberCertificates: [],
  memberCertificatesTotal: 0,
  memberJsa: [],
  memberJsaTotal: 0,
  currentPassedJsa: null,
  membersAdditional: [],
  membersTotalAdditional: 0,
};

const reducer = createReducer<MemberStateType, Action>(initialState)
  .handleAction(actions.getMembers.success, (state, action) =>
    produce(state, (nextState) => {
      const { rows, count, clear, additional } = action.payload;
      if (additional) {
        nextState.membersAdditional = clear ? rows : [...nextState.members, ...rows];
        nextState.membersTotalAdditional = count;
      } else {
        nextState.members = clear ? rows : [...nextState.members, ...rows];
        nextState.membersTotal = count;
      }
    }),
  )
  .handleAction(actions.getMembersTrainings.success, (state, action) =>
    produce(state, (nextState) => {
      const { rows, count, clear } = action.payload;
      nextState.memberTrainings = clear ? rows : [...nextState.memberTrainings, ...rows];
      nextState.memberTrainingsTotal = count;
    }),
  )
  .handleAction(actions.getMembersCertificates.success, (state, action) =>
    produce(state, (nextState) => {
      const { rows, count, clear } = action.payload;
      nextState.memberCertificates = clear ? rows : [...nextState.memberCertificates, ...rows];
      nextState.memberCertificatesTotal = count;
    }),
  )
  .handleAction(actions.getMembersJsa.success, (state, action) =>
    produce(state, (nextState) => {
      const { rows, count, clear } = action.payload;
      nextState.memberJsa = clear ? rows : [...nextState.memberJsa, ...rows];
      nextState.memberJsaTotal = count;
    }),
  )
  .handleAction(actions.setTrainingAndCertificateMemberFilter, (state, action) =>
    produce(state, (nextState) => {
      nextState.trainingAndCertificateMemberFilter = action.payload || { ...defaultMemberFilter };
    }),
  )
  .handleAction(actions.getMember.success, (state, action) =>
    produce(state, (nextState) => {
      const { payload } = action;
      nextState.currentMember = payload;
    }),
  )
  .handleAction(actions.createMember.success, (state, action) =>
    produce(state, (nextState) => {
      const { member } = action.payload;
      nextState.members = [member, ...nextState.members];
      nextState.membersTotal = nextState.membersTotal + 1;
    }),
  )
  .handleAction(actions.updateMember.success, (state, action) =>
    produce(state, (nextState) => {
      const { member } = action.payload;
      const index = nextState.members.findIndex((m) => m.id === member.id);
      if (index >= 0) {
        nextState.members[index] = member;
      }
      if (state.currentMember && state.currentMember.id === member.id) {
        nextState.currentMember = member;
      }
    }),
  )
  .handleAction(actions.getMemberFiles.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.currentMemberFiles = action.payload;
    }),
  )
  .handleAction(actions.getMemberNotes.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.currentMemberNotes = action.payload;
    }),
  )
  .handleAction(actions.addMemberFile.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.currentMemberFiles = [action.payload.file, ...state.currentMemberFiles];
    }),
  )
  .handleAction(actions.addMemberNote.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.currentMemberNotes = [action.payload.note, ...state.currentMemberNotes];
    }),
  )
  .handleAction(actions.deleteMemberFile.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.currentMemberFiles = state.currentMemberFiles.filter((f) => f.id !== action.payload.file_id);
    }),
  )
  .handleAction(actions.deleteMemberNote.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.currentMemberNotes = state.currentMemberNotes.filter((f) => f.id !== action.payload.note_id);
    }),
  )
  .handleAction(actions.setFilter, (state, action) =>
    produce(state, (nextState) => {
      nextState.filter = action.payload || { ...defaultFilter };
    }),
  )
  .handleAction(actions.setMemberSignature.success, (state, action) =>
    produce(state, (nextState) => {
      const { payload } = action;
      if (nextState.currentMember) {
        nextState.currentMember = {
          ...nextState.currentMember,
          signature: payload.signature,
        };
      }
    }),
  )
  .handleAction(actions.deleteMemberSignature.success, (state) =>
    produce(state, (nextState) => {
      if (nextState.currentMember) {
        nextState.currentMember = {
          ...nextState.currentMember,
          signature: null,
        };
      }
    }),
  )
  .handleAction(actions.addMemberCerificate, (state, action) =>
    produce(state, (nextState) => {
      nextState.memberCertificates = [
        getMemberWithCertificateInfoFromCertificate(action.payload),
        ...nextState.memberCertificates,
      ];
      nextState.memberCertificatesTotal = nextState.memberCertificatesTotal + 1;
    }),
  )
  .handleAction(actions.updateMemberCerificate, (state, action) =>
    produce(state, (nextState) => {
      const certificate = action.payload;
      const index = nextState.memberCertificates.findIndex((c) => c.id === certificate.member.id);
      if (index >= 0) {
        nextState.memberCertificates[index] = getMemberWithCertificateInfoFromCertificate(certificate);
      }
    }),
  )
  .handleAction(actions.getMemberPassedJsa.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.currentPassedJsa = action.payload;
    }),
  );

export { reducer as MemberReducer };
