import { API, METHODS, SCHEMAS } from "src/shared/constants";
import { API_ENUM } from "src/shared/constants/api";
import { SchemasMap } from "src/shared/interfaces";
import { mapToSchema, generateDynamicSchemaKeyString } from "src/shared/utils";
import {
  filterSchema,
  getPaginatedResponseSchema,
  createAssignmentSchema,
  assignmentJsaListSchema,
} from "src/shared/validators";
import {
  assignmentForListSchema,
  assignmentSchema,
  updateAssignmentResponseSchema,
  updateAssignmentStatusSchema,
  updateAssignmentCheckInOutSchema,
  assignmentCheckInOutListSchema,
  createAssignmentCheckInOutSchema,
  assignmentPassedJsaListSchema,
} from "src/shared/validators/assignment.schema";

const schemaShapes: SchemasMap<keyof typeof API.ASSIGNMENT> = {
  [API_ENUM.GET_ASSIGNMENTS]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.ASSIGNMENT.GET_ASSIGNMENTS("$id"))}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: filterSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.ASSIGNMENT.GET_ASSIGNMENTS("$id"))}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: getPaginatedResponseSchema(assignmentForListSchema),
    },
  },
  [API_ENUM.ADD_ASSIGNMENT_CHECK_IN]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.ASSIGNMENT.ADD_ASSIGNMENT_CHECK_IN("$id", "$id"))}${SCHEMAS.REQUEST}${
        METHODS.POST
      }`,
      schema: createAssignmentCheckInOutSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.ASSIGNMENT.ADD_ASSIGNMENT_CHECK_IN("$id", "$id"))}${
        SCHEMAS.RESPONSE
      }${METHODS.POST}`,
      schema: updateAssignmentCheckInOutSchema,
    },
  },
  [API_ENUM.ADD_ASSIGNMENT_CHECK_OUT]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.ASSIGNMENT.ADD_ASSIGNMENT_CHECK_OUT("$id", "$id"))}${
        SCHEMAS.REQUEST
      }${METHODS.POST}`,
      schema: createAssignmentCheckInOutSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.ASSIGNMENT.ADD_ASSIGNMENT_CHECK_OUT("$id", "$id"))}${
        SCHEMAS.RESPONSE
      }${METHODS.POST}`,
      schema: updateAssignmentCheckInOutSchema,
    },
  },
  [API_ENUM.CREATE_ASSIGNMENT]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.ASSIGNMENT.CREATE_ASSIGNMENT("$id"))}${SCHEMAS.REQUEST}${
        METHODS.POST
      }`,
      schema: createAssignmentSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.ASSIGNMENT.CREATE_ASSIGNMENT("$id"))}${SCHEMAS.RESPONSE}${
        METHODS.POST
      }`,
      schema: updateAssignmentResponseSchema,
    },
  },
  [API_ENUM.GET_ASSIGNMENT]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.ASSIGNMENT.GET_ASSIGNMENT("$id", "$id"))}${SCHEMAS.REQUEST}${
        METHODS.GET
      }`,
      schema: {},
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.ASSIGNMENT.GET_ASSIGNMENT("$id", "$id"))}${SCHEMAS.RESPONSE}${
        METHODS.GET
      }`,
      schema: assignmentSchema,
    },
  },
  [API_ENUM.GET_ASSIGNMENT_CHECK_IN_OUT]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.ASSIGNMENT.GET_ASSIGNMENT_CHECK_IN_OUT("$id", "$id"))}${
        SCHEMAS.REQUEST
      }${METHODS.GET}`,
      schema: {},
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.ASSIGNMENT.GET_ASSIGNMENT_CHECK_IN_OUT("$id", "$id"))}${
        SCHEMAS.RESPONSE
      }${METHODS.GET}`,
      schema: assignmentCheckInOutListSchema,
    },
  },
  [API_ENUM.GET_ASSIGNMENT_PASSED_JSA]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.ASSIGNMENT.GET_ASSIGNMENT_PASSED_JSA("$id", "$id"))}${
        SCHEMAS.REQUEST
      }${METHODS.GET}`,
      schema: {},
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.ASSIGNMENT.GET_ASSIGNMENT_PASSED_JSA("$id", "$id"))}${
        SCHEMAS.RESPONSE
      }${METHODS.GET}`,
      schema: assignmentPassedJsaListSchema,
    },
  },
  [API_ENUM.GET_ASSIGNMENT_JSA]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.ASSIGNMENT.GET_ASSIGNMENT_JSA("$id", "$id"))}${SCHEMAS.REQUEST}${
        METHODS.GET
      }`,
      schema: {},
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.ASSIGNMENT.GET_ASSIGNMENT_JSA("$id", "$id"))}${SCHEMAS.RESPONSE}${
        METHODS.GET
      }`,
      schema: assignmentJsaListSchema,
    },
  },
  [API_ENUM.UPDATE_ASSIGNMENT]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.ASSIGNMENT.UPDATE_ASSIGNMENT("$id", "$id"))}${SCHEMAS.REQUEST}${
        METHODS.PUT
      }`,
      schema: createAssignmentSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.ASSIGNMENT.UPDATE_ASSIGNMENT("$id", "$id"))}${SCHEMAS.RESPONSE}${
        METHODS.PUT
      }`,
      schema: updateAssignmentResponseSchema,
    },
  },
  [API_ENUM.UPDATE_ASSIGNMENT_STATUS]: {
    request: {
      name: `${generateDynamicSchemaKeyString(API.ASSIGNMENT.UPDATE_ASSIGNMENT_STATUS("$id", "$id"))}${
        SCHEMAS.REQUEST
      }${METHODS.PUT}`,
      schema: updateAssignmentStatusSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyString(API.ASSIGNMENT.UPDATE_ASSIGNMENT_STATUS("$id", "$id"))}${
        SCHEMAS.RESPONSE
      }${METHODS.PUT}`,
      schema: updateAssignmentResponseSchema,
    },
  },
};

export default mapToSchema(schemaShapes);
