export const TOOLBAR_ITEMS = [
  "heading",
  "|",
  "fontFamily",
  "fontSize",
  "fontColor",
  "fontBackgroundColor",
  "|",
  "bold",
  "italic",
  "underline",
  "strikethrough",
  "subscript",
  "superscript",
  "|",
  "alignment",
  "|",
  "horizontalLine",
  "insertTable",
  "|",
  "link",
  "mediaEmbed",
  "uploadImage",
  "|",
  "undo",
  "redo",
];

export const REMOVED_PLUGINS = [
  "CKFinderUploadAdapter",
  "CKFinder",
  "FindAndReplace",
  "Code",
  "CodeBlock",
  "CodeEditing",
  "CodeUI",
  "BlockQuote",
  "BlockQuoteEditing",
  "BlockQuoteUI",
  "CKFinderEditing",
  "CKFinderUI",
  "CloudServicesUploadAdapter",
  "EasyImage",
];
