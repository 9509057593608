import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isString } from "formik";
import {
  actions as certificateActions,
  selectors as certificateSelectors,
} from "src/containers/TrainingAndCertificate/store";
import { CertificateTemplate } from "src/shared/models";
import { useDebouncedFunction } from "src/shared/hooks";

import { CertificateTemplatesFilter } from "../interface";

export interface IUseCertificateTemplateSelector {
  companyCode?: string | null;
  selectCertificateTemplateCb?: (template: CertificateTemplate) => void;
  getFilterData: () => Partial<CertificateTemplatesFilter> | void;
}

export default function useCertificateTemplateSelector({
  companyCode,
  selectCertificateTemplateCb,
  getFilterData,
}: IUseCertificateTemplateSelector) {
  const dispatch = useDispatch();

  const [selectedCertificateTemplate, setSelectedCertificateTemplate] = useState<CertificateTemplate | null>(null);
  const matchedCertificateTemplates = useSelector(certificateSelectors.getCertificatesTemplatesAdditional());

  const searchMatchedCertificates = useCallback(() => {
    const { name, issued_by } = getFilterData() || {};
    if (issued_by && name) {
      dispatch(
        certificateActions.getCertificatesTemplates.request({
          limit: 100,
          page: 0,
          company_code: companyCode,
          additional: true,
          name,
          issued_by,
        }),
      );
    }
  }, [dispatch, companyCode, getFilterData]);

  const onChangeIssuedByDebounced = useDebouncedFunction(searchMatchedCertificates, 1000);

  const selectCertificateTemplate = useCallback(
    (template: CertificateTemplate) => {
      selectCertificateTemplateCb?.(template);
      setSelectedCertificateTemplate(template);
    },
    [selectCertificateTemplateCb],
  );

  useEffect(() => {
    return () => {
      dispatch(
        certificateActions.getCertificatesTemplates.success({
          count: 0,
          rows: [],
          additional: true,
          clear: true,
        }),
      );
    };
  }, [dispatch]);

  useEffect(() => {
    if (matchedCertificateTemplates.length) {
      selectCertificateTemplate(matchedCertificateTemplates[0]);
    } else {
      setSelectedCertificateTemplate(null);
    }
  }, [matchedCertificateTemplates, selectCertificateTemplate]);

  const onChangeCertificateTemplateName = useCallback(
    (_, data) => {
      if (!isString(data)) {
        const template = data.object as CertificateTemplate;
        selectCertificateTemplate(template);
      } else {
        setSelectedCertificateTemplate(null);
        onChangeIssuedByDebounced?.();
      }
    },
    [selectCertificateTemplate, onChangeIssuedByDebounced],
  );

  return {
    selectedCertificateTemplate,
    onChangeCertificateTemplateName,
    onChangeCertificateTemplateIssuedBy: onChangeIssuedByDebounced,
  };
}
