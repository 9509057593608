import React, { FC, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRoutes } from "react-router-dom";
import { Outlet, useNavigate } from "react-router";
import { AuthGuard } from "src/shared/guards";
import { NamesOfParentRoutes } from "src/shared/constants";
import { Toast, Notification, OverlayWrapper, ModalWrapper } from "src/shared/components/";
import { actions as sharedActions, selectors as sharedSelectors } from "src/shared/store";
import { ToastProvider } from "react-toast-notifications";

import ApplicationContainer from "./ApplicationContainer";
import { AuthContainer } from "../Auth";

import "./index.scss";


const App: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const notification = useSelector(sharedSelectors.getNotification());
  const navigateURL = useSelector(sharedSelectors.getNavigateURL());

  useEffect(() => {
    if (navigateURL) {
      navigate(navigateURL);
      dispatch(sharedActions.navigate(""));
    }
  }, [navigateURL, dispatch, navigate]);

  
  return (
    <div className="App">
      <ToastProvider components={{ Toast }}>
        <Notification notification={notification} />
        <OverlayWrapper />
        <ModalWrapper />        
        <Outlet />
      </ToastProvider>
    </div>
  );
};

export default App;
