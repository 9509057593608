import { getPaginatedResponseSchema } from "./shared.schema";

const userNotificationSchema = {
  type: "object",
  additionalProperties: false,
  required: ["id", "text", "is_read", "created_at"],
  properties: {
    id: { type: "number" },
    text: { type: "string" },
    is_read: { type: "boolean" },
    created_at: { type: "string" },
    assignment_id: { type: ["number", "null"] },
    training_id: { type: ["number", "null"] },
    certificate_id: { type: ["number", "null"] },
    action: { type: ["string", "null"] },
  },
};

export const getUserNotificationsResponseSchema = getPaginatedResponseSchema(userNotificationSchema);

export const getUserNotificationsUnreadCountResponseSchema = {
  type: "object",
  additionalProperties: false,
  required: ["count"],
  properties: {
    count: { type: "number" },
  },
};

export const readUserNotificationsResponseSchema = {
  type: "object",
  additionalProperties: false,
  required: ["notifications"],
  properties: {
    notifications: {
      type: "array",
      items: [{ type: "number" }],
    },
  },
};

export const readUserNotificationResponseSchema = {
  type: "object",
  additionalProperties: false,
  required: ["notification"],
  properties: {
    notification: { type: "number" },
  },
};
