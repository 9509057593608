import React, { useCallback } from "react";
import { Note } from "src/shared/models";
import { actions } from "src/shared/store";
import { useDispatch } from "react-redux";


import { NoteForm, NoteList } from "./components";
import { NoteFormShape } from "./interfaces";
import "./index.scss";
import { FormCard } from '../FormCard';
import { AccessControl } from '../AccessControl';

export interface NotesBlockProps {
  isDisabled: boolean;
  notes: Note[];
  onDeleteNote: (note: Note) => void;
  onCreateNote: (note: NoteFormShape) => void;
  permission?: string;
  companyCode?: string | null;
}

const NotesBlock = (props: NotesBlockProps) => {
  const dispatch = useDispatch();
  const { notes, onDeleteNote, onCreateNote, permission, companyCode, isDisabled } = props;

  const onCloseForm = useCallback(() => {
    dispatch(actions.hideModal());
  }, [dispatch]);

  const addNewNote = useCallback(() => {
    dispatch(
      actions.showModal({
        title: "Add Note",
        body: (
          <NoteForm
            onCancel={onCloseForm}
            onSubmit={(payload) => {
              onCreateNote(payload);
              onCloseForm();
            }}
          />
        ),
        modalWidth: "370px",
      }),
    );
  }, [dispatch, onCreateNote, onCloseForm]);

  const deleteNote = useCallback(
    (note: Note) => {
      dispatch(
        actions.showModal({
          title: "You want to delete note",
          body: "Are you sure you want to delete this note?",
          confirmText: "Delete",
          closeOnOutside: true,
          onConfirm: () => onDeleteNote(note),
        }),
      );
    },
    [dispatch, onDeleteNote],
  );

  return (
    <FormCard className="notes-block">
      <div className="notes-header">
        <div className="left-notes-header">Notes</div>
        <AccessControl permission={permission || ""} option={{ companyCode, onlyAppAdmin: !companyCode }}>
          {!isDisabled ? (
            <div className="right-notes-header" onClick={addNewNote}>
              + Add Note
            </div>
          ) : null}
        </AccessControl>
      </div>
      <div className="notes-list">
        {notes.length ? (
          <NoteList
            onDelete={deleteNote}
            notes={notes}
            permission={permission}
            companyCode={companyCode}
            isDisabled={isDisabled}
          />
        ) : (
          <div className="empty-list">No notes yet</div>
        )}
      </div>
    </FormCard>
  );
};

export default NotesBlock;
