import { createAction, createAsyncAction } from "typesafe-actions";
import { File, Task, TaskListItem } from "src/shared/models";
import {
  PaginatedResponse,
  Filter,
  PayloadWithId,
  IdWithOptionalCode,
  CreateFileShape,
  CreateFileSuccess,
  DeleteFileSuccess,
  BaseResponse,
} from "src/shared/interfaces";
import { TaskActionTypes } from "src/containers/Task/store/constants";
import {
  CreateTaskSuccess,
  DeleteTaskFileShape,
  TaskFormShapeToRequest,
  UpdateTaskStatusShape,
  TaskImportShape,
} from "src/containers/Task/interface";

export const getTasks = createAsyncAction(
  TaskActionTypes.GET_TASKS,
  TaskActionTypes.GET_TASKS_SUCCESS,
  TaskActionTypes.GET_TASKS_FAILURE,
)<Filter, PaginatedResponse<TaskListItem>, Error>();

export const getTask = createAsyncAction(
  TaskActionTypes.GET_TASK,
  TaskActionTypes.GET_TASK_SUCCESS,
  TaskActionTypes.GET_TASK_FAILURE,
)<{ id: number | string }, Task | null, Error>();

export const clearTasks = createAction(TaskActionTypes.CLEAR_TASKS)();

export const createTask = createAsyncAction(
  TaskActionTypes.CREATE_TASK,
  TaskActionTypes.CREATE_TASK_SUCCESS,
  TaskActionTypes.CREATE_TASK_FAILURE,
)<TaskFormShapeToRequest, CreateTaskSuccess, Error>();

export const updateTask = createAsyncAction(
  TaskActionTypes.UPDATE_TASK,
  TaskActionTypes.UPDATE_TASK_SUCCESS,
  TaskActionTypes.UPDATE_TASK_FAILURE,
)<Required<PayloadWithId<TaskFormShapeToRequest>>, CreateTaskSuccess, Error>();

export const updateTaskStatus = createAsyncAction(
  TaskActionTypes.UPDATE_TASK_STATUS,
  TaskActionTypes.UPDATE_TASK_STATUS_SUCCESS,
  TaskActionTypes.UPDATE_TASK_STATUS_FAILURE,
)<Required<IdWithOptionalCode<UpdateTaskStatusShape>>, CreateTaskSuccess, Error>();

export const getTaskFiles = createAsyncAction(
  TaskActionTypes.GET_TASK_FILES,
  TaskActionTypes.GET_TASK_FILES_SUCCESS,
  TaskActionTypes.GET_TASK_FILES_FAILURE,
)<{ id: string | number }, File[], Error>();

export const addTaskFile = createAsyncAction(
  TaskActionTypes.ADD_TASK_FILE,
  TaskActionTypes.ADD_TASK_FILE_SUCCESS,
  TaskActionTypes.ADD_TASK_FILE_FAILURE,
)<PayloadWithId<CreateFileShape>, CreateFileSuccess, Error>();

export const deleteTaskFile = createAsyncAction(
  TaskActionTypes.DELETE_TASK_FILE,
  TaskActionTypes.DELETE_TASK_FILE_SUCCESS,
  TaskActionTypes.DELETE_TASK_FILE_FAILURE,
)<DeleteTaskFileShape, DeleteFileSuccess, Error>();

export const importTask = createAsyncAction(
  TaskActionTypes.IMPORT_TASK,
  TaskActionTypes.IMPORT_TASK_SUCCESS,
  TaskActionTypes.IMPORT_TASK_FAILURE,
)<TaskImportShape, BaseResponse, Error>();

export const setFilter = createAction(TaskActionTypes.SET_FILTER)<Filter | null>();
export const setClearTaskForm = createAction(TaskActionTypes.SET_CLEAR_TASK_FORM)<boolean>();
